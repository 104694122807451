import React, { useEffect, useState } from 'react';
import {
	Box, Typography, Grid, Card, CardContent,
	CardMedia, CircularProgress, Button, Accordion,
	AccordionSummary, AccordionDetails, Paper, Checkbox,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductSurveyQuestions } from 'thunk'; // Thunks for fetching product data
import { RootState, addErrorMessage } from 'features';
import { CountDownAndRedirect } from 'page/ProductSurveyPage/components';
import { ExpandGreyIcon } from 'components/icons';

const welcomeText = `
Ensuring the independence of older adults largely depends on the accessibility and usability of the products, services, and environments they interact with. Unfortunately, many products are designed without considering the needs and preferences of older individuals.
`;

const welcomeText2 = `
At the Global Centre for Modern Ageing (GCMA), we understand the importance of including older adults in product development. To address this issue, we have developed the GCMA Validation Framework, which is an inclusive tool to evaluate the usability of products for older adults, by older adults.
`;

const welcomeText3 = `
Based on this research, we have included ‘features’ that contribute to making a product accessible for older individuals. Features refer to various aspects of a product, such as weight, size, design, and more.`;

const ratingText = `
You will be asked to use the product and then rate its performance on a scale of 1 to 5, where 1 means "strongly disagree" and 5 means "strongly agree."`;

const importanceText = `
You will also be asked to rate the importance of each product feature using a scale of 1 to 5, where 1 means "not important" and 5 means "extremely important."`;

const privacyNotice = `
The information you provide in this survey is non-identifiable, meaning it cannot be traced back to you personally. However, all responses will still be kept confidential and will only be accessible to the research team. Your privacy is a priority.`;

export function CodesignerProductMainPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { codesignerId } = useParams();
	// Get product survey state from Redux
	const {
		loading, error, products, domains,
	} = useSelector((state: RootState) => state.productSurvey);
	const { currentSurveyId } = useSelector((state: RootState) => state.filters.survey);
	const [privacyNoticeOpen, setPrivacyNoticeOpen] = useState(false);
	const [haveReadPrivacyNotice, setHaveReadPrivacyNotice] = useState(false);
	// Dispatch the action to fetch product and domain information
	useEffect(() => {
		if (currentSurveyId) {
			dispatch(getProductSurveyQuestions({ data: { codesignerSurveyID: currentSurveyId } }) as any);
		}
	}, [dispatch, currentSurveyId]);

	const onProceed = () => {
		if (!haveReadPrivacyNotice) {
			dispatch(addErrorMessage('Please read the privacy notice before proceeding.'));
			return;
		}
		if (!checked) {
			dispatch(addErrorMessage('Please tick the checkbox to proceed.'));
			return;
		}
		navigate(`/codesigner/${codesignerId}/product-survey`);
	};
	const [checked, setChecked] = useState(false);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const getTotalNumberOfQuestions = () => {
		let total = 0;
		domains.forEach((domain) => {
			total += domain.features?.flatMap(
				(feature) => feature?.questions,
			)?.length || 0;
		});
		domains.forEach((domain) => {
			total += domain?.features?.length || 0;
		});
		return total;
	};

	const getDomainNames = () => {
		const domainNames = domains.map((domain) => domain.name);
		return domainNames.join(', ');
	};

	/** text that introduce how many questions are there in the survey, how many domains are there */
	const surveyIntroduction = `
This survey consists of ${getTotalNumberOfQuestions()} questions across ${domains.length} areas, including:
${getDomainNames()}
`;

	// Handle loading state
	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100vh">
				<CircularProgress />
				<Typography variant="h6" sx={{ marginLeft: 2 }}>Loading, please wait...</Typography>
			</Box>
		);
	}

	// Handle error state
	if (error) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100vh">
				<CountDownAndRedirect message="An error occurred. Redirecting you back to the homepage in" />
			</Box>
		);
	}

	return (
		<Box sx={{ padding: 4 }}>
			<Paper sx={{ padding: 4 }}>
				{/* Welcome Section */}
				<Typography
					sx={{
						fontSize: '28px',
						fontWeight: '500px',
					}}
					gutterBottom
				>
					Welcome to your Mavy Product Survey
				</Typography>
				{/* <Typography
					sx={{ fontSize: '18px' }}
					paragraph
					gutterBottom
				>
					{welcomeText}
				</Typography>
				<Typography
					sx={{ fontSize: '18px' }}
					paragraph
					gutterBottom
				>
					{welcomeText2}
				</Typography>
				<Typography
					sx={{ fontSize: '18px' }}
					paragraph
					gutterBottom
				>
					{welcomeText3}
				</Typography> */}

				{/* Product Info Section */}
				<Box sx={{ backgroundColor: '#f0f0f0', padding: 2, marginBottom: 4 }}>
					<Typography
						sx={{
							fontSize: '28px',
							fontWeight: '500px',
						}}
						gutterBottom
					>
						Your Survey Products
					</Typography>

					{/* Display products */}
					<Grid container spacing={4}>
						{products.map((product) => (
							<Grid item xs={12} sm={6} md={4} key={product.productId}>
								<Card>
									<CardMedia
										component="img"
										height="140"
										image={product.imageUrl}
										alt={product.modelName}
										style={{ objectFit: 'contain' }}
									/>
									<CardContent sx={{ margin: '0px' }}>
										<Typography
											sx={{
												fontSize: '18px',
												fontWeight: '500px',
											}}
											gutterBottom
										>
											{product.modelName}
										</Typography>
										<Typography
											sx={{
												fontSize: '14px',
												fontWeight: '500px',
											}}
											gutterBottom
										>
											{product.description}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
				</Box>

				{/* Survey Info Section */}
				<Accordion>
					<AccordionSummary expandIcon={<ExpandGreyIcon />}>
						<Typography
							sx={{
								fontSize: '28px',
								fontWeight: '500px',
							}}
							gutterBottom
						>
							Survey Information
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							sx={{
								fontSize: '18px',
								fontWeight: '500px',
							}}
							gutterBottom
						>
							{surveyIntroduction}
						</Typography>
					</AccordionDetails>
				</Accordion>
				{/* Explanation Section */}
				<Accordion>
					<AccordionSummary expandIcon={<ExpandGreyIcon />}>

						<Typography
							sx={{
								fontSize: '28px',
								fontWeight: '500px',
							}}
							gutterBottom
						>
							Types of Questions
						</Typography>
					</AccordionSummary>

					{/* Accordion for Rating Questions */}
					<Accordion>
						<AccordionSummary expandIcon={<ExpandGreyIcon />}>
							<Typography
								sx={{
									fontSize: '22px',
									fontWeight: '500px',
								}}
								gutterBottom
							>
								Rating Questions
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: '500px',
								}}
								paragraph
							>
								{ratingText}
							</Typography>
						</AccordionDetails>
					</Accordion>

					{/* Accordion for Importance Questions */}
					<Accordion
						disableGutters
					>
						<AccordionSummary expandIcon={<ExpandGreyIcon />}>
							<Typography
								sx={{
									fontSize: '22px',
									fontWeight: '500px',
								}}
								gutterBottom
							>
								Importance Questions
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: '500px',
								}}
								paragraph
							>
								{importanceText}
							</Typography>
						</AccordionDetails>
					</Accordion>
				</Accordion>
				{/* </Box> */}

				{/* Privacy Notice Section */}

				<Accordion
					disableGutters
					expanded={
						privacyNoticeOpen
					}
					onChange={() => {
						setPrivacyNoticeOpen(!privacyNoticeOpen);
						setHaveReadPrivacyNotice(true);
					}}
				>
					<AccordionSummary expandIcon={<ExpandGreyIcon />}>
						<Typography
							sx={{
								fontSize: '28px',
								fontWeight: '500px',
							}}
							gutterBottom
						>
							Privacy Notice
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							sx={{
								fontSize: '16px',
								fontWeight: '500px',
							}}
							paragraph
						>
							{privacyNotice}
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start',
						gap: '10px',
					}}
				>
					<Checkbox
						color="primary"
						// sx={{ fontSize: '18px' }}
						checked={checked}
						onChange={handleChange}
					/>
					<Typography variant="body2" color="error" sx={{ mb: 1 }}>
						*
					</Typography>
					<Typography
						sx={{
							fontSize: '18px',
							fontWeight: '500px',
							margin: '0px',
						}}
						gutterBottom
					>
						I have read and understood the Privacy Notice.
					</Typography>
				</Box>

				<Button
					variant="outlined"
					color="primary"
					sx={{ mt: 2 }}
					aria-label="Proceed to the next section"
					onClick={onProceed}
				>
					Start Survey
				</Button>
			</Paper>
		</Box>
	);
}

export default CodesignerProductMainPage;
