import {
	Grid, Box, useMediaQuery, Drawer,
} from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import theme from 'theme';
// import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'features';
import { getSurveyFilters, getSharedSurveyFilters } from 'thunk';
import { AnyAction } from '@reduxjs/toolkit';
import { ProductSidebar, FloatingButtons } from './components';
// import {RootState} from 'features/index.ts';

export function ClientProductPage() {
	const ref = useRef<HTMLDivElement>(null);
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	// const navigate = useNavigate();
	const dispatch = useDispatch();
	const { surveyId, singleSurveyId } = useParams();
	const { isShare, shareCode } = useSelector((state: RootState) => state.auth);
	// const { loading, error } = useSelector((state:RootState) => state.auth);
	// const { currentProduct, products } = useSelector((state) => state.filters.product);
	// const {currentSurveyId
	// } = useSelector((state:RootState) => state.filters.survey);

	const [isSidebarOpen, setIsSidebarOpen] = useState(isMobile);
	const handleSidebarOpen = () => {
		setIsSidebarOpen(true);
	};
	const handleSidebarClose = () => {
		setIsSidebarOpen(false);
	};

	const onScroll = () => {
		if (ref.current) {
			ref.current.scrollTop = 0;
		}
	};

	useEffect(() => {
		if (isShare && shareCode && surveyId) {
			dispatch(getSharedSurveyFilters(
				{
					data: { surveyId, code: shareCode },
				},
			) as any as AnyAction);
		} else if (isShare && shareCode && singleSurveyId) {
			dispatch(getSharedSurveyFilters(
				{
					data: { surveyId: singleSurveyId, code: shareCode },
				},
			) as any as AnyAction);
		} else if (!isShare && surveyId) {
			dispatch(getSurveyFilters({ data: surveyId }) as any as AnyAction);
		} else if (!isShare && singleSurveyId) {
			dispatch(getSurveyFilters({ data: singleSurveyId }) as any as AnyAction);
		}
	}, [surveyId, singleSurveyId, dispatch,
		isShare, shareCode, getSurveyFilters, getSharedSurveyFilters]);

	const renderSidebar = () => {
		if (isMobile) {
			return (
				<Drawer
					anchor="left"
					onClose={handleSidebarClose}
					open={isSidebarOpen}
					variant="temporary"
					PaperProps={{
						sx: {
							width: 350,
							top: 0,
							height: '100vh',
						},
					}}
				>
					<ProductSidebar
						onMobileClose={handleSidebarClose}
					/>
				</Drawer>
			);
		}

		return (
			<Grid
				item
				md={3}
				lg={3}
				sx={{
					height: '100vh',
					// overflow: 'auto',
				}}
			>
				<ProductSidebar />
			</Grid>
		);
	};
	return (
		<Grid
			container
			sx={
				{
					height: '100vh',
					margin: '0px',
					padding: '0px',
					overflow: 'hidden',
				}
			}
		>
			{renderSidebar()}
			<Grid
				item
				xs={12}
				sm={12}
				md={9}
				lg={9}
			>
				<Box
					ref={ref}
					sx={
						{
							width: '100%',
							display: 'flex',
							height: '100vh',
							flexDirection: 'column',
							justifyContent: 'start',
							overflow: 'auto',
							scrollBehavior: 'smooth',
							marginTop: '10px',
							marginBottom: '10px',
							// alignItems: 'center',
						}
					}
				>
					<Outlet />
					{/* {renderFloatingButton()} */}
					{/* {!isMobile && <ScrollToTopButton onClick={onScroll} />} */}
					<FloatingButtons handleMenuClick={handleSidebarOpen} handleScrollToTopClick={onScroll} />
				</Box>
			</Grid>
		</Grid>
	);
}
export default ClientProductPage;
