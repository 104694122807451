/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ErrorMessage from './ErrorMessage';

function DisplayErrorMessage() {
	const errorMessages = useSelector((state) => state.error.messages);
	// useEffect(
	// 	() => {
	// 	// debugging information
	// 		console.log('errorMessages:', errorMessages);
	// 	},
	// 	[errorMessages],
	// );

	return (
		<div>
			{errorMessages?.map((message, index) => {
				if (typeof message === 'string') {
					return (
						<ErrorMessage
							message={message}
							index={index}
							open
							key={`error-message-${index}`}
						/>
					);
				}
				return (
					<ErrorMessage
						message="An error occurred. Please try again later."
						index={index}
						open
						key={`error-message-${index}`}
					/>
				);
			})}
		</div>
	);
}

export default DisplayErrorMessage;
