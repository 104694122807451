/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { RATING_THUNK_PREFIX } from 'thunk';
import { ProductRating, SurveyProductRatingResponse } from 'api';

export interface ProductRatingState {
    loading: boolean;
    error: string | null;
    ratingEntities: { [id: string]: ProductRating },
    productIds: string[];
}

const initialState: ProductRatingState = {
	loading: false,
	error: null,
	ratingEntities: {},
	productIds: [],
};

const productRatingSlice = createSlice({
	name: 'productRating',
	initialState,

	reducers: {},

	extraReducers: (builder) => {
		builder.addMatcher(
			(action) => action.type.startsWith(RATING_THUNK_PREFIX)
			&& action.type.endsWith('pending'),
			(state: ProductRatingState) => {
				Object.assign(state, initialState);
				state.loading = true;
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(RATING_THUNK_PREFIX)
			&& action.type.endsWith('rejected'),
			(state: ProductRatingState) => {
				Object.assign(state, initialState);
				state.error = 'Error in fetching product rating';
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(RATING_THUNK_PREFIX)
			&& action.type.endsWith('fulfilled'),
			(state: ProductRatingState, action: { payload: SurveyProductRatingResponse }) => {
				state.loading = false;
				state.error = null;
				state.ratingEntities = action.payload.productRatings.reduce((acc, rating) => {
					acc[String(rating.productId)] = rating;
					return acc;
				}, {} as { [id: string]: ProductRating });
				state.productIds = action.payload.productRatings.map((rating) => rating.productId);
			},
		);
	},
});

export const productRatingReducer = productRatingSlice.reducer;
