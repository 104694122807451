import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { useEffect } from 'react';
import { RootState, setSelectedMultipleOption } from 'features';
import { firstCharToUpperCase } from 'utils';
import SelectedRelatedProductChips from './SelectedRelatedProductChips';

function SurveySelector() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isShare, shareCode } = useSelector((state: RootState) => state.auth);
	const {
		multipleOptions, selectedMultipleOption,
	} = useSelector((state: RootState) => state.filters.survey);
	const { surveyId, clientId } = useParams();

	const handleChange = (event: SelectChangeEvent<string>) => {
		const selectedValue = event.target.value;
		dispatch(setSelectedMultipleOption(selectedValue));
		// navigate to the path /client/clientId/comparative/:surveyId
		navigate(`/client/${clientId}/comparative/${selectedValue.toString()}`);
	};

	useEffect(
		() => {
			// initial load, when surveyId is available
			if (surveyId && selectedMultipleOption !== surveyId) {
				// change the selected option to the surveyId
				dispatch(setSelectedMultipleOption(surveyId));
			} else if (!surveyId && selectedMultipleOption) {
				if (!isShare) {
					// if surveyId is not available, navigate to the first survey
					navigate(`/client/${clientId}/comparative/${selectedMultipleOption.toString()}`);
				} else {
					navigate(`/share/comparative/${selectedMultipleOption.toString()}?code=${shareCode}`);
				}
			} else if (!surveyId && !selectedMultipleOption && multipleOptions.length > 0) {
				// if surveyId is not available, navigate to the first survey
				dispatch(setSelectedMultipleOption(multipleOptions[0].value));
				if (isShare) {
					navigate(`/share/comparative/${multipleOptions[0].value}?code=${shareCode}`);
				} else {
					navigate(`/client/${clientId}/comparative/${multipleOptions[0].value}`);
				}
			}
		},
		[dispatch, selectedMultipleOption,
			surveyId, isShare, clientId, multipleOptions, navigate, shareCode],
	);

	const getRenderedValue = (value: string) => {
		const selectedSurvey = multipleOptions.find((survey) => String(survey.value) === String(value));
		if (selectedSurvey) {
			return firstCharToUpperCase(selectedSurvey.label);
		}
		return '';
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Select
				value={selectedMultipleOption ?? ''}
				onChange={handleChange}
				inputProps={{ 'aria-label': 'Without label' }}
				sx={
					{
						marginLeft: '0px',
						marginBottom: '10px',
						marginTop: '10px',
						width: '100%',
						height: '35px',
						textAlign: 'left',
						paddingLeft: '0px',
					}
				}
				native={false}
				renderValue={getRenderedValue}
			>
				{multipleOptions.map((survey) => (
					<MenuItem
						key={`${survey.value}-menu-item`}
						value={survey.value}
						sx={{
							fontFamily: 'Inter, sans-serif',
							fontWeight: '400',
							display: 'flex',
							flexDirection: 'column',
							alignContent: 'flex-start',
							textAlign: 'left', // Ensure text aligns to the left
						}}
					>
						<Typography
							key={`${survey.value}-menu-item-text`}
							fontFamily="Inter, sans-serif"
							fontWeight="600"
						>
							{firstCharToUpperCase(survey.label)}
						</Typography>
						{survey?.productList?.map((product) => (
							<Box
								key={product.productId}
								sx={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									pl: 2,
								}}
							>
								<Box sx={{
									width: '4px',
									height: '4px',
									borderRadius: '50%',
									backgroundColor: 'currentColor',
									marginRight: '8px',
								}}
								/>
								<Typography>
									{firstCharToUpperCase(product.productName)}
								</Typography>
							</Box>
						))}
					</MenuItem>

				))}
			</Select>
			<SelectedRelatedProductChips />
		</Box>
	);
}

export default SurveySelector;
