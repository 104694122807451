import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { RootState, setSelectedSingleOption } from 'features';
import theme from 'theme';
import { useEffect } from 'react';

function ProductSelector() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { singleSurveyId, clientId } = useParams();
	const { isShare } = useSelector((state: RootState) => state.auth);
	const { selectedSingleOption, singleOptions } = useSelector(
		(state: RootState) => state.filters.survey,
	);
	const handleChange = (event: SelectChangeEvent<string>) => {
		const selectedValue = event.target.value;
		dispatch(setSelectedSingleOption(selectedValue));
		// navigate to the path /client/clientId/single/:surveyId
		navigate(`/client/${clientId}/single/${selectedValue}`);
	};

	useEffect(
		() => {
			if (!isShare) {
				// initial load, when surveyId is available
				if (singleSurveyId && selectedSingleOption !== singleSurveyId) {
				// change the selected option to the surveyId
					dispatch(setSelectedSingleOption(singleSurveyId));
				} else if (!singleSurveyId && selectedSingleOption) {
				// if surveyId is not available, navigate to the first survey
					navigate(`/client/${clientId}/single/${selectedSingleOption.toString()}`);
				} else if (!singleSurveyId && !selectedSingleOption && singleOptions.length > 0) {
				// if surveyId is not available, navigate to the first survey
					dispatch(setSelectedSingleOption(singleOptions[0].value));
					navigate(`/client/${clientId}/single/${singleOptions[0].value}`);
				}
			}
		},
		[dispatch, selectedSingleOption, singleSurveyId, isShare, clientId, singleOptions, navigate],
	);

	return (
		<Select
			value={selectedSingleOption ?? ''}
			onChange={handleChange}
			displayEmpty
			inputProps={{ 'aria-label': 'Without label' }}
			sx={{
				marginLeft: '0px',
				marginBottom: '10px',
				marginTop: '10px',
				width: '100%',
				height: '35px',
				textAlign: 'left',
				paddingLeft: '0px',
			}}
		>
			{singleOptions?.length > 0 ? singleOptions.map((option) => (
				<MenuItem
					key={`surveyOption-${option.value}`}
					value={option.value} // Pass the number directly
				>
					{option.label}
				</MenuItem>
			)) : (
				<MenuItem
					value=""
					disabled
					sx={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: '400',
						alignContent: 'start',
					}}
				>
					<Typography
						sx={{
							color: theme.palette.text.disabled,
						}}
					>
						No products available
					</Typography>
				</MenuItem>
			)}
		</Select>
	);
}

export default ProductSelector;
