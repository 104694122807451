import { useSelector, useDispatch } from 'react-redux';
import { Slider, Box } from '@mui/material';
import theme from 'theme';
import { RootState, setSelectedPersonaRange } from 'features';

function ZeroToFivePersonaSlider({ personaId }: { personaId: string }) {
	const dispatch = useDispatch();
	const {
		allowedMin, allowedMax, selectedMin, selectedMax,
	} = useSelector(
		(state: RootState) => state.filters.persona.filterSelection[personaId],
	);

	const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
		if (Array.isArray(newValue)) {
			if (newValue[0] < allowedMin) {
				dispatch(
					setSelectedPersonaRange({
						id: personaId,
						selectedMin: allowedMin,
						selectedMax: newValue[1],
					}),
				);
			} else if (newValue[1] > allowedMax) {
				dispatch(
					setSelectedPersonaRange({
						id: personaId,
						selectedMin: newValue[0],
						selectedMax: allowedMax,
					}),
				);
			} else {
				dispatch(
					setSelectedPersonaRange({
						id: personaId,
						selectedMin: newValue[0],
						selectedMax: newValue[1],
					}),
				);
			}
		}
	};

	const valueLabelFormat = (val: number) => `${val}`;

	return (
		<Box
			sx={{
				width: '100%',
				position: 'relative',
				marginBottom: '24px',
				marginTop: '-10px',
			}}
		>
			<Slider
				defaultValue={[selectedMin, selectedMax]}
				marks
				step={1}
				sx={{
					'& .MuiSlider-thumb': {
						width: '15px',
						height: '15px',
					},
					'&& .MuiSlider-valueLabel': {
						backgroundColor: `${theme.palette.grey} !important`,
						color: '#fff',
					},
					width: '83%',
					marginLeft: '5%',
					position: 'absolute',
					top: '0',
				}}
				min={1}
				max={5}
				value={[selectedMin, selectedMax]}
				onChange={handleChange}
				valueLabelFormat={valueLabelFormat}
				valueLabelDisplay="auto"
				disableSwap
				getAriaValueText={valueLabelFormat}
			/>
		</Box>
	);
}

export default ZeroToFivePersonaSlider;
