import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Typography,
	CircularProgress,
	Button,
	Snackbar,
	Alert,
} from '@mui/material';
import {
	RootState,
	updatePersonaAnswer,
	addToShouldRenderQuestion,
	removeFromShouldRenderQuestion,
	clearAnswersForQuestions, setPersonaDone,
} from 'features';
import { getPersonaSurveyQuestions, postPersonaSurveyQuestions } from 'thunk';
import { PersonaSurveyAnswers } from 'api';
import { PersonaQuestion } from './components';

function PersonaSurveyPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userId } = useSelector((state: RootState) => state.auth);
	const {
		loading,
		error,
		childQuestions,
		answerEntities,
		questionEntities,
		shouldRenderQuestion,
		submitSuccess,
	} = useSelector(
		(state: RootState) => state.personaSurvey,
	);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [successMessageOpen, setSuccessMessageOpen] = useState(false);

	useEffect(() => {
		dispatch(getPersonaSurveyQuestions() as any);
	}, [dispatch]);

	const handleAnswerChange = (questionId: string, answer?: string, optionId?: string) => {
		// Dispatch an action to update the answer in the Redux state
		dispatch(updatePersonaAnswer({ questionId, answer, optionId }));
		const checkQuestion = questionEntities?.[questionId];
		const selectedOption = checkQuestion?.options.find(
			(option) => option.optionId.toString() === optionId,
		);

		if (selectedOption) {
			// If the selected option has child questions (nextQuestionIds)
			if (selectedOption.nextQuestionIds.length > 0) {
				// Add the child questions to `shouldRenderQuestion`
				dispatch(addToShouldRenderQuestion({
					parentQuestionId: questionId,
					childQuestionIds: selectedOption.nextQuestionIds.map(String),
				}));
			} else {
				// If the option doesn't have nextQuestionIds, remove its child questions
				const childQuestionIdsToRemove = childQuestions.filter((childQuestionId) => {
					const childQuestion = questionEntities?.[childQuestionId];
					return childQuestion?.parentQuestionId.toString() === questionId;
				});

				// Remove the answers for the removed child questions from `answerEntities`
				dispatch(removeFromShouldRenderQuestion(childQuestionIdsToRemove));
				dispatch(clearAnswersForQuestions(childQuestionIdsToRemove));
			}
		}
	};

	const handleSubmit = () => {
		// Check if all required questions are answered
		const allAnswered = shouldRenderQuestion.every((questionId) => {
			const answer = answerEntities?.[questionId];
			// Validate the age field for questions that have a numeric input
			if (questionEntities?.[questionId]?.questionType === 'open') {
				const value = parseInt(answer?.answer || '', 10);
				if (value < 18 || value > 120) {
					setErrorMessage('Age must be between 18 and 120.');
					// console.log(errorMessage);
					return false;
				}
			}

			if (!answer || (!answer.answer && !answer.selectedOptionId)) {
				setErrorMessage('Please answer all required questions.');
				return false; // Return false if any required answer is missing
			}
			return true;
		});

		// If not all questions are answered, show error snackbar
		if (!allAnswered) {
			return;
		}

		// If all questions are answered, submit the survey
		const AnswerData = Object.values(answerEntities || {}).map((answer: any) => ({
			questionId: answer.questionId,
			selectedOptionId: answer.selectedOptionId || null,
			answer: answer.answer || null,
		}));
		const questionAnswers = {
			questionAnswers: AnswerData,
		} as PersonaSurveyAnswers;

		// Dispatch the post action with correct payload structure
		dispatch(postPersonaSurveyQuestions({ data: { questionAnswers } } as any)as any)
			.unwrap()
			.then(() => {
				// Show success message
				setSuccessMessageOpen(true);
				dispatch(setPersonaDone(true));

				// Delay for 3 seconds before navigating
				setTimeout(() => {
					setSuccessMessageOpen(false); // Hide the success message after the delay
					navigate(`/codesigner/${userId}`);
				}, 3000); // 3 seconds delay
			})
			.catch(() => {
				// Handle error (no navigation if the submit fails)
				setErrorMessage('An error occurred while submitting the survey. Please try again.');
			});
	};

	if (loading) {
		return <CircularProgress />;
	}

	if (error) {
		return (
			<Typography color="error">
				Error:
				{' '}
				{error}
			</Typography>
		);
	}

	if (submitSuccess) {
		return (
			<Snackbar
				open={successMessageOpen}
				autoHideDuration={4000}
				onClose={() => setSuccessMessageOpen(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="success" onClose={() => setSuccessMessageOpen(false)}>
					Survey submitted successfully!
				</Alert>
			</Snackbar>
		);
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
				minHeight: '100vh',
				backgroundColor: '#f4f4f4',
			}}
		>
			<Box
				sx={{
					width: '80%',
					maxWidth: '1000px',
					backgroundColor: 'white',
					padding: 4,
					borderRadius: 2,
					boxShadow: 3,
					marginTop: '10%',
					marginBottom: '10%',
				}}
			>
				<Typography variant="h3" gutterBottom textAlign="center">
					Persona Survey
				</Typography>

				{/* Render the current question */}
				{shouldRenderQuestion.map((questionId, index) => {
					const question = questionEntities?.[questionId];

					return question ? (
						<Box key={question.questionId}>
							<PersonaQuestion
								question={question}
								index={index}
								handleAnswerChange={handleAnswerChange}
							/>
						</Box>
					) : null;
				})}

				{/* Pagination buttons */}
				<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
					<Button variant="outlined" color="primary" onClick={handleSubmit}>
						Submit
					</Button>
				</Box>
			</Box>
			{/* Error Snackbar */}
			<Snackbar
				open={Boolean(errorMessage)}
				autoHideDuration={4000}
				onClose={() => setErrorMessage(null)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error" onClose={() => setErrorMessage(null)}>
					{errorMessage}
				</Alert>
			</Snackbar>

			{/* Success Snackbar */}
			<Snackbar
				open={successMessageOpen}
				autoHideDuration={4000}
				onClose={() => setSuccessMessageOpen(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="success" onClose={() => setSuccessMessageOpen(false)}>
					Survey submitted successfully!
				</Alert>
			</Snackbar>
		</Box>
	);
}

export default PersonaSurveyPage;
