import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Navigate, Outlet, useLocation, useNavigate,
} from 'react-router-dom';
import { RootState } from 'features';
import { getAccess } from 'thunk'; // Assuming you have an action creator
import { CircularProgress } from '@mui/material';

function ProtectedRoute(): JSX.Element {
	const {
		isLoggedIn, userId, loading, error,
	} = useSelector((state: RootState) => state.auth);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	// Local state to track if the initial check is done
	const [authChecked, setAuthChecked] = useState(false);

	useEffect(() => {
		// Only dispatch getAccess if the user is not logged in and we are not loading
		if (!isLoggedIn && !loading && !error && authChecked === false) {
			dispatch(getAccess({ suppressed: true }) as any).finally(() => {
				setAuthChecked(true); // Set authChecked to true once the auth check is complete
			});
		} else {
			// If logged in or already checked, set authChecked to true
			setAuthChecked(true);
		}

		// If logged in and user has an intended destination, navigate there
		if (isLoggedIn && userId && location.state?.from) {
			navigate(location.state?.from);
		}
	}, [dispatch, isLoggedIn, navigate, userId, location.state?.from, loading, error, authChecked]);

	// Show loading spinner while authentication state is being determined
	if (loading || !authChecked) {
		console.log('ProtectedRoute: loading');
		console.log('location', location);
		return <CircularProgress color="inherit" />;
	}

	// If not logged in and not loading, redirect to login
	if (!isLoggedIn && authChecked) {
		console.log('ProtectedRoute: redirecting to login');
		console.log('location', location);
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	// If logged in and access is authorized, render the outlet
	if (isLoggedIn) {
		console.log('ProtectedRoute: authorized');
		console.log('location', location);
		return <Outlet />;
	}

	// Default return in case of an unknown error
	return <>Unknown error</>;
}

export default ProtectedRoute;
