import { axiosInstance, CUSTOMER_CONFIG } from 'utils';

const PRODUCT_SURVEY_PATH = '/product-survey';

// Domain List
export interface ProductQuestion {
    questionId: string;
    content: string;
}

export interface Feature {
    featureId: string;
    name: string;
    description: string;
    questions: ProductQuestion[];
}

export interface Domain {
    domainId: string;
    name: string;
    iconMain: string;
    iconRound: string;
    description: string;
    features: Feature[];
}

// Product
export interface SurveyProduct {
    productId: string;
    modelName: string;
    modelNumber: string;
    imageUrl: string;
    description: string;
}

// Main Response

export interface ProductSurveyQuestions {
    templateId: string;
    name: string;
    type: string;
    description: string;
    domains:Domain[]
    products: SurveyProduct[];
}

export const getProductSurveyQuestions = async ({ codesignerSurveyID }:{
    codesignerSurveyID:string
}): Promise<ProductSurveyQuestions> => {
	const response = await axiosInstance.get(`${PRODUCT_SURVEY_PATH}/${codesignerSurveyID}`, CUSTOMER_CONFIG());
	return response as any as ProductSurveyQuestions;
};

// Post Requests
// Rating for a specific question
export interface Rating {
    questionId: string;
    rating: number | null;
}

// Feature Rating, including the list of question ratings and importance
export interface SurveyFeatureRating {
    featureId: string;
    ratings: Rating[];
    importance: number | null;
}

// Domain Rating, including the list of feature ratings
export interface SurveyDomainRating {
    domainId: string;
    featureRatings: SurveyFeatureRating[];
}

// Product Rating, including the list of domain ratings
export interface SurveyProductRating {
    productId: string;
    domainRatings: SurveyDomainRating[];
}

export const submitProductSurvey = async ({
	surveyId,
	productRatings,
}:{
surveyId:string,
productRatings:SurveyProductRating[]
}) => {
	axiosInstance.post(`${PRODUCT_SURVEY_PATH}/${surveyId}`, { productRatings }, CUSTOMER_CONFIG());
};
