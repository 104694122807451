/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CODESIGNER_THUNK_PREFIX } from 'thunk';
import { getCodesignerResponse, CodesignerSurvey } from 'api';

export interface CoDesignerSurveyState {
	loading: boolean;
	error: any;
	currentCodesignerId: string | null;
	codesignerIds: string[];
	codesignerEntities: { [id: string]: CodesignerSurvey };
}

const initialState: CoDesignerSurveyState = {
	loading: false,
	error: null,
	currentCodesignerId: null,
	codesignerIds: [],
	codesignerEntities: {},
};
const coDesignerSurveySlice = createSlice({
	name: 'coDesignerSurvey',
	initialState,
	reducers: {
		setCurrentCodesignerId(state, action) {
			state.currentCodesignerId = (action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) => action.type.startsWith(CODESIGNER_THUNK_PREFIX)
			&& action.type.endsWith('pending'),
			(state) => {
				Object.assign(state, initialState);
				state.loading = true;
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(CODESIGNER_THUNK_PREFIX)
			&& action.type.endsWith('rejected'),
			(state, action) => {
				Object.assign(state, initialState);
				state.error = action.payload;
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(CODESIGNER_THUNK_PREFIX)
			&& action.type.endsWith('fulfilled'),
			(state, action: { payload: getCodesignerResponse }) => {
				state.loading = false;
				state.error = null;
				state.codesignerIds = action.payload.surveyCodesigners?.map(
					(codesigner) => codesigner.codesignerId,
				);
				state.codesignerEntities = action.payload.surveyCodesigners?.reduce((acc, codesigner) => {
					acc[String(codesigner.codesignerId)] = codesigner;
					return acc;
				}, {} as { [id: string]: CodesignerSurvey });
			},
		);
	},
});

export const {
	setCurrentCodesignerId,
} = coDesignerSurveySlice.actions;

export const coDesignerSurveyReducer = coDesignerSurveySlice.reducer;
