import AuthError from './AuthError';

export class LoginExpiredError extends AuthError {
	constructor() {
		super('Your login has expired. Please login again.');
		this.name = 'LoginExpiredError';
	}
}

export default LoginExpiredError;
