// query the window width and set the height of the feature list accordingly
// if large or above, 63*maxFeatureLines
// if large, 63*maxFeatureLines
// if larger than 900 60*maxFeatureLines
import { useEffect, useState } from 'react';
// if smaller than 600 57*maxFeatureLines
import { useSelector } from 'react-redux';
import { MaxFeatureLinesCalculator } from 'utils';

function FeatureListHeight() {
	const [height, setHeight] = useState(0);
	const { ratingEntities, loading, error } = useSelector((state) => state.product.rating);

	useEffect(() => {
		if (loading) return;
		if (error) return;
		// console.log(ratingEntities);
		const maxFeatureLines = MaxFeatureLinesCalculator(ratingEntities);
		const widith = 65 * maxFeatureLines;
		setHeight(widith);
	}, [ratingEntities, loading, error]);

	return {
		height,
	};
}

export default FeatureListHeight;
