/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonaSurveyQuestions, Question, QuestionAnswer } from 'api'; // Adjust the import based on your file structure
import { getPersonaSurveyQuestions, postPersonaSurveyQuestions } from 'thunk'; // This thunk will be created to fetch the survey data

const loadStateFromLocalStorage = () => {
	try {
		const serializedState = localStorage.getItem('surveyAnswers');
		if (serializedState === null) {
			return null;
		}
		return JSON.parse(serializedState);
	} catch (e) {
		console.error('Could not load state from localStorage', e);
		return null;
	}
};
const saveStateToLocalStorage = (state: any) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('surveyAnswers', serializedState);
	} catch (e) {
		console.error('Could not save state to localStorage', e);
	}
};
interface QuestionEntity{
    [questionId:string]:Question
}

interface AnswerEntities{
[questionId:string]:QuestionAnswer
}

export interface SurveyState {
    loading: boolean;
    error: string | null;
    // questions: Question[];
	// renderQuestions: Question[];
	parentQuestions:string[];
	childQuestions:string[];
	shouldRenderQuestion:string[];
	answerEntities:AnswerEntities|null;
	questionEntities:QuestionEntity|null;
	submitSuccess: boolean;
}

const initialState: SurveyState = {
	loading: false,
	error: null,
	// questions: [],
	// renderQuestions: Question[];
	parentQuestions: [],
	childQuestions: [],
	shouldRenderQuestion: [],
	answerEntities: loadStateFromLocalStorage() || {},
	questionEntities: null,
	// renderQuestions: [],
	submitSuccess: false,
};

const surveySlice = createSlice({
	name: 'survey',
	initialState,
	reducers: {
		clearAnswersForQuestions: (state, action: PayloadAction<string[]>) => {
			if (state.answerEntities) {
				action.payload.forEach((questionId) => {
					delete state.answerEntities?.[questionId];
				});
			}
		},
		addToShouldRenderQuestion: (state, action: PayloadAction<{
			parentQuestionId: string, childQuestionIds: string[] }>) => {
			const { parentQuestionId, childQuestionIds } = action.payload;

			// Find the index of the parent question in `shouldRenderQuestion`
			const parentIndex = state.shouldRenderQuestion.indexOf(parentQuestionId);

			// If the parent is found, insert child questions right after the parent
			if (parentIndex !== -1) {
				// Remove any duplicates from the child questions
				const uniqueChildQuestions = childQuestionIds
					.filter((childId) => !state.shouldRenderQuestion.includes(childId));

				// Insert child questions after the parent question
				state.shouldRenderQuestion.splice(parentIndex + 1, 0, ...uniqueChildQuestions);
			}
		},
		removeFromShouldRenderQuestion: (state, action: PayloadAction<string[]>) => {
			// Remove the questions in the provided childQuestionIds array from `shouldRenderQuestion`
			state.shouldRenderQuestion = state.shouldRenderQuestion.filter(
				(questionId) => !action.payload.includes(questionId),
			);
		},
		// Reducer to handle updating answers in the state
		updatePersonaAnswer: (state, action: PayloadAction<{
			questionId: string; answer?: string; optionId?: string }>) => {
			const { questionId, answer, optionId } = action.payload;

			// Initialize answerEntities if null
			if (!state.answerEntities) {
				state.answerEntities = {};
			}

			// Check if the answer is for a selected option or an open answer
			if (state.answerEntities[questionId]) {
				// Update the answer entity based on whether it's an option selection or an open answer
				if (optionId) {
					state.answerEntities[questionId].selectedOptionId = optionId;
					delete state.answerEntities[questionId].answer; // Remove the answer if
				} else if (answer) {
					state.answerEntities[questionId].answer = answer;
					delete state.answerEntities[questionId].selectedOptionId; // Remove
				}
			} else {
				// Create a new answer entity if it doesn't already exist
				state.answerEntities[questionId] = {
					questionId,
					...(optionId ? { selectedOptionId: optionId } : {}),
					...(answer ? { answer } : {}),
				};
			}
			saveStateToLocalStorage(state.answerEntities);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPersonaSurveyQuestions.pending, (state:SurveyState) => {
			Object.assign(state, initialState);
			state.loading = true;
		});
		builder.addCase(
			getPersonaSurveyQuestions.fulfilled,
			(state, action: PayloadAction<PersonaSurveyQuestions>) => {
				state.loading = false;
				const questionEntities: QuestionEntity = {};
				const parentQuestions: string[] = [];
				const childQuestions: string[] = [];
				// Iterate over the fetched questions and store each by its questionId
				action.payload.questions.forEach((question) => {
					// Store each question in questionEntities by its questionId
					questionEntities[question.questionId] = question;

					// Classify questions as parent or child based on the parentQuestionId
					if (question.parentQuestionId === null) {
						parentQuestions.push(question.questionId.toString());
					} else {
						childQuestions.push(question.questionId.toString());
					}
				});

				state.questionEntities = questionEntities;
				state.parentQuestions = parentQuestions;
				state.shouldRenderQuestion = [...parentQuestions];
				state.childQuestions = childQuestions;
			},
		);
		builder.addCase(getPersonaSurveyQuestions.rejected, (state, action) => {
			Object.assign(state, initialState);
			state.error = action.error.message || 'Failed to fetch survey data';
		});
		// Submit Survey Answers (POST)
		builder.addCase(postPersonaSurveyQuestions.pending, (state) => {
			state.loading = true;
			state.error = null;
			state.submitSuccess = false;
		});
		builder.addCase(postPersonaSurveyQuestions.fulfilled, (state) => {
			state.loading = false;
			state.submitSuccess = true; // Mark submission as successful
			localStorage.removeItem('surveyAnswers');
		});
		builder.addCase(postPersonaSurveyQuestions.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error?.message || 'Failed to submit survey answers';
			state.submitSuccess = false; // Mark submission as failed
		});
	},
});

export const {
	updatePersonaAnswer, addToShouldRenderQuestion,
	removeFromShouldRenderQuestion,
	clearAnswersForQuestions,
} = surveySlice.actions;
export const personaSurveyReducer = surveySlice.reducer;
