/* eslint-disable no-shadow */
import { CUSTOMER_CONFIG, axiosInstance, CUSTOMER_SHARE_CONFIG } from 'utils';

const LOGIN_PATH = '/public/login';
const USER_ACCESS_PATH = '/access';

export interface LoginRequest {
    email: string;
    password: string;
    rememberMe: boolean;
}

export enum UserRole {
    ADMIN = 'admin',
    CUSTOMER = 'customer',
    CODESIGNER = 'codesigner',
}

export interface SingleProductSurvey{
    surveyId:string;
    productId:string;
    productName:string;
    productDone?:boolean;
}

export interface ProductInfo{
    productId:string;
    productName:string;
}
export interface MultipleProductSurvey{
    surveyId:string;
    surveyName:string;
    productList:ProductInfo[];
    productDone?:boolean;
}
export interface LoginResponse {
    id:string;
    userRole:UserRole;
    firstName:string;
    lastName:string;
    personaDone: boolean;
    singleProductSurveyList:SingleProductSurvey[];
    multipleProductSurveyList:MultipleProductSurvey[];
    accessToken:string;
    refreshToken?:string;
}

export interface shareCodeAccess {
    singleProductSurveyList: SingleProductSurvey[];
    multipleProductSurveyList: MultipleProductSurvey[];
}

export const login = async ({ email, password, rememberMe }: LoginRequest) => {
	const response = await axiosInstance.post(LOGIN_PATH, {
		email,
		password,
		rememberMe,
	});
	return response as any as LoginResponse;
};

export const auth = async () => {
	// console.log('CUSTOMER_CONFIG()', CUSTOMER_CONFIG());
	const response = await axiosInstance.get(USER_ACCESS_PATH, CUSTOMER_CONFIG());
	return response as any as LoginResponse;
};

export const getShareCodeAccess = async (
	{ surveyId, code }: { surveyId: string; code: string },
): Promise<shareCodeAccess> => {
	const response = await axiosInstance.get(`/survey/${surveyId}/access`, {
		...CUSTOMER_SHARE_CONFIG(code),
	});
	// console.log('response', response);
	return response as any as shareCodeAccess;
};
