import { useSelector } from 'react-redux';
import {
	Typography, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import { useState } from 'react';
import colors from 'theme/colors';
import { RootState } from 'features';
import ProductIntroSkeleton from './ProductIntroSkeleton';
import ProductIntroContent from './ProductIntroContent';
import ProductIntroCardHeader from './ProductIntroCardHeader';
import { ExpandGreyIcon } from '../../icons';

export function ProductIntroCard({ productId }: {productId: string}) {
	const {
		loading, error, productEntities,
	} = useSelector((state: RootState) => state.product.productInfo);
	const [expanded, setExpanded] = useState('product-intro-header');
	const getSummaryPaddingBottom = () => {
		if (expanded === 'product-intro-header') {
			return '0px';
		}
		return '16px';
	};

	const renderProductIntroContent = () => {
		if (error) {
			return null;
		}
		if (loading) {
			return <ProductIntroSkeleton />;
		}
		if (productEntities && productEntities[String(productId)]) {
			return <ProductIntroContent productId={String(productId)} />;
		}

		return <Typography>No product info</Typography>;
	};

	const onAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? 'product-intro-header' : '');
	};

	return (
		<Accordion
			defaultExpanded
			disableGutters
			expanded={expanded === 'product-intro-header'}
			onChange={
				onAccordionChange
			}
		>
			<AccordionSummary
				expandIcon={(
					<ExpandGreyIcon
						fontSize="large"
						sx={{
							padding: '0px',
							width: ['40px', '40px', '36px'],
							height: ['40px', '40px', '36px'],
						}}

					/>
				)}
				aria-controls="product-intro-header"
				id="product-intro-header"
				sx={{
					backgroundColor: colors.white,
					paddingTop: '16px',
					paddingLeft: '16px',
					paddingRight: '16px',
					paddingBottom: { getSummaryPaddingBottom },
				}}
			>
				<ProductIntroCardHeader
					expanded={expanded === 'product-intro-header'}
					productId={String(productId)}
				/>
			</AccordionSummary>
			<AccordionDetails
				sx={
					{
						display: 'flex',
						flexDirection: 'column',
						paddingTop: '0px',
						// paddingBottom: '0px',
					}
				}
			>
				{renderProductIntroContent()}
			</AccordionDetails>

		</Accordion>
	);
}

export default ProductIntroCard;
