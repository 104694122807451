import {
	Box, Skeleton, Typography,
} from '@mui/material';
import { DomainRating, FeatureRating } from 'api';
import FeatureRow from './FeatureRow';

function FeatureList({
	domainRating, importanceRatingOn, error, loading,
}:{
	domainRating: DomainRating,
	importanceRatingOn: boolean,
	error: boolean,
	loading: boolean,
}) {
	if (!domainRating) {
		return null;
	}
	const {
		featureRatings,
	} = domainRating;
	const filteredFeatureRatings = featureRatings?.filter(
		(featureRating) => parseFloat(featureRating?.rating) !== 0,
	);

	if (!filteredFeatureRatings || filteredFeatureRatings?.length === 0) {
		return null;
	}

	const getFeatureScoreRows = () => filteredFeatureRatings
	&& filteredFeatureRatings.map((featureRating, index) => (
		getFeatureRowRendered(featureRating, index)
	));

	const getFeatureRowRendered = (featureRating:FeatureRating, index:number) => {
		if (error) {
			return null;
		}
		if (loading) {
			return (
				<Skeleton
					key={`${domainRating?.domainId}-${index}-feature-row`}
					variant="rectangular"
					width="100%"
					height={20}
					sx={{ margin: '5px' }}
				/>
			);
		}
		if (featureRating && parseFloat(featureRating.rating) !== 0) {
			return (
				<FeatureRow
					key={`${featureRating.featureId}-feature-row`}
					domainId={String(domainRating.domainId)}
					featureRating={featureRating}
					importanceRatingOn={importanceRatingOn}
				/>
			);
		}

		return null;
	};
	return (
		<Box
			sx={{ height: '100%' }}
		>
			<Typography
				sx={{
					color: '#455468',
					fontFamily: 'Inter, sans-serif',
					fontWeight: 600,
					fontSize: '16px',
					textAlign: 'left',
					marginBottom: ['0px', '5px', '10px'], // [mobile, tablet, desktop
				}}
			>
				Features
			</Typography>
			<Box
				// overflow="auto"
				sx={{
					// height: '150px',
					height: `${65 * (featureRatings?.length ?? 0)}px`,
					width: '100%',
					paddingRight: '8px',
					paddingLeft: '8px',
				}}
			>
				{getFeatureScoreRows()}
			</Box>

		</Box>
	);
}
export default FeatureList;
