import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import DomainGeneralSkeleton from './DomainGeneralSkeleton';
import DomainGeneralContent from './DomainGeneralContent';

function DomainGeneral({
	domainId, importanceRatingOn, productId,
}:{
	domainId:string, importanceRatingOn:boolean, productId:string
}) {
	const { loading, error, ratingEntities } = useSelector((state:RootState) => state.product.rating);
	const ratingData = ratingEntities[productId]?.domainRatings;
	const domainRatingData = ratingData?.find((rating) => rating.domainId === domainId);
	if (loading) {
		return <DomainGeneralSkeleton />;
	}
	if (error) {
		return null;
	}
	if (domainRatingData) {
		return (
			<DomainGeneralContent
				domainRating={domainRatingData}
				importanceRatingOn={importanceRatingOn}
			/>
		);
	}

	return null;
}

export default DomainGeneral;
