import { useSelector } from 'react-redux';
import {
	Typography, Box, Divider,
} from '@mui/material';
import { useState } from 'react';
import { RootState } from 'features';
import DomainGeneral from './DomainGeneralV2';
import FeatureList from './FeatureList';
import Toggle from '../../../Toggle';
import DomainExpertOpinion from './DomainExpertOpinion';

function DomainCardContent({ domainId } : { domainId: String }) {
	const { featuresOn, expertOpinionsOn } = useSelector((state :RootState) => state.filters.toggle);
	const [importanceRatingOn, setImportanceRatingOn] = useState(false);
	const {
		ratingEntities, productIds, loading, error,
	} = useSelector((state: RootState) => state.product.rating);
	const domainRatings = ratingEntities?.[String(productIds[0])]?.domainRatings;
	const domainRating = domainRatings?.find((rating) => String(rating.domainId) === domainId);
	return (
		<Box sx={{
			height: '100%',
		}}
		>
			<DomainGeneral
				domainRating={domainRating as any}
				loading={loading}
				error={error !== null}
			/>
			<Divider
				sx={
					{
						marginTop: '16px',
						marginBottom: '16px',
						border: '1px solid #E0E0E0',
					}
				}
			/>
			{
				featuresOn && (
					<FeatureList
						domainRating={domainRating as any}
						importanceRatingOn={importanceRatingOn}
						error={error !== null}
						loading={loading}
					/>
				)
			}
			<Box
				sx={
					{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'end',
						padding: '0px',
					}
				}
			>
				<Typography>
					Importance Rating
				</Typography>
				<Toggle
					value={importanceRatingOn}
					onChange={() => setImportanceRatingOn(!importanceRatingOn)}
				/>
			</Box>

			{expertOpinionsOn && (
				<DomainExpertOpinion expertOpinion={domainRating?.expertOpinion as any} />
			)}
		</Box>
	);
}

export default DomainCardContent;
