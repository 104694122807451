import {
	Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import ComparisonProductIntroSkeleton from './ComparisonProductIntroSkeleton';
import ComparisonProductContent from './ComparisonProductContent';

export function ComparisonProductIntroCard(
	{ index, productId }: { index: number, productId: string },
) {
	const { loading, error, productIds } = useSelector(
		(state:RootState) => state.product.productInfo,
	);
	if (loading) {
		return <ComparisonProductIntroSkeleton />;
	}
	if (error) {
		return <Typography color="error">{error}</Typography>;
	}
	if (productIds?.length === 0) {
		return <Typography color="error">No data available</Typography>;
	}
	return <ComparisonProductContent index={index} productId={productId} />;
}

export default ComparisonProductIntroCard;
