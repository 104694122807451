import LogoutIcon from '@mui/icons-material/Logout';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'theme';
import { logout, RootState } from 'features';
import { TokenService } from 'utils';
import { persistor } from 'store';

export function LogoutButton() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading } = useSelector((state:RootState) => state.auth);
	const clickLogout = () => {
		dispatch(logout() as any);
		// Clear the persisted state
		persistor.purge();
		TokenService.clearTokens();
		localStorage.removeItem('surveyAnswers');
		navigate('/login');
	};

	return (
		<LoadingButton
			fullWidth
			variant="outlined"
			sx={{
				mt: 2,
				mb: 2,
				borderRadius: {
					xs: 40,
					sm: 40,
					md: 52,
				},
				height: {
					xs: 26,
					sm: 28,
					md: 30,
				},

				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				'&:hover': {
					backgroundColor: theme.palette.primary.light,
				},

			}}
			startIcon={<LogoutIcon />}
			loading={loading}
			onClick={clickLogout}
		>
			Logout
		</LoadingButton>
	);
}

export default LogoutButton;
