/* eslint-disable no-shadow */
import { CUSTOMER_CONFIG, axiosInstance } from 'utils';

const CUSTOMER_SURVEY_PATH = '/survey';

export interface SendEmailRequest {
    emails: string[];
    comment: string;
    commentTitle: string;
}

export enum ReportType {
    SUMMARY = 'SUMMARY',
    DETAILED = 'DETAILED',
    STANDARD = 'STANDARD',
    COMPARISSON = 'COMPARISON',
}

export const sendReportByEmail = async (
	{
		surveyId, reportType, emails, comment, commentTitle,
	}
    : {
        surveyId: string,
        reportType: ReportType,
        emails: string[],
        comment: string,
        commentTitle: string
    },
): Promise<void> => {
	await axiosInstance.post(`${CUSTOMER_SURVEY_PATH}/${surveyId}/emailReportLink?type=${reportType}`, {
		emails,
		comment,
		commentTitle,
	}, CUSTOMER_CONFIG());
};
