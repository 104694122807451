/* eslint-disable no-shadow */
import { CUSTOMER_CONFIG, axiosInstance, CUSTOMER_SHARE_CONFIG } from 'utils';

const CUSTOMER_SURVEY_PATH = '/survey';

export enum GenderEnum {
    FEMALE='Female',
    MALE='Male',
    OTHER='Other'
}

export interface personaCategoryRating {
    category_id: string;
    rating: string;
}

export interface CodesignerSurvey {
    codesignerId: string;
    age: number;
    gender: GenderEnum;
    aliasName: string;
    profileImgUrl: string;
    customizedFields: Map<string, string >;
    personaCategoryRatings: personaCategoryRating[];
}

export interface getCodesignerResponse {
    surveyId: string;
    surveyCodesigners: CodesignerSurvey[];
}

export const getSurveyCodesigners = async (
	{ surveyId, searchParam }: { surveyId: string, searchParam?: Map<string, string> },
): Promise<getCodesignerResponse> => {
	// Convert Map to plain object if searchParam is provided
	const params = searchParam ? Object.fromEntries(searchParam.entries()) : undefined;

	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/codesigners`, {
		...CUSTOMER_CONFIG(),
		params,
	});

	return response as any as getCodesignerResponse;
};

export const getSharedSurveyCodesigners = async (
	{ surveyId, searchParam, code }:
    { surveyId: string, searchParam?: Map<string, string>, code: string },
): Promise<getCodesignerResponse> => {
	const params = searchParam ? Object.fromEntries(searchParam.entries()) : undefined;

	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/codesigners`, {
		...CUSTOMER_SHARE_CONFIG(code),
		params,
	});

	return response as any as getCodesignerResponse;
};
