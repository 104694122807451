import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { ComparisonProductUsabilityCard } from 'components';
import { getProductRating, getSharedProductRating } from 'thunk';
import { RootState } from 'features';
import SelectedDomainCards from './SelectedDomainCards';

function MultipleProductMetricPage() {
	const dispatch = useDispatch();
	const { surveyId } = useParams();
	const { isShare, shareCode } = useSelector((state:RootState) => state.auth);
	const { selectedProductList } = useSelector((state:RootState) => state.filters.survey);
	useEffect(
		() => {
			if (surveyId && selectedProductList?.length && selectedProductList.length > 0) {
				if (isShare && shareCode) {
					dispatch(getSharedProductRating({
						data: {
							surveyId,
							code: shareCode,
						},
					}) as any);
				} else {
					dispatch(getProductRating({
						data: {
							surveyId,
						},
					}) as any);
				}
			}
		},
		[surveyId, selectedProductList, dispatch, isShare, shareCode],
	);

	const getGridSize = () => {
		if (selectedProductList?.length === 1) {
			return 12;
		}
		if (selectedProductList?.length === 2) {
			return 6;
		}
		return 4;
	};
	// console.log('selectedProductList', selectedProductList);
	return (
		<Box>
			<Grid container spacing={2} padding="16px">
				{selectedProductList?.map((id) => (
					<Grid item xs={getGridSize()} key={`${id}-introCard-Grid`}>
						<ComparisonProductUsabilityCard key={`${id}-introCard`} productId={id} />
					</Grid>
				))}
			</Grid>
			<Grid container spacing={2} padding="16px">
				{selectedProductList?.map((id) => (
					<Grid item xs={getGridSize()} key={`${id}-introCard-Grid`}>
						<SelectedDomainCards productId={id} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

export default MultipleProductMetricPage;
