import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
	Typography, Box, Divider,
} from '@mui/material';
import { useState } from 'react';
import { RootState } from 'features';
import DomainGeneral from './DomainGeneralV2';
import FeatureList from './FeatureList';
import Toggle from '../../../Toggle';
import DomainExpertOpinion from './DomainExpertOpinion';

function DomainCardContent({ domainId, productId }: { domainId: string, productId: string }) {
	const filter = useSelector((state: RootState) => state.filters.toggle);
	const { ratingEntities, loading, error } = useSelector(
		(state: RootState) => state.product.rating,
	);
	const domainRatings = ratingEntities?.[productId]?.domainRatings;
	const domainRating = domainRatings?.find((rating) => String(rating.domainId) === domainId);
	const [importanceRatingOn, setImportanceRatingOn] = useState(false);

	return (
		<Box sx={{
			height: '100%',
		}}
		>
			<DomainGeneral
				domainId={domainId}
				importanceRatingOn={importanceRatingOn}
				productId={productId}
			/>
			<Divider
				sx={
					{
						marginTop: '16px',
						marginBottom: '16px',
						border: '1px solid #E0E0E0',
					}
				}
			/>
			{
				filter.featuresOn && (
					<FeatureList
						domainRating={domainRating as any}
						importanceRatingOn={importanceRatingOn}
						error={error !== null}
						loading={loading}
					/>
				)
			}
			<Box
				sx={
					{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'end',
						padding: '0px',
					}
				}
			>
				<Typography>
					Importance Rating
				</Typography>
				<Toggle
					value={importanceRatingOn}
					onChange={() => setImportanceRatingOn(!importanceRatingOn)}
				/>
			</Box>

			{filter.expertOpinionsOn && !loading && !error && domainRating && (
				<DomainExpertOpinion domainRating={domainRating as any} />
			)}
		</Box>
	);
}

export default DomainCardContent;
