import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { selectAuth } from 'features';
import { LoginRequest } from 'api';
import { login } from 'thunk';
import EmailField from './EmailField'; // Assumes EmailField handles its own state and errors
import PasswordField from './PasswordField'; // Assumes PasswordField handles its own state and errors
import RememberMeCheckbox from './RememberMeCheckbox';
import SubmitButton from './SubmitButton';
import ErrorMessage from '../../DisplayErrorMessage/ErrorMessage';
import WelcomeComponent from '../WelcomeComponent'; // A welcome banner or component

export function LoginForm(): JSX.Element {
	const { loading, error } = useSelector(selectAuth);
	const dispatch = useDispatch();
	const rememberMeRef = useRef<HTMLInputElement>(null);
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [errorMessageOpen, setErrorMessageOpen] = useState(false);
	const validateEmail = () => {
		if (!email) return 'Email is required';
		if (!/\S+@\S+\.\S+/.test(email)) return 'Email is invalid';
		return '';
	};

	const validatePassword = () => {
		if (!password) return 'Password is required';
		return '';
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		// console.log('Submitting form');
		setEmailError(validateEmail());
		setPasswordError(validatePassword());
		// console.log('Email:', email);
		// console.log('Password:', password);
		// console.log('Remember me:', rememberMeRef?.current?.checked);
		const loginRequest = {
			email,
			password,
			rememberMe: !!rememberMeRef?.current?.checked || false,
		} as LoginRequest;

		if (!emailError && !passwordError) {
			dispatch(login({ data: loginRequest }) as any as AnyAction);
		}
	};

	const onEmailChange = (event : React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		if (emailError) setEmailError(''); // Clear email error if it exists
	};

	const onPasswordChange = (event : React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		if (passwordError) setPasswordError('');
	};

	return (
		<Box
			component="form"
			noValidate
			onSubmit={handleSubmit}
			sx={{
				my: {
					xs: 2,
					sm: 10,
					md: 12,
					lg: 14,
				},
				mx: 'auto',
				ml: {
					xs: 2,
					sm: 4,
					md: 6,
					lg: 8,
				},
				mr: {
					xs: 2,
					sm: 4,
					md: 6,
					lg: 8,
				},
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'left',
			}}
		>
			<WelcomeComponent />
			<EmailField
				email={email}
				onChange={onEmailChange}
				emailError={emailError}
			/>
			<PasswordField
				password={password}
				onChange={onPasswordChange}
				passwordError={passwordError}
				showPassword={showPassword}
				setShowPassword={(value) => setShowPassword(value)}
			/>
			<RememberMeCheckbox rememberMeRef={rememberMeRef} />
			<SubmitButton loading={loading} />
			{error && <ErrorMessage message={error as string} open={errorMessageOpen} index={0} />}
		</Box>
	);
}

export default LoginForm;
