import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

export function StartProductSurveyButton() {
	const { codesignerId } = useParams();
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(`/codesigner/${codesignerId}/survey-info`); // Adjust route as needed
	};

	return (
		<LoadingButton
			fullWidth
			type="submit"
			// fullWidth
			variant="outlined"
			sx={{
				mt: 3,
				mb: 2,
				borderRadius: {
					xs: 40,
					sm: 40,
					md: 52,
				},
				height: {
					xs: 36,
					sm: 36,
					md: 48,
				},
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.primary.contrastText,
				'&:hover': {
					backgroundColor: theme.palette.secondary.light,
				},
			}}
			color="primary"
			startIcon={<AssignmentIndIcon />}
			onClick={handleClick}
		>
			Start Product Survey
		</LoadingButton>
	);
}

export default StartProductSurveyButton;
