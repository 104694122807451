import {
	Box, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import DomainListRow from './DomainListRow';
import DomainListSkeleton from './DomainListSkeleton';

function DomainList() {
	const {
		selectedDomains,
		loading: domainPending,
		error: domainError,
	}	= useSelector(
		(state: RootState) => state.filters.domain,
	);
	const {
		loading: ratingLoading,
		error: ratingError,
	} = useSelector((state: RootState) => state.product.rating);
	const loading = domainPending || ratingLoading;
	const error = domainError || ratingError;
	const getDomainScoreRows = () => selectedDomains?.map((domainId) => (
		(
			<DomainListRow
				domainId={String(domainId)}
				key={`${domainId}-domain-row`}
			/>
		)
	));

	const getDomainListRendered = () => {
		if (loading) {
			return (
				<DomainListSkeleton />
			);
		}
		if (error) {
			return null;
		}
		if (selectedDomains && selectedDomains.length > 0 && !loading && !error) {
			return (
				<>
					{getDomainScoreRows()}
				</>
			);
		}
		return null;
	};

	return (
		<>
			<Typography
				sx={{
					color: '#455468',
					fontFamily: 'Inter, sans-serif',
					fontWeight: 600,
					fontSize: '16px',
					// textAlign: 'left',
					marginBottom: ['0px', '5px', '10px'], // [mobile, tablet, desktop
				}}
			>
				Domains
			</Typography>
			<Box
				// overflow="auto"
				sx={{
					// height: '150px',
					width: '100%',
					paddingRight: ['8px', '8px', '16px'], // [mobile, tablet, desktop
					paddingLeft: ['8px', '8px', '16px'],
				}}
			>
				{getDomainListRendered()}
			</Box>

		</>
	);
}

export default DomainList;
