/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductSurveyQuestions, Domain, SurveyProduct } from 'api'; // Import your API interfaces
import { getProductSurveyQuestions } from 'thunk'; // Import the thunk for fetching product survey data

export interface ProductSurveyState {
    loading: boolean;
    error: string | null;
    domains: Domain[];
    products: SurveyProduct[];
}

const initialState: ProductSurveyState = {
	loading: false,
	error: null,
	domains: [],
	products: [],
};

const productSurveySlice = createSlice({
	name: 'productSurvey',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getProductSurveyQuestions.pending, (state:ProductSurveyState) => {
			Object.assign(state, initialState);
			state.loading = true;
			state.error = null;
			state.domains = [];
			state.products = [];
		});
		builder.addCase(
			getProductSurveyQuestions.fulfilled,
			(state, action: PayloadAction<ProductSurveyQuestions>) => {
				state.loading = false;
				state.domains = action.payload.domains; // Save the domains from the API response
				state.products = action.payload.products; // Save the products from the API response
			},
		);
		builder.addCase(getProductSurveyQuestions.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message || 'Failed to fetch product survey data';
		});
	},
});

export const productSurveyReducer = productSurveySlice.reducer;
