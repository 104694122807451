/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AUTH_THUNK_PREFIX } from 'thunk';
import { SingleProductSurvey, MultipleProductSurvey, ProductInfo } from 'api/index';

export interface Option{
    label:string;
    value:string;
	productList?:ProductInfo[];
}

export const TAB_CONSTANTS = {
	SINGLE_PRODUCT: 'SINGLE_PRODUCT',
	MULTIPLE_PRODUCT: 'MULTIPLE_PRODUCT',
};

type TabConstants = typeof TAB_CONSTANTS[keyof typeof TAB_CONSTANTS]
export interface SurveySelectionFilterState {
    currentSurveyId: string | null;
    selectedTab: TabConstants;
    singleOptions: Option[];
    multipleOptions: Option[];
    selectedSingleOption: string | null;
    selectedMultipleOption: string | null;
	selectedProductList: string[] | null;
}

const initialState: SurveySelectionFilterState = {
	currentSurveyId: null,
	selectedTab: TAB_CONSTANTS.SINGLE_PRODUCT,
	singleOptions: [],
	multipleOptions: [],
	selectedSingleOption: null,
	selectedMultipleOption: null,
	selectedProductList: [],
};

export const surveySelectionFilterSlice = createSlice({
	name: 'surveySelectionFilter',
	initialState,
	reducers: {
		setCurrentSurveyId: (state:SurveySelectionFilterState, action: { payload: string }) => {
			state.currentSurveyId = action.payload;
		},
		setSelectedTab: (
			state:SurveySelectionFilterState,
			action: { payload: TabConstants },
		) => {
			state.selectedTab = action.payload;
			if (action.payload === TAB_CONSTANTS.SINGLE_PRODUCT) {
				state.selectedMultipleOption = null;
				state.selectedProductList = [] as string[];
			} else {
				state.selectedSingleOption = null;
			}
		},
		setSelectedSingleOption: (state, action: { payload: string }) => {
			state.selectedSingleOption = action.payload;
		},
		setSelectedMultipleOption: (state, action: { payload: string }) => {
			state.selectedMultipleOption = action.payload;
			state.selectedProductList = state.multipleOptions.find(
				(option) => String(option.value) === action.payload,
			)?.productList?.map((product) => product.productId) || [];
		},
		setSelectedProductIds: (state, action: { payload: string[] }) => {
			state.selectedProductList = action.payload;
		},
		removeSurveyId: (state, action: { payload: string }) => {
			state.singleOptions = state.singleOptions.filter(
				(option) => option.value !== action.payload,
			);
			state.multipleOptions = state.multipleOptions.filter(
				(option) => option.value !== action.payload,
			);
		},
	},
	extraReducers(builder) {
		// match path auth, type fulfilled
		builder.addMatcher(
			(action) => action.type.endsWith('/fulfilled') && action.type.startsWith(AUTH_THUNK_PREFIX),
			(state, action) => {
				const { singleProductSurveyList, multipleProductSurveyList } = action.payload;
				const filteredProductSurveyList = singleProductSurveyList?.filter(
					(survey:SingleProductSurvey) => survey.productDone !== true,
				);
				const filteredMultipleProductSurveyList = multipleProductSurveyList?.filter(
					(survey:MultipleProductSurvey) => survey.productDone !== true,
				);
				state.singleOptions = filteredProductSurveyList?.map((survey:SingleProductSurvey) => ({
					label: survey.productName,
					value: survey.surveyId,
				}));
				state.multipleOptions = filteredMultipleProductSurveyList?.map(
					(survey:MultipleProductSurvey) => ({
						label: survey.surveyName,
						value: survey.surveyId,
						productList: survey.productList,
					}),
				);
				// if there is no single product survey but there is a multiple product survey
				// set selected tab to multiple product
				if (state.singleOptions?.length > 0) {
					if (!state.selectedSingleOption) {
						state.selectedSingleOption = state.singleOptions[0].value;
					}
					// if (!state.currentSurveyId) {
					state.currentSurveyId = state.singleOptions[0].value;
					// }
				} else if (state.multipleOptions?.length > 0) {
					state.selectedTab = 'MULTIPLE_PRODUCT';
					if (!state.selectedMultipleOption) {
						state.selectedMultipleOption = state.multipleOptions[0].value;
					}
					// if (!state.currentSurveyId) {
					state.currentSurveyId = state.multipleOptions[0].value;
					// }
				}
			},
		);
	},
});

export const {
	setCurrentSurveyId,
	setSelectedTab,
	setSelectedSingleOption,
	setSelectedMultipleOption,
	setSelectedProductIds,
	removeSurveyId,
} = surveySelectionFilterSlice.actions;

export const surveySlice = surveySelectionFilterSlice.reducer;

export const selectSurveySelectionFilter = (state:
    { surveySelectionFilter: SurveySelectionFilterState }) => state.surveySelectionFilter;
