import { useSelector } from 'react-redux';
import { RootState } from 'features';
import SinglePersonSelector from './SinglePersonaSelector';

function PersonaFilterContent() {
	const { personaIds, filterSelection } = useSelector(
		(state:RootState) => state.filters.persona,
	);

	const getMeaningfulIds = () => {
		// if entities[id].min==entities[id].max then it is not a slider persona,
		// so we don't want to show it
		const meaningfulIds = [] as string[];
		personaIds?.forEach((id) => {
			if (filterSelection[id].allowedMin !== filterSelection[id].allowedMax) {
				meaningfulIds.push(id);
			}
		});
		return meaningfulIds;
	};

	return (
		<div>
			{
				personaIds?.map((id) => (
					<SinglePersonSelector key={`personaSelector-${id}`} personaId={String(id)} />
				))
			}
			{/* {ids?.map((id) => (
				<SinglePersonSelector key={`personaSelector-${id}`} personaId={id} />
			))} */}
		</div>
	);
}

export default PersonaFilterContent;
