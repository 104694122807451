import { useSelector, useDispatch } from 'react-redux';
import {
	Box, Divider, Tabs, Tab,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
	TAB_CONSTANTS,
	setSelectedTab,
} from 'features/filters/surveySlice';
import theme from 'theme';
import { RootState } from 'features';
import { useEffect } from 'react';
import ProductSelector from './ProductSelector';
import SurveySelector from './SurveySelector';
import { useTabHooks } from './useTabHooks';

const MavyTab = styled(Tab)({
	'&.Mui-selected': {
		backgroundColor: theme.palette.primary.main, //
		color: theme.palette.primary.contrastText,
		borderRadius: '5px 5px 0px 0px',
	},
	'&.MuiTab-root': {
		fontFamily: 'Inter, sans-serif',
		fontWeight: '600',
		fontSize: '14px',
		color: theme.palette.primary.dark,
		marginLeft: '10px',
		textAlign: 'left',
		justifyContent: 'center',
		textTransform: 'none',
		padding: '0px',
		margin: '0px',
		minWidth: '70px',
		minHeight: '36px',
	},
	'&.Mui-selected.MuiTab-root': {
		color: theme.palette.primary.contrastText, // Ensure this specificity wins out
	},
});

const MavyTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
		display: 'none',
	},
	'&.MuiTabs-root': {
		minHeight: '36px',
	},

});

export function ProductOrSurveySelector() {
	const dispatch = useDispatch();
	const { selectedTab } = useSelector((state: RootState) => state.filters.survey);
	const { singleProductTabDisabled, multipleProductTabDisabled } = useTabHooks();
	const { surveyId, singleSurveyId } = useParams();
	const onChange = (event: React.SyntheticEvent, newValue: string) => {
		if (newValue === TAB_CONSTANTS.SINGLE_PRODUCT) {
			dispatch(setSelectedTab(TAB_CONSTANTS.SINGLE_PRODUCT));
		} else {
			dispatch(setSelectedTab(TAB_CONSTANTS.MULTIPLE_PRODUCT));
		}
	};
	useEffect(() => {
		if (surveyId && selectedTab === TAB_CONSTANTS.SINGLE_PRODUCT) {
			dispatch(setSelectedTab(TAB_CONSTANTS.MULTIPLE_PRODUCT));
		} else if (singleSurveyId && selectedTab === TAB_CONSTANTS.MULTIPLE_PRODUCT) {
			dispatch(setSelectedTab(TAB_CONSTANTS.SINGLE_PRODUCT));
		}
	}, [surveyId, singleSurveyId, selectedTab]);

	return (
		<Box width="100%" justifyContent="center">
			<MavyTabs
				value={selectedTab}
				onChange={onChange}
				aria-label="disabled tabs example"
			>
				<MavyTab
					label="Single"
					value={TAB_CONSTANTS.SINGLE_PRODUCT}
					disabled={singleProductTabDisabled}
				/>
				<MavyTab
					label="Comparative"
					value={TAB_CONSTANTS.MULTIPLE_PRODUCT}
					disabled={multipleProductTabDisabled}
					sx={{
						'&.MuiTab-root': {
							minWidth: '100px',
						},
					}}
				/>
			</MavyTabs>
			<Divider sx={{ marginBottom: '15px' }} />
			<Box width="90%" justifyContent="center" display="flex" justifyItems="center" justifySelf="center">
				{selectedTab === TAB_CONSTANTS.SINGLE_PRODUCT ? <ProductSelector /> : <SurveySelector />}
			</Box>
		</Box>

	);
}
export default ProductOrSurveySelector;
