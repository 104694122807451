import { Typography, Skeleton, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';

function ProductIntroCardHeader({ expanded, productId } : {expanded: boolean, productId: string}) {
	const { loading, productEntities } = useSelector((state: RootState) => state.product.productInfo);
	const productDetail = productEntities[productId];

	if (loading) {
		return <Skeleton variant="text" width={150} height={40} />;
	}

	const getModelNameAndNumber = () => {
		if (productDetail?.modelName && productDetail?.modelNumber) {
			return `${productDetail.modelName} (${productDetail.modelNumber})`;
		}
		if (productDetail?.modelName) {
			return productDetail.modelName;
		}
		return '';
	};

	return (
		<Box>
			<Typography
				sx={{
					color: '#455468',
					fontFamily: 'Inter, sans-serif',
					fontWeight: 600,
					fontSize: '18px',
					textAlign: 'start',
				}}
			>
				{getModelNameAndNumber()}
			</Typography>
			{/* <Typography
				sx={{
					fontSize: '12px',
					fontWeight: 400,
					fontFamily: 'Inter, sans-serif',
					textAlign: 'left',
					color: colors.grey,
					display: expanded ? 'block' : 'none',
				}}
			>
				{productDetail?.productCategoryName}

			</Typography> */}
		</Box>
	);
}

export default ProductIntroCardHeader;
