/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
	getSurveyShareLink,
} from 'thunk';

export interface ShareLinkState {
    loading: boolean;
    error: string | null;
    code: string;
}

const initialState: ShareLinkState = {
	loading: false,
	error: null,
	code: '',
};

const shareLinkSlice = createSlice({
	name: 'shareLink',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSurveyShareLink.pending, (state) => {
			Object.assign(state, initialState);
			state.loading = true;
		});
		builder.addCase(getSurveyShareLink.fulfilled, (state, action) => {
			Object.assign(state, initialState);
			state.code = action.payload.code;
		});
		builder.addCase(getSurveyShareLink.rejected, (state, action) => {
			Object.assign(state, initialState);
			state.error = action.error.message || 'Failed to get share link';
		});
	},
});

export const shareLinkReducer = shareLinkSlice.reducer;
