import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	RootState, setProductQuestionRating,
	setProductImportanceRating, clearAllErrorMessage,
	removeSurveyId,
	addErrorMessage,
} from 'features';
import { getProductSurveyQuestions } from 'thunk';
import {
	CircularProgress, Typography,
	Box, Divider, Snackbar, Pagination, Button, IconButton,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Alert from '@mui/material/Alert';
import ErrorMessage from 'components/DisplayErrorMessage/ErrorMessage';
import {
	SubmitButton, RatingQuestion, ImportanceRatingQuestion, CountDownAndRedirect,
} from './components';

function ProductSurveyPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		loading: submitLoading,
	} = useSelector((state: RootState) => state.productSurveySubmission);
	const prevSubmitLoading = useRef(submitLoading);
	// const rating=ratingEntities??
	const { currentSurveyId } = useSelector((state:RootState) => state.filters.survey);
	const { messages } = useSelector((state: RootState) => state.error);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [successMessageOpen, setSuccessMessageOpen] = useState(false);
	const {
		loading, error, domains, products,
	} = useSelector(
		(state: RootState) => state.productSurvey,
	);
	useEffect(() => {
		if (prevSubmitLoading.current && !error && !submitLoading) {
			// Show success message
			setSuccessMessageOpen(true);
			dispatch(removeSurveyId(currentSurveyId as string));
		}
	}, [dispatch, error, submitLoading, navigate, currentSurveyId]);

	useEffect(() => {
		if (currentSurveyId !== null) {
			dispatch(getProductSurveyQuestions({
				data: {
					codesignerSurveyID: currentSurveyId,
				},
			}) as any);
		}
	}, [dispatch, currentSurveyId]);
	useEffect(() => {
		prevSubmitLoading.current = submitLoading;
	}, [submitLoading]);

	const surveyResponse = useSelector(
		(state: RootState) => state.productSurveyInitial,
	)?.[currentSurveyId as string];
	// console.log('surveyResponse', surveyResponse);
	if (!surveyResponse) {
		return <CountDownAndRedirect message="Unkown error." />;
	}

	const {
		ratingEntities,
		importanceEntities,
		ratingValidations,
		importanceValidations,
	} = surveyResponse;

	const handleAnswerChange = (productId: string, questionId: string, value: string) => {
		dispatch(clearAllErrorMessage());
		const rating = parseInt(value, 10);
		dispatch(
			setProductQuestionRating({
				surveyId: currentSurveyId as string,
				productId: productId.toString(), // Ensure productId is passed as a string
				questionId: questionId.toString(), // Ensure questionId is passed as a string
				rating,
			}),
		);
	};

	const handleImportanceAnswerChange = (productId: string, featureId: string, value: string) => {
		dispatch(clearAllErrorMessage());
		const rating = parseInt(value, 10);
		dispatch(
			setProductImportanceRating({
				surveyId: currentSurveyId as string,
				productId: productId.toString(), // Ensure productId is passed as a string
				featureId: featureId.toString(), // Ensure questionId is passed as a string
				rating,
			}),
		);
	};

	// Handle question pagination (Previous Question)
	const handlePreviousQuestion = () => {
		if (currentQuestionIndex === 0) {
			return;
		}
		setCurrentQuestionIndex((prevIndex) => Math.max(0, prevIndex - 1));
	};
	// UseEffect to handle success message and navigation

	// Gather all questions across all domains and features into a flat array
	const allQuestions = domains.flatMap((domain) => domain.features.flatMap((feature) => ({
		domainName: domain.name,
		featureName: feature.name,
		featureID: feature.featureId,
		featureDescription: feature.description,
		questions: feature.questions,
	})));

	// If there are no questions, return a message
	if (!allQuestions.length) {
		return <Typography>No questions found.</Typography>;
	}

	// Get the current question (based on the currentQuestionIndex)
	const currentFeature = allQuestions[currentQuestionIndex];
	const currentQuestions = currentFeature.questions;

	// Determine if the user is on the last question page
	const isLastPage = currentQuestionIndex === allQuestions.length - 1;

	const getCurrentDomainNo = () => {
		const domainIndex = domains.findIndex((domain) => domain.name === currentFeature.domainName);
		return domainIndex + 1;
	};

	const getCurrentFeatureNo = () => {
		const currentDomain = domains.find((domain) => domain.name === currentFeature.domainName);
		if (!currentDomain) {
			return 0;
		}
		const featureIndex = currentDomain
			?.features?.findIndex((feature) => feature.name === currentFeature.featureName);
		return featureIndex + 1;
	};

	const getCurrentFeaturesLength = () => {
		const currentDomain = domains.find((domain) => domain.name === currentFeature.domainName);
		if (!currentDomain) {
			return 0;
		}
		return currentDomain?.features?.length;
	};
	const onPaginationChange = (
		value: number,
		featureId: string,
		questionIds: string[],
	) => {
		// we dont validate when user go to previous questions
		if (value < currentQuestionIndex + 1) {
			setCurrentQuestionIndex(value - 1);
			return;
		}
		// if all questions are answered, submit the survey
		let allQuestionsAnswered = true;
		// Check if all questions for all products have been answered
		products.forEach((product) => {
			questionIds.forEach((questionId) => {
				if (ratingValidations && !ratingValidations[product.productId]?.[questionId]) {
					allQuestionsAnswered = false;
				}
			});
		});

		// Check feature importance ratings
		products.forEach((product) => {
			if (importanceValidations
				&& !importanceValidations[product.productId]?.[featureId]) {
				allQuestionsAnswered = false;
			}
		});

		if (allQuestionsAnswered) {
			setCurrentQuestionIndex(value - 1);
		} else {
			dispatch(addErrorMessage('Please answer all questions.'));
		}
	};
	// Handle loading state
	if (loading) {
		return <CircularProgress />;
	}

	// Handle error state
	if (error) {
		return (
			<CountDownAndRedirect message={error} />
		);
	}
	if (successMessageOpen) {
		return (
			<CountDownAndRedirect message="Survey submitted successfully! Thank you for your time." />
		);
	}

	// Render products and questions
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
				minHeight: '100vh',
				backgroundColor: '#f4f4f4',
			}}
		>
			{messages.map((message, index) => (
				<ErrorMessage message={message} open={!!message} index={index} />
			))}
			{/* Success message */}
			<Snackbar open={successMessageOpen} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert severity="success">Survey submitted successfully!</Alert>
			</Snackbar>
			<Box
				sx={{
					width: '80%',
					maxWidth: '1000px',
					backgroundColor: 'white',
					padding: 4,
					borderRadius: 2,
					boxShadow: 3,
					marginTop: '10%',
					marginBottom: '10%',
				}}
			>
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Typography variant="h4" gutterBottom textAlign="center">
						Product Survey
					</Typography>
					<Typography variant="h5" gutterBottom textAlign="center">
						(
						{currentQuestionIndex + 1}
						/
						{allQuestions.length}
						)
					</Typography>
				</Box>

				{/* Render the current domain and feature information */}
				<Box display="flex" flexDirection="row" alignItems="center" gap="10px">
					<Typography variant="h5">{currentFeature.featureName}</Typography>
					<Typography variant="subtitle1">
						{`(${getCurrentFeatureNo()} / ${getCurrentFeaturesLength()})`}
					</Typography>
				</Box>
				<Box display="flex" flexDirection="row" alignItems="center" gap="10px">
					<Typography variant="h6">{currentFeature.domainName}</Typography>
					<Typography variant="subtitle1">
						{`(${getCurrentDomainNo()} / ${domains.length})`}
					</Typography>
				</Box>
				{/* insert dangerouslySetInnerHTML */}
				<Typography
					variant="body1"
					sx={{ fontSize: '16px' }}
					dangerouslySetInnerHTML={{ __html: currentFeature.featureDescription }}
				/>

				<Divider sx={{ my: 2 }} />
				{/* Render current question */}
				{currentQuestions.map((question, index) => (
					<RatingQuestion
						key={question.questionId.toString()}
						index={index}
						questionId={question.questionId.toString()}
						questionContent={question.content}
						products={products.map((product) => ({
							productId: product.productId.toString(), // Convert productId to string
							modelName: product.modelName,
						}))}
						ratingEntities={ratingEntities}
						handleAnswerChange={handleAnswerChange}
						ratingValidations={ratingValidations}
					/>
				))}
				{/* Importance Question */}
				<Box sx={{ mb: 4 }}>
					<ImportanceRatingQuestion
						featureName={currentFeature.featureName}
						featureId={currentFeature.featureID.toString()}
						products={products.map((product) => ({
							productId: product.productId.toString(), // Convert productId to string
							modelName: product.modelName,
						}))}
						importanceEntities={importanceEntities}
						importanceValidations={importanceValidations}
						handleImportanceAnswerChange={handleImportanceAnswerChange}
					/>
				</Box>
				<Box sx={{ mb: 4 }} display="flex" flexDirection="row" justifyContent="space-between">
					{/* Show "Previous" button unless on the first page */}

					<Button
						variant="outlined"
						onClick={handlePreviousQuestion}
						disabled={currentQuestionIndex === 0}
						color="primary"
						startIcon={<ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />}
					>
						Previous
					</Button>

					{/* Show "Next" button unless on the last page */}
					{isLastPage && (

						<SubmitButton />

					)}

					<Button
						variant="outlined"
						color="primary"
						onClick={() => onPaginationChange(
							currentQuestionIndex + 2,
							currentFeature.featureID.toString(),
							currentQuestions.map((question) => question.questionId.toString()),
						)}
						disabled={currentQuestionIndex === allQuestions.length - 1}
						endIcon={<ArrowForwardIosIcon />}
					>
						Next
					</Button>

				</Box>
				{/* Pagination buttons */}
				<Box sx={{
					display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end',
				}}
				>

					<Pagination
						count={allQuestions.length} // Number of pages
						hidePrevButton
						hideNextButton
						page={currentQuestionIndex + 1} // Current page number (1-based)
						onChange={(event, value) => onPaginationChange(
							value,
							currentFeature.featureID.toString(),
							currentQuestions.map((question) => question.questionId.toString()),
						)} // Use closure to pass additional parameters
					/>

				</Box>
			</Box>
		</Box>
	);
}
export default ProductSurveyPage;
