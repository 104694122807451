import { axiosInstance, CUSTOMER_CONFIG } from 'utils';

const PERSONA_SURVEY_PATH = '/persona-survey';

export interface Option {
    optionId: number;
    content: string;
    nextQuestionIds: number[];
}

export interface Question {
    questionId: number;
    questionType: string;
    content: string;
    orderNumber: number;
    options: Option[];
    parentQuestionId: number;
    parentOptionId: number;
}

export interface PersonaSurveyQuestions {
    questions: Question[];
}

export const getPersonaSurveyQuestions = async (): Promise<PersonaSurveyQuestions> => {
	const response = await axiosInstance.get(PERSONA_SURVEY_PATH, CUSTOMER_CONFIG());
	return response as any as PersonaSurveyQuestions;
};

export interface QuestionAnswer {
    questionId: string;
    selectedOptionId?: string;
    answer?: string;
}

export interface PersonaSurveyAnswers {
    questionAnswers: QuestionAnswer[];
}

// API to Post persona survey Answers
export const postPersonaSurveyQuestions = async (
	{ questionAnswers }: {questionAnswers :PersonaSurveyAnswers},
) => {
	await axiosInstance.post(PERSONA_SURVEY_PATH, questionAnswers, CUSTOMER_CONFIG());
};
