import { Box, Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux/';
import { alpha } from '@mui/material/styles';
import theme from 'theme';
import { RootState } from 'features';
import { NO_PERSONA_PROFILE } from 'constant';
import { firstCharToUpperCase } from 'utils';
import PersonaCategoryRating from './PersonaCategoryRating';

function CoDesignerSurveyContent() {
	const {
		currentCodesignerId,
		codesignerEntities,
	} = useSelector((state: RootState) => state.codesigner);

	const survey = codesignerEntities[String(currentCodesignerId)];

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			gap: '38px',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
		}}
		>
			<Box
				id="survey-content-upper"
				width="100%"
				display="flex"
				gap="20px"
				sx={{
					maxHeight: ['100%', '45vh', '40vh'],
					flexDirection: ['column', 'row', 'row'],
					height: ['100%', '50%', '60%'],
				}}
				overflow="auto"
				position="relative"
			>
				<Box
					id="survey-content-upper-profile"
					height="100%"
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyItems="space-between"
					boxSizing="inherit"
					gap="20px"
					sx={{
						width: ['100%', '30%', '30%'],
						maxHeight: ['100%', '45vh', '30vh'],
						position: 'sticky',
						top: 0,
					}}
					overflow="visible"

				>
					<Box
						id="survey-content-upper-profile-avatar-container"
						width="40%"
						// height="50%"
						// borderRadius="50%"
						// overflow="hidden"
					>
						<img
							src={survey?.profileImgUrl || NO_PERSONA_PROFILE}
							alt="avatar"
							style={{
								width: '100%',
								// minWidth: '150px',
								// minHeight: '150px',
								height: '100%',
								objectFit: 'cover',
								borderRadius: '50%',
							}}
						/>
					</Box>
					<Box
						sx={{
							height: '10%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography sx={{
							fontSize: '18px',
							fontWeight: 600,
							color: theme.palette.text.primary,
						}}
						>
							{survey?.aliasName || 'Unknown Co-Designer'}
						</Typography>
						<Typography sx={{
							fontSize: '16px',
							fontWeight: 400,
							color: theme.palette.text.primary,

						}}
						>
							{`${survey?.gender} , ${survey?.age}`}
						</Typography>
					</Box>
				</Box>

				<Grid
					container
					columnGap={12.5}
					rowGap={3.5}
					width="70%"
					height="100%"
					sx={{
						width: ['100%', '70%', '70%'],
					}}
				>
					{
						survey?.personaCategoryRatings?.map((personaCategoriesRating) => (
							<Grid
								item
								xs={12}
								md={4}
								xl={2.5}
								key={`personaCategoriesRating${personaCategoriesRating.category_id}`}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: ['start', 'center', 'center'], // eslint-disable-line no-mixed-operators
									alignItems: 'start',
								}}
							>
								<PersonaCategoryRating
									id={String(personaCategoriesRating?.category_id)}
									rating={personaCategoriesRating?.rating}
								/>
							</Grid>
						))
					}
				</Grid>

			</Box>

			<Grid
				container
				columnGap={8}
				rowGap={3.5}
				height="40%"
				width="100%"
				overflow="auto"
				sx={{ maxHeight: ['100%', '35vh', '30vh'] }}

			>
				{// customize fields are objects, key is the question and value is the answer
					Object.entries(survey?.customizedFields || {}).map(([key, value]) => (
						<Grid
							item
							xs={12}
							md={3}
							lg={3.5}
							key={key}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'start',
								alignItems: 'start',

							}}
							spacing={2}
						>
							<Typography sx={{
								fontSize: '18px',
								fontWeight: 600,
								color: theme.palette.text.primary,
								fontFamily: 'sans-serif',
								backgroundColor: alpha(theme.palette.primary.light, 0.3),
								borderRadius: '8px',
								padding: '10px',
								width: '100%',
							}}
							>
								{key}
							</Typography>
							<Typography sx={{
								fontSize: '16px',
								fontWeight: 400,
								color: theme.palette.text.primary,
								maxHeight: '200px',
								overflow: 'auto',
								padding: ['5px', '10px', '15px'],
							}}
							>
								{firstCharToUpperCase(value)}
							</Typography>
						</Grid>
					))
				}
			</Grid>

		</Box>
	);
}

export default CoDesignerSurveyContent;
