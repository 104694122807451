/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PRODUCT_INFO_THUNK_PREFIX } from 'thunk';
import { ProductInformation, getProductResponse } from 'api';

export interface productInfoState {
    loading: boolean;
    error: string | null;
    productEntities: { [id: string]: ProductInformation };
    productIds: string[];
}

const initialState: productInfoState = {
	loading: false,
	error: null,
	productEntities: {},
	productIds: [],
};

const productInfoSlice = createSlice({
	name: 'productInfo',
	initialState,

	reducers: {
	},

	extraReducers: (builder) => {
		builder.addMatcher(
			(action) => action.type.startsWith(PRODUCT_INFO_THUNK_PREFIX)
			&& action.type.endsWith('pending'),
			(state: productInfoState) => {
				Object.assign(state, initialState);
				state.loading = true;
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(PRODUCT_INFO_THUNK_PREFIX)
			&& action.type.endsWith('rejected'),
			(state: productInfoState) => {
				Object.assign(state, initialState);
				state.loading = false;
				state.error = 'Error in fetching product info';
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(PRODUCT_INFO_THUNK_PREFIX)
			&& action.type.endsWith('fulfilled'),
			(state, action: { payload: getProductResponse }) => {
				state.loading = false;
				state.error = null;
				const productEntities = action.payload.products?.reduce((acc, product) => {
					acc[String(product.productId)] = product;
					return acc;
				}, {} as { [id: string]: ProductInformation });
				state.productEntities = productEntities;
				state.productIds = action.payload.products?.map((product) => product.productId);
			},
		);
	},
});

export const productInfoReducer = productInfoSlice.reducer;
