import {
	Box, Skeleton, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import { DomainRating } from 'api';
import DomainRow from './DomainRow/index';

function DomainList({ productId }: { productId: string }) {
	const { ratingEntities, loading, error } = useSelector(
		(state: RootState) => state.product.rating,
	);
	const domainRatings = ratingEntities[productId]?.domainRatings;
	const domainIdsLength = domainRatings?.length;

	const getdomainRowRendered = (domainRating:DomainRating) => {
		if (error) {
			return null;
		}
		if (loading) {
			return (
				<Skeleton
					key={`${domainRating.domainId}-domain-row`}
					variant="rectangular"
					width="100%"
					height="90%"
					sx={{ margin: '5px' }}
				/>
			);
		}
		if (!error && !loading && domainRating) {
			const rating = parseFloat(domainRating.rating);
			if (rating && rating !== 0) {
				return (
					<DomainRow
						key={`${domainRating.domainId}-domain-row`}
						domainRating={domainRating}
					/>
				);
			}
		}

		return null;
	};

	const getdomainScoreRows = () => domainRatings?.map((domainRating) => (
		getdomainRowRendered(domainRating)
	));

	return (
		<Box
			sx={{ height: '100%' }}
		>
			<Typography
				sx={{
					color: '#455468',
					fontFamily: 'Inter, sans-serif',
					fontWeight: 600,
					fontSize: '16px',
					textAlign: 'left',
					marginBottom: ['0px', '5px', '10px'], // [mobile, tablet, desktop
				}}
			>
				domains
			</Typography>
			<Box
				// overflow="auto"
				sx={{
					// height: '150px',
					height: 'auto',
					width: '100%',
					paddingRight: '8px',
					paddingLeft: '8px',
				}}
			>
				{getdomainScoreRows()}
			</Box>

		</Box>
	);
}

export default DomainList;
