import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCodesigners } from 'thunk';
import { RootState } from 'features';

export function ApplyFilterButton() {
	const dispatch = useDispatch();
	const { singleSurveyId, surveyId } = useParams();
	const id = singleSurveyId || surveyId;
	const { filterSelection, personaIds } = useSelector(
		(state:RootState) => state.filters.persona,
	);
	const { minAge, maxAge } = useSelector((state:RootState) => state.filters.age);
	const { male, female } = useSelector((state:RootState) => state.filters.gender.selectedGender);
	const applyEnabled = id !== undefined;
	const searchParam = new Map<string, string>();
	const getPersonaQueryParams = () => {
		personaIds.map((personaId:string) => {
			const stringId = personaId;
			const {
				selectedMin, selectedMax, name, allowedMin, allowedMax,
			} = filterSelection[stringId];
			if (selectedMin !== allowedMin) {
				searchParam.set(`${name}_min`, selectedMin.toString());
			}
			if (selectedMax !== allowedMax) {
				searchParam.set(`${name}_max`, selectedMax.toString());
			}
		});
	};

	const getAgeParam = () => {
		if (minAge !== 0) {
			searchParam.set('age_min', minAge.toString());
		}
		if (maxAge !== 120) {
			searchParam.set('age_max', maxAge.toString());
		}
	};

	const getGenderParam = () => {
		searchParam.set('Female', female.toString());
		searchParam.set('Male', male.toString());
	};

	const handleApply = () => {
		searchParam.clear();
		getPersonaQueryParams();
		getAgeParam();
		getGenderParam();
		if (id !== undefined) {
			dispatch(getCodesigners(
				{
					data:
			{ surveyId: id, searchParam },
				},
			) as any);
		}
	};

	return (
		<Button
			onClick={handleApply}
			style={{
				// cursor: 'pointer',
				padding: '-2px',
				fontWeight: '500',
				fontSize: '12px',
			}}
			size="small"
			variant="outlined"
			disabled={!applyEnabled}
		>
			Apply
		</Button>
	);
}

export default ApplyFilterButton;
