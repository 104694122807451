/* eslint-disable no-shadow */
import { CUSTOMER_CONFIG, axiosInstance } from 'utils';

const CUSTOMER_SURVEY_PATH = '/survey';

export interface shareLinkRes{
    code:string;
}

export const getShareLink = async (
	{ surveyId }: { surveyId: string },
): Promise<shareLinkRes> => {
	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/share-link`, {
		...CUSTOMER_CONFIG(),
	});

	return response as any as shareLinkRes;
};
