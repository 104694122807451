import { useSelector } from 'react-redux/';
import { RootState } from 'features';
import CodesignerSurveySkeleton from './CoDesigerSkeleton';
import CoDesignerSurveyContent from './CoDesignerSurveyContent';

export function CoDesignerSurveyCard() {
	const {
		loading, error, codesignerIds, currentCodesignerId, codesignerEntities,
	} = useSelector((state: RootState) => state.codesigner);

	if (loading) {
		return <CodesignerSurveySkeleton />;
	}
	if (error) {
		return null;
	}
	if (codesignerIds.length === 0 || !currentCodesignerId
			|| !codesignerEntities || !codesignerEntities[String(currentCodesignerId)]) {
		return <div>No Codesigner Data</div>;
	}

	return <CoDesignerSurveyContent />;
}

export default CoDesignerSurveyCard;
