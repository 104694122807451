import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'features';

export const useTabHooks = () => {
	const [singleProductTabDisabled, setSingleProductTabDisabled] = useState(false);
	const [multipleProductTabDisabled, setMultipleProductTabDisabled] = useState(false);
	const {
		singleOptions,
		multipleOptions,
	} = useSelector((state: RootState) => state.filters.survey);

	useEffect(() => {
		setSingleProductTabDisabled(!singleOptions || singleOptions.length === 0);
		setMultipleProductTabDisabled(!multipleOptions || multipleOptions.length === 0);
	}, [singleOptions, multipleOptions]);

	return {
		singleProductTabDisabled,
		multipleProductTabDisabled,
	};
};

export default useTabHooks;
