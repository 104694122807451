/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { submitProductSurvey } from 'thunk';

export interface ProductSurveySubmissionState {
    loading: boolean;
    error: string | null;
}

const initialState: ProductSurveySubmissionState = {
	loading: false,
	error: null,
};

export const productSurveySubmissionSlice = createSlice({
	name: 'productSurveySubmission',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(submitProductSurvey.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(submitProductSurvey.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(submitProductSurvey.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message || 'Failed to fetch product survey data';
		});
	},
	reducers: {},
});

export default productSurveySubmissionSlice.reducer;
export const productSurveySubmission = productSurveySubmissionSlice.reducer;
