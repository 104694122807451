import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { RootState } from 'features';
import ProductDomainCard from 'components/Cards/ProductDomainCard';

function SelectedDomainCards({ productId } : { productId: string }) {
	const { selectedDomains } = useSelector((state:RootState) => state.filters.domain);

	return (
		<Box sx={{
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		}}
		>
			{selectedDomains.map((id) => (
				(
					<Box width="100%">
						<ProductDomainCard
							productId={productId}
							domainId={id}
							key={`${id}-domain`}
						/>
					</Box>
				)
			))}
		</Box>
	);
}

export default SelectedDomainCards;
