/* eslint-disable no-param-reassign */
import { combineReducers } from '@reduxjs/toolkit';
import { productInfoReducer } from './productInfoSlice';
import { productRatingReducer } from './productRatingSlice';

const product = combineReducers({
	productInfo: productInfoReducer,
	rating: productRatingReducer,
});

export default product;
