import { useSelector, useDispatch } from 'react-redux';
import {
	Card, CardContent, CardHeader, IconButton,
} from '@mui/material';
import { RootState, setSelectedDomain } from 'features';
import CloseIcon from '../../icons/CloseIcon';
import DomainCardContent from './DomainCardContent';

export function ProductDomainCard({ productId, domainId }
	: { productId: string, domainId: string }) {
	const { domainEntities, selectedDomains } = useSelector(
		(state:RootState) => state.filters.domain,
	);
	const domainInfo = domainEntities[domainId];
	const dispatch = useDispatch();

	const handleCloseClick = () => {
		const newSelectedDomains = selectedDomains.filter((id) => id !== domainId);
		dispatch(setSelectedDomain(newSelectedDomains));
	};

	return (
		<Card
			sx={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: '#FFFFFF',
				width: '100%',
				height: 'auto',
				marginBottom: '15px',
			}}
		>
			<CardHeader
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%',
					// padding: '10px',
					padding: '16px',
					height: '88px',
				}}
				titleTypographyProps={{
					sx: {
						color: '#455468',
						fontFamily: 'Inter, sans-serif',
						fontWeight: 600,
						fontSize: '18px',
						textAlign: 'left',
						marginTop: '-10px',
					},
				}}
				title={domainInfo?.name}
				action={(
					<IconButton
						onClick={handleCloseClick}
						sx={{
							'& .MuiSvgIcon-root': {
								width: '40px',
								height: '40px',
							},
							'& .MuiIconButtonBase-root': {
								padding: '0px',

							},

						}}
					>
						<CloseIcon />
					</IconButton>
				)}
			/>
			<CardContent sx={{ height: '100%', marginTop: '-16px' }}>
				<DomainCardContent domainId={domainId} productId={productId} />
			</CardContent>
		</Card>
	);
}

export default ProductDomainCard;
