import { useParams, Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { getProductInfo, getSharedProductInfo } from 'thunk';
import { RootState } from 'features';
import { ComparisonProductIntroCard } from 'components';

export function MultipleProductInfoPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// console.log('MultipleProductInfoPage');
	const { surveyId, clientId } = useParams();
	const { isShare, shareCode } = useSelector((state:RootState) => state.auth);
	const {
		selectedProductList,
	} = useSelector((state:RootState) => state.filters.survey);
	useEffect(
		() => {
			if (!isShare && surveyId) {
				dispatch(getProductInfo({
					data: {
						surveyId,
					},
				}) as any);
				navigate(`/client/${clientId}/comparative/${surveyId}/metrics`);
			} else if (isShare && surveyId && shareCode) {
				dispatch(getSharedProductInfo({
					data: {
						surveyId,
						code: shareCode,
					},
				}) as any);
				navigate(`/share/comparative/${surveyId}/metrics?code=${shareCode}`);
			}
		},
		[surveyId, dispatch, isShare, shareCode, clientId, navigate],
	);

	const getGridSize = () => {
		if (selectedProductList?.length === 1) {
			return 12;
		}
		if (selectedProductList?.length === 2) {
			return 6;
		}
		return 4;
	};
	// console.log('selectedProductList', selectedProductList);
	return (
		<Box>
			<Grid container spacing={2} padding="16px">
				{selectedProductList?.map((id, index) => (
					<Grid item xs={getGridSize()} key={`${id}-introCard-Grid`}>
						<ComparisonProductIntroCard key={`${id}-introCard`} index={index} productId={String(id)} />
					</Grid>
				))}
			</Grid>
			<Outlet />
		</Box>
	);
}

export default MultipleProductInfoPage;
