import {
	Grid, Box, Paper, CssBaseline, ThemeProvider,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoginForm } from 'components';
import { auth, getAccess } from 'thunk';
import theme from 'theme';
import logo from 'images/logo.png';
import { TokenService } from 'utils';

const defaultTheme = theme;

export default function SignInSide() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { userId, userRole, isLoggedIn } = useSelector((state) => state.auth);

	useEffect(
		() => {
			if (isLoggedIn && location.state && location.state.from) {
				console.log('isLoggedIn && location.state && location.state.from');
				console.log('navigate(location.state.from)', navigate(location.state.from));
				navigate(location.state.from);
			} else if (userId) {
				// Navigate based on user role
				if (userRole === 'codesigner') {
					navigate(`/codesigner/${userId}`);
				} else {
					navigate(`/client/${userId}`);
				}
			} else if (TokenService.getAccessToken() || TokenService.getRefreshToken()) {
				dispatch(getAccess({ suppressed: true }));
			}
		},
		[userId, location, navigate, dispatch, userRole],
	);

	return (
		<ThemeProvider theme={defaultTheme}>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={12}
					sm={8}
					md={6}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Box
						component={Paper}
						elevation={6}
						sx={{
							width: { xs: '100%', md: '95%', lg: '90%' },
							height: { xs: '100%', md: '95%', lg: '90%' },
							p: 3,
						}}
					>
						<LoginForm />
					</Box>
				</Grid>
				<Grid item xs={false} sm={4} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', justifyContent: 'center' }}>
					<Box component="img" src={logo} sx={{ maxHeight: '70%', maxWidth: '70%' }} alt="Company Logo" />
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}
