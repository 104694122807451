import {
	Grid, Box,
} from '@mui/material';
import { useState } from 'react';
import { FeatureRating } from 'api';
import ImportanceScore from './ImportanceScore';
import ScoreRating from './ScoreRating';
import MavieGauge from '../../../Gauges';
import WarningInfo from './WarningInfo';
import FeatureDescription from './FeatureDescription';
import FeatureExplanation from './FeatureExplanation';

function FeatureCardContent({ featureRating }:{featureRating:FeatureRating}) {
	const {
		rating, importance, description, name, percentages,
	} = featureRating;
	const ratingValue = parseFloat(rating);
	const importanceValue = parseFloat(importance);
	const [explanationOn, setExplanationOn] = useState(true);
	const filteredPercentages = percentages?.filter((percentage) => percentage !== 0);

	const warning = () => {
		// if rating is less than importance by more than 1, then show warning
		if (ratingValue && importanceValue && ratingValue < importanceValue - 1) {
			return true;
		}
		return false;
	};
	const getImportanceRendered = () => {
		if (ratingValue === 0) {
			// console.log('feature score 0, special case');
			return (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center', // Center the gauge horizontally
						alignItems: 'center', // Center the gauge vertically
						width: '95%',
					}}
				>
					<MavieGauge
						type="placeholder"
					/>
				</Box>
			);
		}
		if (ratingValue !== 0) {
			return (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center', // Center the gauge horizontally
						alignItems: 'center', // Center the gauge vertically
						width: '95%',
					}}
				>
					<MavieGauge
						type="percentage"
						value={ratingValue}
						importance={importanceValue}
						percentages={filteredPercentages || [0.2, 0.2, 0.2, 0.2, 0.2]}
					/>
				</Box>
			);
		}
		return null;
	};

	return (
		<>
			<Grid
				container
				spacing={1}
				marginTop={0}
				alignItems="center"
				paddingLeft={2}
				paddingRight={2}
				paddingTop={0}
			>
				<Grid
					item
					xs={5}
					sm={4}
					md={6}
					sx={{
						display: 'flex',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',

						}}
					>
						<FeatureDescription description={featureRating?.description || ''} />
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'start', // Align the content horizontally
								justifyContent: 'end', // Center the content vertically if needed
							}}
						>
							<ScoreRating
								score={rating}
								explanationOn={explanationOn}
								setExplanationOn={setExplanationOn}
							/>
							<ImportanceScore score={importance} />
							{warning() && <WarningInfo />}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={7} sm={8} md={6} lg={6}>
					{getImportanceRendered()}
				</Grid>
			</Grid>
			{explanationOn && <FeatureExplanation percentages={percentages} score={ratingValue} />}
		</>
	);
}

export default FeatureCardContent;
