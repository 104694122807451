import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import {
	RootState, addErrorMessage,
} from 'features';
import { submitProductSurvey } from 'thunk';

export function SubmitButton() {
	const dispatch = useDispatch();
	const { loading: submitting } = useSelector((state: RootState) => state.productSurveySubmission);
	const { domains, products } = useSelector((state: RootState) => state.productSurvey);

	const { currentSurveyId } = useSelector((state:RootState) => state.filters.survey);

	const surveyResponse = useSelector(
		(state: RootState) => state.productSurveyInitial,
	);
	if (!surveyResponse || !surveyResponse[currentSurveyId as string]) {
		return null;
	}
	// Getting the Redux State
	const {
		ratingEntities, importanceEntities,
	} = surveyResponse[currentSurveyId as string];

	// Handle submission of the survey
	const handleSubmit = () => {
		let allQuestionsAnswered = true;

		// Check if all questions for all products have been answered
		// eslint-disable-next-line guard-for-in
		for (const productId in ratingEntities) {
			if (Object.prototype.hasOwnProperty.call(ratingEntities, productId)) {
				for (const questionId in ratingEntities[productId]) {
					if (Object.prototype.hasOwnProperty.call(ratingEntities[productId], questionId)) {
						const { rating } = ratingEntities[productId][questionId];

						if (rating === null) {
							allQuestionsAnswered = false;
							dispatch(addErrorMessage('Please answer all questions.'));
							break;
						}
					}
				}
			}
			if (!allQuestionsAnswered) break; // Stop if any question is unanswered
		}
		// Check feature importance ratings
		if (allQuestionsAnswered) {
			// eslint-disable-next-line guard-for-in
			for (const productId in importanceEntities) {
				if (Object.prototype.hasOwnProperty.call(importanceEntities, productId)) {
					for (const featureId in importanceEntities[productId]) {
						if (Object.prototype.hasOwnProperty.call(importanceEntities[productId], featureId)) {
							const { rating } = importanceEntities[productId][featureId];
							if (rating === null) {
								allQuestionsAnswered = false;
								dispatch(addErrorMessage('Please rate the importance of all features.'));
								break;
							}
						}
					}
				}
				if (!allQuestionsAnswered) break; // Stop if any importance rating is missing
			}
		}
		if (!allQuestionsAnswered) {
			return;
		}
		// Building the productRatings payload
		const productRatings = products.map((product) => ({
			productId: product.productId.toString(),
			domainRatings: domains.map((domain) => ({
				domainId: domain.domainId.toString(),
				featureRatings: domain.features.map((feature) => ({
					featureId: feature.featureId.toString(),
					ratings: feature.questions.map((question) => ({
						questionId: question.questionId.toString(),
						rating: ratingEntities?.[product.productId]?.[question.questionId]?.rating ?? null,
					})),
					importance: importanceEntities?.[product.productId]?.[feature.featureId]?.rating ?? null,
				})),
			})),
		}));

		if (currentSurveyId) {
		// Dispatching the submitProductSurvey thunk with surveyId and productRatings
			dispatch(submitProductSurvey({
				data: {
					surveyId: currentSurveyId.toString(),
					productRatings,
				},
			}) as any);
		}
	};

	return (
		<LoadingButton
			variant="outlined"
			color="primary"
			loading={submitting}
			onClick={handleSubmit}
			endIcon={<SendIcon />}
		>
			Submit
		</LoadingButton>
	);
}
export default SubmitButton;
