import {
	Box, Typography, Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import { StartPersonaSurveyButton, StartProductSurveyButton } from 'components';

export function CodesignerMainPage() {
	const {
		firstName, lastName, personaDone,
	} = useSelector((state: RootState) => state.auth);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-start',
				height: '100vh',
				textAlign: 'center',
				marginTop: '20%',
				p: 3,
			}}
		>
			<Typography variant="h4" gutterBottom>
				Welcome,
				{' '}
				{firstName}
				{' '}
				{lastName}
				!
			</Typography>
			{/* Conditionally render Persona Survey status or button */}
			{personaDone ? (
				<Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
					Persona Survey is already completed.
				</Typography>
			) : (
				<Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" columns={{ mt: 4 }}>
					<Grid item xs={7} width="70%">
						<StartPersonaSurveyButton disabled={personaDone} />
					</Grid>
				</Grid>
			)}
			{/* Always display the Product Survey Button */}
			{personaDone && (
				<Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
					<Grid item xs={7} width="70%">
						<StartProductSurveyButton />
					</Grid>
				</Grid>
			) }
		</Box>
	);
}

export default CodesignerMainPage;
