/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	HttpResponse, http, delay,
} from 'msw';

const customerLoginRes = {
	id: 12,
	userRole: 'CUSTOMER',
	singleProductSurveyList: [
		// {
		// 	surveyId: 1,
		// 	productId: 1,
		// 	productName: 'Product 1',
		// },
		// {
		// 	surveyId: 2,
		// 	productId: 2,
		// 	productName: 'Product 2',
		// },
	],
	multipleProductSurveyList: [
		// {
		// 	surveyId: 3,
		// 	surveyName: 'Survey 1',
		// 	productList: [
		// 		{
		// 			productId: 3,
		// 			productName: 'Product 3',
		// 		},
		// 		{
		// 			productId: 4,
		// 			productName: 'Product 4',
		// 		},
		// 	],
		// },
		// {
		// 	surveyId: 4,
		// 	surveyName: 'Survey 2',
		// 	productList: [
		// 		{
		// 			productId: 5,
		// 			productName: 'Product 5',
		// 		},
		// 		{
		// 			productId: 6,
		// 			productName: 'Product 6',
		// 		},
		// 	],
		// },
	],
	accessToken: '1234',
};

const loginResolver = async () => {
	delay(1000);
	return HttpResponse.json(customerLoginRes);
};

const loginHandler = [
	http.post('http://localhost:8080/public/login', loginResolver),
];

const accessHandler = [
	http.get('http://localhost:8080/customer/access', loginResolver),
];

export { loginHandler, accessHandler };

export default loginHandler;
