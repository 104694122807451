import { Box, Grid, Divider } from '@mui/material';

import { useSelector } from 'react-redux';
import {
	ProductOrSurveySelector, PersonaFilterWithSelector, LogoutButton, DomainSelector, ShareButton,
} from 'components';
import { RootState } from 'features';
import ViewToggle from './ViewToggle';
import AgeSelector from './AgeSelector';
import GenderSelector from './GenderSelector';
import FilterReset from './FilterReset';

export function ProductFilter() {
	const { selectedTab, selectedSingleOption, selectedMultipleOption } = useSelector(
		(state: RootState) => state.filters.survey,
	);
	const { isShare } = useSelector((state: RootState) => state.auth);
	const isSingleProductPage = selectedTab === 'SINGLE_PRODUCT';
	const productFilterPanel = () => (
		<>
			<FilterReset />
			<Box
				sx={{
					overflowY: 'auto',
					overflowX: 'hidden',
					flex: 1,
					width: '100%',
				// paddingLeft: '10px',
				}}
			>

				<PersonaFilterWithSelector />

				<Divider sx={{
					width: '100%',
					marginTop: '10px',
					marginBottom: '10px',
				}}
				/>

				<AgeSelector />
				<GenderSelector />

				<Divider sx={{
					width: '100%',
					marginTop: '10px',
				// marginBottom: '10px',
				}}
				/>

				<DomainSelector />
				<ViewToggle />
			</Box>
		</>
	);

	const surveyFilterPanel = () => (
		<>
			<FilterReset />
			<Box
				sx={{
					overflowY: 'auto',
					overflowX: 'hidden',
					flex: 1,
					width: '100%',
					// paddingLeft: '10px',
				}}
			>
				<DomainSelector />
				<ViewToggle />
			</Box>
		</>
	);

	const renderFilterPanel = () => {
		if (isSingleProductPage && selectedSingleOption) {
			return productFilterPanel();
		}
		if (!isSingleProductPage && selectedMultipleOption) {
			return surveyFilterPanel();
		}
		return null;
	};
	return (
		<Box
			sx={
				{
					position: 'relative', // added
					maxHeight: '100vh', // ensure it does not exceed the viewport height
					overflow: 'auto', // hide the scrollbars
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'start',
					alignItems: 'start',
					width: '95%',
					height: '100%',
					marginTop: '10px',
					paddingLeft: '23px',
					paddingRight: '5px',
					marginLeft: '0px',
					marginRight: '0px',
					marginBottom: '10px',
					borderRadius: '0px 0px 0px 0px',
					boxShadow: '0px 0px 0px 0px',
				}
			}
		>
			<ProductOrSurveySelector />
			<Divider
				sx={{
					width: '100%',
					marginTop: '10px',
				}}
			/>
			{
				renderFilterPanel()
			}
			{!isShare && (
				<Grid
					container
					spacing={1}
					sx={{
						position: 'sticky', bottom: 0, zIndex: 10,
					}}
				>
					<Grid item xs={6}>
						<ShareButton />
					</Grid>
					<Grid item xs={6}>
						<LogoutButton />
					</Grid>
				</Grid>
			)}
		</Box>
	);
}

export default ProductFilter;
