/* eslint-disable no-shadow */
import { CUSTOMER_CONFIG, axiosInstance, CUSTOMER_SHARE_CONFIG } from 'utils';

const CUSTOMER_SURVEY_PATH = '/survey';

export interface ProductInformation {
    productId: string;
    modelName: string;
    modelNumber: string;
    imageUrl: string;
    description: string;
    brandName: string;
    brandIconImageUrl: string;
    categoryName: string;
}
export interface getProductResponse {
    surveyId:string;
    products:ProductInformation[];
}

export const getProductInformation = async ({ surveyId }: { surveyId: string }):
Promise<getProductResponse> => {
	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/product-info`, CUSTOMER_CONFIG());
	return response as any as getProductResponse;
};

export const getSharedProductInformation = async (
	{ surveyId, code }: { surveyId: string, code: string },
): Promise<getProductResponse> => {
	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/product-info`, {
		...CUSTOMER_SHARE_CONFIG(code),
	});
	return response as any as getProductResponse;
};
