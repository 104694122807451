import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setIsShare, setShareCode } from 'features';
import { Outlet, useSearchParams, useParams } from 'react-router-dom';

import { getShareCodeAccess } from 'thunk';

function ShareRoute() {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const { isLoggedIn, loading } = useSelector((state: RootState) => state.auth);
	const { singleSurveyId, surveyId } = useParams();
	const surveyIdParam = singleSurveyId || surveyId || '0';
	const shareCode = searchParams.get('code');

	useEffect(
		() => {
			if (shareCode) {
				dispatch(getShareCodeAccess(
					{
						data: {
							code: shareCode,
							surveyId: surveyIdParam,
						},
					},
				) as any);
			}
		},
		[dispatch, shareCode, surveyIdParam],
	);

	if (isLoggedIn) {
		dispatch(setIsShare(true));
		dispatch(setShareCode(shareCode));
		return <Outlet />;
	}
	if (loading) {
		return <CircularProgress color="inherit" />;
	}

	return (
		<div>
			<h1>Unauthorized</h1>
		</div>
	);
}

export default ShareRoute;
