import {
	Grid, Typography, Box,
} from '@mui/material';
import { useState } from 'react';
import theme from 'theme';
import { FeatureRating } from 'api';
import ScoreBar from '../../../../../Ratings/ScoreBar';
import FeatureCard from '../../../../FeatureCard';
import WarningIcon from '../../../../../icons/WarningIcon';

function FeatureRow({ domainId, featureRating, importanceRatingOn }:{
	domainId: string,
	featureRating: FeatureRating
	importanceRatingOn: boolean,
}) {
	const [open, setOpen] = useState(false);
	const featureImportance = parseFloat(featureRating?.importance);
	const rating = parseFloat(featureRating?.rating);
	const name = featureRating?.name || 'No Feature Name';
	const handleClick = () => {
		if (rating && rating !== 0) setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const warning = () => {
		// if rating is less than importance by more than 1, then show warning
		if (rating && featureImportance && rating < featureImportance - 1) {
			return true;
		}
		return false;
	};

	const getFeatureRatingFromatted = () => {
		if (rating && rating !== 0) {
			return rating.toFixed(1);
		}
		return 'N/A';
	};

	const cursorStyle = () => {
		// if the feature has no rating, then the cursor is not clickable
		if (rating && rating !== 0) {
			return 'pointer';
		}
		return 'default';
	};

	return (
		<Box sx={{
			paddingTop: '10px',
			paddingBottom: '10px',
		}}
		>
			<Box
				sx={
					{ width: '100%' }
				}
			>
				<Typography
					sx={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: 600,
						// marginLeft: '-10px',
						fontSize: ['12px', '13px', '14px'], // [mobile, tablet, desktop
						textAlign: 'left',
						color: theme.palette.primary.main,
					}}
				>
					{name}
				</Typography>
			</Box>
			<Grid
				container
				spacing={1}
				// gap={1}
				alignItems="center"
				sx={{
					// marginBottom: ['5px', '8px', '10px', '10px'], // [mobile, tablet, desktop
					cursor: cursorStyle(),
					justifyContent: 'space-between',
				}}
				onClick={handleClick}
			>
				<Grid item xs={9.5}>
					<ScoreBar
						score={rating || 0}
						importance={featureImportance}
						importanceRatingOn={importanceRatingOn}
						highlight
					/>
				</Grid>
				<Grid
					item
					xs={1.5}
					lg={1}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end', // Aligns content to the end
						justifyItems: 'flex-end', // Aligns content to the end
						alignItems: 'center', // Centers items vertically
					}}
				>
					{warning() && (
						<WarningIcon
							sx={{
								width: ['16px', '18px', '20px'],
								height: ['16px', '18px', '20px'],
								// marginRight: ['8px', '8px', '10px'],
							}}
						/>
					)}
				</Grid>
				<Grid
					item
					xs={0.5}
					lg={1}
					sx={{
						display: 'flex',
						justifyContent: 'flex-end', // Aligns content to the end
						alignItems: 'center', // Centers items vertically
					}}
				>

					<Typography
						sx={{
							color: '#455468',
							fontFamily: 'Inter, sans-serif',
							fontWeight: 600,
							fontSize: ['12px', '13px', '14px'],
							textAlign: 'right',
						}}
					>
						{getFeatureRatingFromatted()}
					</Typography>
				</Grid>
			</Grid>
			<FeatureCard
				open={open}
				handleCloseClick={handleClose}
				featureRating={featureRating}
				domainId={domainId}
			/>
		</Box>
	);
}

export default FeatureRow;
