/* eslint-disable default-param-last */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationError } from 'yup';
import { LoginExpiredError } from 'utils/errors';
import { ErrorResponse } from '../api';

interface AsyncThunkConfig {
  state: any;
  rejectValue: ErrorResponse;
}

interface ThunkAPI {
  dispatch: Function;
  state: any;
  rejectWithValue: Function;
}

interface BaseThunkArg<T> {
  data?: T; // Make data optional
  suppressed?: boolean;
}

// Utility for standard error handling
const handleThunkError = <T>(error: any, thunkAPI: ThunkAPI, arg?: BaseThunkArg<T>) => {
	if (error instanceof ValidationError) {
		console.log('error', error);
		return thunkAPI.rejectWithValue({ error: 'Invalid data structure from server' });
	}
	if (error instanceof LoginExpiredError) {
		console.log('login expire error in base thunk');
		// dispatch an action to logout the user
		thunkAPI.dispatch({ type: 'LOGOUT' });
	}
	console.error('Error in thunk:', error);
	console.log('rejecting with value');
	return thunkAPI.rejectWithValue({
		error: error.message || 'Unknown error',
		suppressed: arg?.suppressed || false,
	});
};

// Base thunk creator function
// Base thunk creator function
const createBaseAsyncThunk = <RequestType, ResponseType>(
	typePrefix: string,
	apiFunction: (arg?: RequestType) => Promise<ResponseType>,
) => createAsyncThunk<ResponseType, BaseThunkArg<RequestType> | void, AsyncThunkConfig>(
	typePrefix,
	async (arg = { data: undefined }, thunkAPI) => {
		try {
		// Optionally dispatch an action type as API_CALL for all thunks
			thunkAPI.dispatch({ type: 'API_CALL' });
			console.log('arg in base thunk', arg);
			const response = await apiFunction(arg?.data);
			console.log('response in base thunk', response);
			return response as ResponseType;
		} catch (error) {
			// console.error('error in base thunk', error);
			// console.log('returning error');
			return handleThunkError(error, thunkAPI as any as ThunkAPI, arg as any);
		}
	},
);

export default createBaseAsyncThunk;
