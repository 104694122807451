/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { domainFilter, SurveyFilterResponse } from 'api';
import { SURVEY_FILTER_THUNK_PREFIX } from 'thunk';

import { RESET_STATE } from '../../constant';

export interface domainFilterState {
	loading: boolean;
	error: string | null;
	domainEntities: { [id: string]:domainFilter };
	domainIds: string[];
	selectedDomains: string[];
}

const initialState: domainFilterState = {
	loading: false,
	error: null,
	domainEntities: {},
	domainIds: [],
	selectedDomains: [],
};

export const domainFilterSlice = createSlice({
	name: 'domainFilter',
	initialState,

	reducers: {
		setSelectedDomain: (state, { payload }) => {
			state.selectedDomains = payload;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(RESET_STATE, (state: domainFilterState) => {
		// reset to all true
			state.selectedDomains = state.domainIds;
		});
		builder.addMatcher(
			(action) => action.type.startsWith(SURVEY_FILTER_THUNK_PREFIX)
			&& action.type.endsWith('pending'),
			(state) => {
			// reset to all true, clear all filters, reset to initial state
				Object.assign(state, initialState);
				state.loading = true;
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(SURVEY_FILTER_THUNK_PREFIX)
			&& action.type.endsWith('fulfilled'),
			(state, action: { payload: SurveyFilterResponse }) => {
				state.loading = false;
				state.error = null;
				const domainEntities = action.payload.domains.reduce((acc, domain) => {
					acc[String(domain.domainId)] = domain;
					return acc;
				}, {} as { [id: string]: domainFilter });
				state.domainEntities = domainEntities;
				state.domainIds = action.payload.domains.map((domain) => domain.domainId);
				state.selectedDomains = state.domainIds;
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(SURVEY_FILTER_THUNK_PREFIX)
		&& action.type.endsWith('rejected'),
			(state, action) => {
				state.loading = false;
				state.error = action.payload as string;
			},
		);
	},
});
export const { setSelectedDomain } = domainFilterSlice.actions;
export const domainSlice = domainFilterSlice.reducer;
