/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenService } from 'utils';
import {
	login, getAccess, getShareCodeAccess, AUTH_THUNK_PREFIX,
} from 'thunk';
import { LoginResponse } from 'api';

const handlePending = (state:AuthState) => {
	Object.assign(state, initialState);
	state.loading = true;
};

const LOGOUT = 'LOGOUT';
const handleLoginFulfilled = (state:AuthState, action: PayloadAction<LoginResponse>) => {
	// console.log('action.payload in auth state', action.payload);
	state.loading = false;
	state.isLoggedIn = true;
	state.userId = action.payload.id;
	state.userRole = action.payload.userRole; // Store the userRole
	state.firstName = action.payload.firstName;
	state.lastName = action.payload.lastName;
	state.personaDone = action.payload.personaDone;
	state.error = null;
	const rememberMe = !!action.payload.refreshToken;
	if (action.payload.accessToken) {
		TokenService.setTokens({
			accessToken: action.payload.accessToken,
			refreshToken: action.payload.refreshToken,
			rememberMe,
		});
	}
};

const handleRejected = (state:AuthState, action:any) => {
	Object.assign(state, initialState);
	state.error = action.error.message;
};

interface AuthState {
	isLoggedIn: boolean | null;
	userId: string | null;
	loading: boolean;
	error: string | null;
	success: string | null;
	isShare: boolean;
	shareCode: string | null;
	userRole: string | null;
	firstName?: string | null; // Added the Codesigner Names
	lastName?: string | null;
	personaDone?: boolean;
}

const initialState: AuthState = {
	isLoggedIn: null,
	userId: null,
	userRole: null,
	loading: false,
	error: null,
	success: null,
	isShare: false,
	shareCode: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetProcess: (state) => {
			state.error = null;
			state.success = null;
			state.loading = false;
		},
		setIsShare: (state, action) => {
			state.isShare = action.payload;
		},
		setShareCode: (state, action) => {
			state.shareCode = action.payload;
		},
		setPersonaDone: (state, action) => {
			state.personaDone = action.payload;
		},
		logout: (state) => {
			state.isLoggedIn = false;
			state.userId = null;
			state.firstName = null;
			state.userRole = null;
			state.userRole = null;
			state.error = null;
			state.success = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.fulfilled, handleLoginFulfilled)
			.addCase(getAccess.fulfilled, handleLoginFulfilled)
			.addCase(
				LOGOUT,
				(state) => {
					Object.assign(state, initialState);
				},
			)
			.addCase(getShareCodeAccess.fulfilled, (state, action) => {
				Object.assign(state, initialState);
				state.isLoggedIn = true;
				state.userRole = 'shared user';
			})
			.addMatcher((action) => action.type.startsWith(AUTH_THUNK_PREFIX) && action.type.endsWith('pending'), handlePending)
			.addMatcher((action) => action.type.startsWith(AUTH_THUNK_PREFIX) && action.type.endsWith('rejected'), handleRejected);
	},
});

export const {
	resetProcess, setIsShare, setShareCode, logout, setPersonaDone,
} = authSlice.actions;
export const auth = authSlice.reducer;
export type { AuthState };
export const selectAuth = (state: { auth: AuthState }) => state.auth;
