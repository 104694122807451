import { CUSTOMER_CONFIG, axiosInstance, CUSTOMER_SHARE_CONFIG } from 'utils';

const CUSTOMER_SURVEY_PATH = '/survey';

export interface SurveyFilterResponse {
    surveyId: string;
    personaFilters: PersonaFilter[] ;
    domains: domainFilter[];
}

export interface PersonaFilter {
    categoryId: string;
    name: string;
    iconImageUrl: string;
    description: string;
    minRating: number;
    maxRating: number;
}

export interface domainFilter {
    domainId: string;
    name: string;
    iconMain: string;
    iconRound: string;
    description: string;
}

export const getSurveyFilters = async (surveyId: string): Promise<SurveyFilterResponse> => {
	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/filter`, CUSTOMER_CONFIG());
	return response as any as SurveyFilterResponse;
};

export const getSharedSurveyFilters = async (
	{ surveyId, code }: { surveyId: string, code: string },
): Promise<SurveyFilterResponse> => {
	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/filter`, {
		...CUSTOMER_SHARE_CONFIG(code),
	});
	return response as any as SurveyFilterResponse;
};
