/* eslint-disable max-len */
import {
	auth as getAccessApi,
	login as loginApi,
	getShareCodeAccess as getShareCodeAccessApi,
	getSurveyFilters as getSurveyFiltersApi,
	getSharedSurveyFilters as getSharedSurveyFiltersApi,
	getProductInformation as getProductInformationApi,
	getSharedProductInformation as getSharedProductInformationApi,
	getSurveyCodesigners as getSurveyCodesignersApi,
	getSharedSurveyCodesigners as getSharedSurveyCodesignersApi,
	getProductRatings as getProductRatingsApi,
	getSharedProductRatings as getSharedProductRatingsApi,
	getShareLink as getShareLinkApi,
	sendReportByEmail as sendReportByEmailApi,
	getProductReport as getProductReportApi,
	getSurveyProductInfo as getSurveyProductInfoApi,
	getSurveyProductRating as getSurveyProductRatingApi,
	getSurveyProductFeature as getSurveyProductFeatureApi,
	getComparisonReport,
	getShareProductAccessInfo as getShareProductAccessInfoApi,
	getShareSurveyAccessInfo as getShareSurveyAccessInfoApi,
	sendProductReport as sendProductReportApi,
	sendSurveyReport as sendSurveyReportApi,
	getProductRatings,
	getPersonaSurveyQuestions as getPersonaSurveyQuestionsApi,
	postPersonaSurveyQuestions as postPersonaSurveyQuestionsApi,
	getProductSurveyQuestions as getProductSurveyQuestionsApi,
	submitProductSurvey as submitProductSurveyAPI,

} from 'api';
import createBaseAsyncThunk from './baseThunk';

export const AUTH_THUNK_PREFIX = 'auth';
export const SURVEY_FILTER_THUNK_PREFIX = 'survey/filter';
export const PRODUCT_INFO_THUNK_PREFIX = 'survey/productInfo';
export const CODESIGNER_THUNK_PREFIX = 'survey/codesigner';
export const RATING_THUNK_PREFIX = 'survey/rating';
export const SHARE_LINK_THUNK_PREFIX = 'survey/shareLink';
export const REPORT_THUNK_PREFIX = 'survey/report';
export const PERSONA_SURVEY_THUNK_PREFIX = 'survey/personaSurvey';
export const PERSONA_ANSWER_THUNK_PREFIX = 'survey/personaAnswerSurvey';
export const PRODUCT_SURVEY_THUNK_PREFIX = 'survey/productSurvey';
export const PRODUCT_ANSWER_THUNK_PREFIX = 'survey/productAnswerSurvey';

export const getAccess = createBaseAsyncThunk(`${AUTH_THUNK_PREFIX}/auth`, getAccessApi);
export const login = createBaseAsyncThunk(`${AUTH_THUNK_PREFIX}/login`, loginApi);
export const getShareCodeAccess = createBaseAsyncThunk(`${AUTH_THUNK_PREFIX}/getShareCodeAccess`, getShareCodeAccessApi);
export const getSurveyFilters = createBaseAsyncThunk(`${SURVEY_FILTER_THUNK_PREFIX}/getSurveyFilters`, getSurveyFiltersApi);
export const getSharedSurveyFilters = createBaseAsyncThunk(`${SURVEY_FILTER_THUNK_PREFIX}/getSharedSurveyFilters`, getSharedSurveyFiltersApi);
export const getProductInfo = createBaseAsyncThunk(`${PRODUCT_INFO_THUNK_PREFIX}/getProductInfo`, getProductInformationApi);
export const getSharedProductInfo = createBaseAsyncThunk(`${PRODUCT_INFO_THUNK_PREFIX}/getSharedProductInfo`, getSharedProductInformationApi);
export const getCodesigners = createBaseAsyncThunk(`${CODESIGNER_THUNK_PREFIX}/getCodesigners`, getSurveyCodesignersApi);
export const getSharedCodesigners = createBaseAsyncThunk(`${CODESIGNER_THUNK_PREFIX}/getSharedCodesigners`, getSharedSurveyCodesignersApi);
export const getProductRating = createBaseAsyncThunk(`${RATING_THUNK_PREFIX}/getProductRating`, getProductRatingsApi);
export const getSharedProductRating = createBaseAsyncThunk(`${RATING_THUNK_PREFIX}/getSharedProductRating`, getSharedProductRatingsApi);
export const getSurveyShareLink = createBaseAsyncThunk(`${SHARE_LINK_THUNK_PREFIX}/getSurveyShareLink`, getShareLinkApi);
export const sendReportByEmail = createBaseAsyncThunk(`${REPORT_THUNK_PREFIX}/sendReportByEmail`, sendReportByEmailApi);
export const postPersonaSurveyQuestions = createBaseAsyncThunk(
	`${PERSONA_ANSWER_THUNK_PREFIX}/postPersonaSurveyQuestions`,
	postPersonaSurveyQuestionsApi,
);
export const getPersonaSurveyQuestions = createBaseAsyncThunk(
	`${PERSONA_SURVEY_THUNK_PREFIX}/getPersonaSurveyQuestions`,
	getPersonaSurveyQuestionsApi,
);
export const getProductSurveyQuestions = createBaseAsyncThunk(
	`${PRODUCT_SURVEY_THUNK_PREFIX}/getProductSurveyQuestions`,
	getProductSurveyQuestionsApi,
);
export const submitProductSurvey = createBaseAsyncThunk(
	`${PRODUCT_ANSWER_THUNK_PREFIX}/submitProductSurvey`,
	submitProductSurveyAPI,
);
// export const getProductSurveyQuestions = createBaseAsyncThunk<{ codesignerSurvseyID: string }, ProductSurveyQuestions>(
// 	'survey/getProductSurveyQuestions',
// 	async ({ codesignerSurvseyID }) => {
// 	  const response = await getProductSurveyQuestionsApi({ codesignerSurvseyID });
// 	  return response; // Ensure this returns `ProductSurveyQuestions`
// 	}
//   );
// export const getReport = createBaseAsyncThunk('product/getReport', getProductReportApi);
// export const getSurveyReport = createBaseAsyncThunk('survey/getSurveyReport', getComparisonReport);
// export const getShareProductAccessInfo = createBaseAsyncThunk('auth/getShareProductAcccess', getShareProductAccessInfoApi);
// export const getShareSurveyAccessInfo = createBaseAsyncThunk('auth/getShareSurveyAccess', getShareSurveyAccessInfoApi);
// export const sendProductReport = createBaseAsyncThunk('product/sendProductReport', sendProductReportApi);
// export const sendSurveyReport = createBaseAsyncThunk('survey/sendSurveyReport', sendSurveyReportApi);
