import { useSelector } from 'react-redux';
import {
	Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RootState } from 'features';
import theme from 'theme';
import PersonaFilterSkeleton from './PersonaFilterSkeleton';
import PersonaFilterContent from './PersonaFilterContent';

export function PersonaFilterWithSelector() {
	const { loading, error, personaEntities }	= 	useSelector(
		(state:RootState) => state.filters.persona,
	);
	const { selectedTab } = useSelector((state:RootState) => state.filters.survey);
	const isSingleProductTab = selectedTab === 'SINGLE_PRODUCT';
	const renderPersonaSelectorContent = () => {
		if (error) {
			return null;
		}
		if (loading) {
			return <PersonaFilterSkeleton />;
		}
		if (personaEntities) {
			return <PersonaFilterContent />;
		}
		// console.log('persona entities empty');
		return null;
	};

	return (
		<Accordion
			elevation={0}
			// defaultExpanded
			disableGutters
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="persona-selector-header"
				sx={
					{
						paddingLeft: '0px',
						paddingRight: '0px',
						paddingBottom: '10px',
					}
				}
			>
				<Typography
					sx={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: '600',
						fontSize: '14px',
						color: theme.palette.primary.dark,
						marginLeft: '-1px',

					}}
				>
					Persona
				</Typography>
			</AccordionSummary>
			{isSingleProductTab && (
				<AccordionDetails
					sx={{
						marginTop: '-10px',
						marginLeft: '0px',
						padding: '0px',
						width: '100%',
					}}
				>
					{renderPersonaSelectorContent()}
				</AccordionDetails>
			)}
		</Accordion>
	);
}

export default PersonaFilterWithSelector;
