/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ReportType } from 'api';
import {
	REPORT_THUNK_PREFIX,
} from 'thunk';

export interface SharePDFState {
	loading: boolean;
	error: string | null;
	reportType: ReportType;
	comment: string;
	commentTitle: string;
}

const initialState: SharePDFState = {
	loading: false,
	error: null,
	reportType: ReportType.SUMMARY,
	comment: '',
	commentTitle: 'Executive Summary',
};

const shareSlice = createSlice({
	name: 'sharePDF',
	initialState,

	reducers: {
		setReportType(state, action: { payload: ReportType }) {
			state.reportType = action.payload;
		},

		setComment(state, action) {
			state.comment = action.payload;
		},

		setCommentTitle(state, action) {
			state.commentTitle = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) => action.type.startsWith(REPORT_THUNK_PREFIX)
				&& action.type.endsWith('pending'),
			(state: SharePDFState) => {
				state.loading = true;
				state.error = null;
			},
		)
			.addMatcher(
				(action) => action.type.startsWith(REPORT_THUNK_PREFIX)
				&& action.type.endsWith('rejected'),
				(state, action) => {
					state.loading = false;
					state.error = action.payload;
				},
			)
			.addMatcher(
				(action) => action.type.startsWith(REPORT_THUNK_PREFIX)
				&& action.type.endsWith('fulfilled'),
				(state) => {
					state.loading = false;
					state.error = null;
				},
			);
	},
});
export const {
	setReportType,
	setComment, setCommentTitle,
} = shareSlice.actions;

export const shareReducer = shareSlice.reducer;
