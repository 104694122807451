import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { RootState, setProductQuestionValidation } from 'features';
import {
	Box, Typography, RadioGroup, FormControlLabel,
	Radio, Grid,
} from '@mui/material';

interface RatingQuestionProps {
    questionId: string;
    questionContent: string;
	index: number;
    products: Array<{ productId: string; modelName: string }>;
    ratingEntities: any;
	ratingValidations: any;
    handleAnswerChange: (productId: string, questionId: string, value: string) => void;
  }

export function RatingQuestion({
	questionId,
	questionContent,
	products,
	ratingEntities,
	ratingValidations,
	handleAnswerChange,
	index,
}: RatingQuestionProps) {
	const dispatch = useDispatch();
	const { currentSurveyId: surveyId } = useSelector((state:RootState) => state.filters.survey);

	const { products: productsState } = useSelector((state: RootState) => state.productSurvey);

	// eslint-disable-next-line no-shadow
	const handleOptionChange = (productId: string, questionId: string, value: string) => {
		handleAnswerChange(productId, questionId, value);
		dispatch(setProductQuestionValidation({
			surveyId: surveyId as string,
			productId,
			questionId,
			isValid: true,
		}));
	};

	return (
		<Box sx={{ mb: 4 }}>
			<Typography variant="body2" sx={{ mb: 1, fontSize: '16px', fontWeight: 600 }}>
				Question
				{' '}
				{index + 1}
				:
				{' '}
				{questionContent}
			</Typography>

			{/* Header for the rating scales */}
			<Grid
				container
				spacing={1}
				alignItems="center"
				justifyContent="space-between"
				paddingTop="10px"
			>
				<Grid item xs={1.5}>
					<Typography variant="body2" textAlign="center" fontWeight="550">Product</Typography>
				</Grid>
				<Grid item xs={10.5}>
					<Grid container>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Strongly Disagree</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(1)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Disagree</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(2)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Neutral</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(3)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Agree</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(4)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Strongly Agree</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(5)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Not Applicable</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(Null)</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{/* Loop through products for the current question */}
			{products.map((product) => (
				<Grid
					container
					spacing={1}
					paddingTop="10px"
					alignItems="center"
					justifyContent="center"
					width="100%"
					key={product.productId}
				>

					<Grid
						item
						xs={1.5}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						{ratingValidations && !ratingValidations[product.productId]?.[questionId] && (
							<Typography variant="body2" color="error" sx={{ mb: 1 }}>
								*
							</Typography>
						)}
						<Typography
							variant="body2"
							textAlign="center"

						>
							{product.modelName}
						</Typography>
						{products.length !== 1 && (
							<img
								src={
									productsState.find((p) => p.productId === product.productId)?.imageUrl
								}
								alt={product.modelName}
								style={{ width: '50px', height: '50px', marginLeft: '10px' }}
							/>
						)}

					</Grid>
					<Grid item xs={10.5}>
						<RadioGroup
							row
							value={
								ratingEntities?.[product.productId]?.[questionId]?.rating?.toString() || ''
							}
							onChange={(e) => handleOptionChange(product.productId, questionId, e.target.value)}
						>
							<Grid container>
								{[1, 2, 3, 4, 5, 0].map((val) => (
									<Grid
										item
										xs={2}
										key={val}
										sx={{
											display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
										}}
									>
										<FormControlLabel
											sx={{
												width: '100%',
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												alignItems: 'center',
												margin: '0',
												marginLeft: '6.5px',
											}}
											value={val.toString()}
											control={<Radio />}
											label=""
										/>
									</Grid>
								))}
							</Grid>
						</RadioGroup>
					</Grid>
				</Grid>
			))}
		</Box>
	);
}

export default RatingQuestion;
