import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import { getProductRating, getSharedProductRating } from 'thunk';
import { UsabilityRatingCard } from 'components';
import SelectedDomainCards from './SelectedDomainCards';

function ProductMetricPage() {
	const dispatch = useDispatch();
	const { singleSurveyId } = useParams();
	const {
		loading, error, codesignerIds,
	} = useSelector((state: RootState) => state.codesigner);
	const {
		isShare, shareCode,
	} = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		if (!loading && !error && codesignerIds.length > 0) {
			if (isShare && shareCode && singleSurveyId) {
				dispatch(getSharedProductRating({
					data: {
						surveyId: singleSurveyId,
						codesignerIds,
						code: shareCode,
					},
				}) as any);
			} else if (singleSurveyId) {
				dispatch(getProductRating({
					data: {
						surveyId: singleSurveyId,
						codesignerIds,
					},
				}) as any);
			}
		}
	}, [singleSurveyId, codesignerIds, loading, error, isShare, shareCode, dispatch]);

	return (
		<Box
			sx={{
				width: '100%',
				height: 'auto',
				justifyContent: 'start',
				gap: '16px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<UsabilityRatingCard />
			<Typography
				sx={{
					color: '#455468',
					fontFamily: 'Inter, sans-serif',
					fontWeight: 600,
					fontSize: '18px',
					textAlign: 'left',

				}}
			>
				Domains
			</Typography>
			<SelectedDomainCards />
		</Box>

	);
}
export default ProductMetricPage;
