import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Option, RootState, setCurrentSurveyId } from 'features';
import theme from 'theme';
import { useEffect } from 'react';

export function CodesignerSurveySelector() {
	const dispatch = useDispatch();
	const {
		personaDone,
	} = useSelector((state: RootState) => state.auth);
	const { singleOptions, multipleOptions, currentSurveyId } = useSelector(
		(state: RootState) => state.filters.survey,
	);

	const getCombinedOptions = (): Option[] => {
		const newOptions = [] as Option[];
		if (!personaDone) {
			return newOptions;
		}
		singleOptions.map((so:Option) => newOptions.push(so));
		multipleOptions.map((mo:Option) => newOptions.push(mo));

		return newOptions;
	};

	const getNoOptionPlaceholder = (): String => {
		if (!personaDone) {
			return 'Please complete the persona survey first';
		}
		return 'No products available';
	};

	const handleChange = (e:SelectChangeEvent<string>) => {
		const selectedValue = e.target.value;
		// console.log('Selected Survey ID:', selectedValue);
		dispatch(setCurrentSurveyId(selectedValue));
	};

	// useEffect(() => {
	// 	if (!currentSurveyId && singleOptions?.length > 0 || multipleOptions?.length > 0) {
	// 		if (singleOptions?.length > 0) {
	// 			dispatch(setCurrentSurveyId(singleOptions?.[0].value));
	// 		} else {
	// 			dispatch(setCurrentSurveyId(multipleOptions?.[0].value));
	// 		}
	// 	}
	// });
	useEffect(() => {
		if (!currentSurveyId && (singleOptions?.length > 0 || multipleOptions?.length > 0)) {
			if (singleOptions?.length > 0) {
				dispatch(setCurrentSurveyId(singleOptions[0].value));
			} else {
				dispatch(setCurrentSurveyId(multipleOptions[0].value));
			}
		}
	}, [currentSurveyId, singleOptions, multipleOptions, dispatch]);
	return (
		<Select
			value={currentSurveyId ?? ''}
			onChange={handleChange}
			// inputProps={{ 'aria-label': 'Without label' }}
			sx={{
				marginLeft: '0px',
				marginBottom: '10px',
				marginTop: '10px',
				width: '100%',
				height: '35px',
				textAlign: 'left',
				paddingLeft: '0px',
			}}
			displayEmpty
			// eslint-disable-next-line no-shadow
			renderValue={(selectedValue) => {
				if (!selectedValue) {
					// This will handle cases where the value is null, undefined, or an empty string
					return getNoOptionPlaceholder();
				}
				const selectedOption = getCombinedOptions().find(
					(option) => option.value === selectedValue,
				);
				return selectedOption?.label
				|| getNoOptionPlaceholder(); // Fallback to placeholder if label isn't found
			}}

		>
			{getCombinedOptions()?.length > 0 ? getCombinedOptions().map((option) => (
				<MenuItem
					key={`surveyOption-${option.value}`}
					value={option.value} // Pass the number directly
				>
					{option.label}
				</MenuItem>
			)) : (
				<MenuItem
					value=""
					disabled
					sx={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: '400',
						alignContent: 'start',
					}}
				>
					<Typography
						sx={{
							color: theme.palette.text.disabled,
						}}
					>
						{getNoOptionPlaceholder()}
					</Typography>
				</MenuItem>
			)}
		</Select>
	);
}

export default CodesignerSurveySelector;
