/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import JSONbig from 'json-bigint';
import { LoginExpiredError } from '../errors';
import { TokenService } from '../tokenManager';

export const url = () => {
	console.log(process.env.REACT_APP_MODE);
	if (process.env.REACT_APP_MODE === 'LOCAL') {
		return 'http://localhost:8080';
	}
	if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
		return process.env.REACT_APP_API_BASE_URL;
	}
	if (process.env.REACT_APP_MODE === 'PRODUCTION') {
		return process.env.REACT_APP_PRODUCTION_API_BASE_URL;
	}
	return process.env.REACT_APP_API_BASE_URL;
};

export const axiosInstance = axios.create({
	baseURL: url(),
	withCredentials: true,
	transformResponse: (data) => {
		try {
			return JSONbig.parse(data, { storeAsString: true });
		} catch (error) {
			console.error('error', error);
			return data;
		}
	},
});

axiosInstance.interceptors.request.use(async (config: any) => {
	config.headers = config.headers || {};
	// console.log('config in interceptor', config);

	if (config.useBaseEntryPath) {
		config.url = config.useBaseEntryPath + config.url;
	}

	if (config.useAuth) {
		config.headers['Include-Headers'] = true;
		const accessToken = TokenService.getAccessToken();
		// examine if the accessToken is available or expired
		const isTokenValid = TokenService.validateToken(accessToken);

		if (isTokenValid) {
			// console.log('accessToken', accessToken);
			config.headers.Authorization = `Bearer ${accessToken}`;
		} else {
			// console.log('refreshToken', TokenService.getRefreshToken());
			const refreshToken = TokenService.getRefreshToken();
			if (!refreshToken) {
				// redirect to login page
				// console.log('redirect to login page');
				// window.location.href = '#login';
				// stop the request
				return Promise.reject(new LoginExpiredError());
			}
			// call the public/customer/refresh, and use refresh token as query param
			const response = await axiosInstance.get('/public/customer/refresh', {
				params: {
					refreshToken,
				},
			});
			const res = response as any;
			const newAccessToken = res.accessToken;
			TokenService.updateAccessToken(newAccessToken);
			// console.log('stored accessToken', TokenService.getAccessToken());
			config.headers.Authorization = `Bearer ${newAccessToken}`;
		}
	}

	if (config.isShare) {
		const { shareCode } = config;
		if (shareCode) {
			const separator = config.url.includes('?') ? '&' : '?';
			config.url += `${separator}code=${shareCode}`;
		}
	}

	return config as any; // Cast to 'any' to bypass type mismatch issues
}, (error) => Promise.reject(error));

axiosInstance.interceptors.response.use(
	(response) => {
		const accessToken = response.headers.authorization || response.headers.Authorization;
		if (accessToken) {
			TokenService.updateAccessToken(accessToken.replace('Bearer ', ''));
		}
		if (response.config.headers['Include-Headers']) {
			return response.data;
		}
		return response.data;
	},
	// eslint-disable-next-line no-shadow
	(error) => {
		console.error('error', error);
		if (error instanceof LoginExpiredError) {
			console.log('returning error');
			throw error;
		}
		// console.log('error', error);
		if (error.response && error.response.status === 404) {
			if (error.response.data.error.includes('rating')) {
				throw new Error('This survey is not completed');
			}
			if (error.response.data.error.includes('not found')) {
				throw new Error('Data not found or not available');
			}
			throw new Error('Internet connection is required');
		}
		if (error.response) {
			throw new Error(error.response.data.error || 'An error occurred, please try again');
		}
		if (error.request) {
			throw new Error('Cannot connect to the server, please check your internet connection or contact the support team');
		}
		throw new Error('An unknown error occurred, please contact the support team');
	},
);

export default axiosInstance;
