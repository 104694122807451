/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ProductSurveyQuestions, SurveyProduct, Domain, Feature, ProductQuestion,
} from 'api'; // Import your API interfaces
import { getProductSurveyQuestions } from 'thunk'; // Import both thunks

interface ProductRatingEntity {
  [productId: string]: {
    [questionId: string]: {
      questionId: string;
      rating: number | null;
    };
  };
}

interface RatingValidationsEntity {
  [productId: string]: {
    [questionId: string]: boolean;
  };
}

interface ProductImportanceRatingEntity {
  [productId: string]: {
    [featureId: string]: {
      featureId: string;
      rating: number | null;
    };
  };
}

interface ImportanceRatingValidationsEntity {
  [productId: string]: {
    [featureId: string]: boolean;
  };
}

export interface inidividualSurveyResponseState{
ratingEntities: ProductRatingEntity | null;
  importanceEntities: ProductImportanceRatingEntity | null;
  ratingValidations: RatingValidationsEntity | null;
  importanceValidations: ImportanceRatingValidationsEntity | null;
  questionIds: string[];
}

export interface ProductSurveyResponseState {
  [surveyId:string]: inidividualSurveyResponseState;
}

const initialState: ProductSurveyResponseState = {};

// Helper function to initialize rating entities
const initializeRatingEntities = (
	products: SurveyProduct[],
	domains: Domain[],
): ProductRatingEntity => products.reduce((acc, product: SurveyProduct) => {
	const ratingEntities = domains.flatMap(
		(domain: Domain) => domain.features.flatMap(
			(feature: Feature) => feature.questions.map((question: ProductQuestion) => ({
				[question.questionId]: {
					questionId: question.questionId,
					rating: null, // Default rating
				},
			})),
		),
	);

	// Flatten the entity result into a single object
	const flattenedEntity = ratingEntities.reduce(
		(entityAcc, curr) => ({ ...entityAcc, ...curr }),
		{},
	);

	acc[product.productId.toString()] = flattenedEntity;
	return acc;
}, {} as ProductRatingEntity);

// Helper function to initialize importance entities
const initializeImportanceEntities = (
	products: SurveyProduct[],
	domains: Domain[],
): ProductImportanceRatingEntity => products.reduce((acc, product: SurveyProduct) => {
	const importanceEntities = domains.flatMap(
		(domain: Domain) => domain.features.map((feature: Feature) => ({
			[feature.featureId]: {
				featureId: feature.featureId,
				rating: null, // Default importance rating
			},
		})),
	);

	// Flatten the entity result into a single object
	const flattenedEntity = importanceEntities.reduce(
		(entityAcc, curr) => ({ ...entityAcc, ...curr }),
		{},
	);

	acc[product.productId.toString()] = flattenedEntity;
	return acc;
}, {} as ProductImportanceRatingEntity);

// Main function that initializes either rating or importance entities based on entityType
const initializeEntities = (
	products: SurveyProduct[],
	domains: Domain[],
	entityType: 'rating' | 'importance',
): ProductRatingEntity | ProductImportanceRatingEntity => {
	if (entityType === 'rating') {
		return initializeRatingEntities(products, domains);
	}
	return initializeImportanceEntities(products, domains);
};

// Helper function to initialize validations
const initializeValidations = (
	products: SurveyProduct[],
	domains: Domain[],
	validationType: 'rating' | 'importance',
) => products.reduce((acc, product) => {
	const validation = domains.flatMap((domain) => (validationType === 'rating'
		? domain.features.flatMap((feature) => feature.questions.map((question) => ({
			[question.questionId.toString()]: false,
		})))
		: domain.features.map((feature) => ({
			[feature.featureId.toString()]: false,
		})))).reduce((acc, curr) => ({ ...acc, ...curr }), {});

	acc[product.productId.toString()] = validation;
	return acc;
}, {} as RatingValidationsEntity | ImportanceRatingValidationsEntity);

const productSurveyResponseSlice = createSlice({
	name: 'ProductSurveyResponse',
	initialState,
	reducers: {
		setProductQuestionRating(
			state,
			action: PayloadAction<{
				surveyId:string,
				productId: string;
				questionId: string;
				rating: number }>,
		) {
			const {
				surveyId, productId, questionId, rating,
			} = action.payload;

			// Extract the nested objects into temporary variables
			const questionRating = state?.[surveyId]?.ratingEntities?.[productId]?.[questionId];

			if (questionRating !== undefined && rating !== null && rating !== undefined) {
				// Now safely assign the rating
				questionRating.rating = rating;
			}
		},
		setProductImportanceRating(
			state,
			action: PayloadAction<{
				surveyId:string;
				productId: string;
				featureId: string;
				rating: number }>,
		) {
			const {
				surveyId, productId, featureId, rating,
			} = action.payload;
			const importanceRating = state?.[surveyId]?.importanceEntities?.[productId]?.[featureId];
			if (importanceRating !== undefined && rating !== null && rating !== undefined) {
				importanceRating.rating = rating;
			}
		},
		setProductQuestionValidation(
			state,
			action: PayloadAction<{
				surveyId:string;
				productId: string;
				questionId: string;
				isValid: boolean }>,
		) {
			const {
				surveyId, productId, questionId, isValid,
			} = action.payload;

			const questionValid = state?.[surveyId]?.ratingValidations?.[productId];
			if (questionValid && questionId && isValid !== undefined) {
				questionValid[questionId] = isValid;
			}
		},
		setProductImportanceValidation(
			state,
			action: PayloadAction<{
				surveyId:string;
				productId: string;
				featureId: string;
				isValid: boolean }>,
		) {
			const {
				surveyId, productId, featureId, isValid,
			} = action.payload;
			const importanceValid = state?.[surveyId]?.importanceValidations?.[productId];
			if (importanceValid && featureId && isValid !== undefined
			) {
				importanceValid[featureId] = isValid;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			getProductSurveyQuestions.fulfilled,
			(state, action: PayloadAction<ProductSurveyQuestions>) => {
				// check if surveyId exists in state
				if (!state || !state[action.payload.templateId]) {
					state[action.payload.templateId] = {
						ratingEntities: initializeEntities(action.payload.products, action.payload.domains, 'rating') as ProductRatingEntity,
						importanceEntities: initializeEntities(action.payload.products, action.payload.domains, 'importance') as ProductImportanceRatingEntity,
						ratingValidations: initializeValidations(action.payload.products, action.payload.domains, 'rating') as RatingValidationsEntity,
						importanceValidations: initializeValidations(action.payload.products, action.payload.domains, 'importance') as ImportanceRatingValidationsEntity,
						questionIds: action.payload.domains.flatMap(
							(domain) => domain.features.flatMap(
								(feature) => feature.questions.map(
									(question) => question.questionId,
								),
							),
						),
					};
				}
			},
		);
	},
});

export const {
	setProductQuestionRating,
	setProductImportanceRating,
	setProductQuestionValidation,
	setProductImportanceValidation,
} = productSurveyResponseSlice.actions;

export const productSurveyResponseReducer = productSurveyResponseSlice.reducer;
