export class TokenService {
	static setTokens({ accessToken, refreshToken, rememberMe }) {
		if (rememberMe) {
			localStorage.setItem('accessToken', accessToken);
			if (refreshToken) {
				localStorage.setItem('refreshToken', refreshToken);
			}
		} else {
			sessionStorage.setItem('accessToken', accessToken);
		}
	}

	static getAccessToken() {
		return localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken') || null;
	}

	static getRefreshToken() {
		return localStorage.getItem('refreshToken') || null;
	}

	static updateAccessToken(accessToken) {
		if (localStorage.getItem('accessToken')) {
			localStorage.setItem('accessToken', accessToken);
		} else {
			sessionStorage.setItem('accessToken', accessToken);
		}
	}

	static clearTokens() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		sessionStorage.removeItem('accessToken');
	}

	static validateToken(token) {
		if (!token) {
			return false;
		}
		try {
			// Split the token to get the payload
			const payloadBase64Url = token.split('.')[1];

			// Fix base64Url encoding: replace URL-safe characters and add padding
			const payloadBase64 = payloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');
			const payloadBase64Padded = payloadBase64.padEnd(payloadBase64.length + (4 - payloadBase64.length % 4) % 4, '=');

			// Decode the payload from base64
			const payloadJson = atob(payloadBase64Padded);
			const payload = JSON.parse(payloadJson);

			// Get the current time and the expiration time from the payload
			const currentTime = Math.floor(Date.now() / 1000);
			const expTime = payload.exp;

			// Return true if the token is expired, otherwise false
			return expTime > currentTime;
		} catch (e) {
			console.error('Failed to decode JWT:', e);
			return false; // If there's an error decoding, assume the token is invalid/expired
		}
	}
}

export default TokenService;
