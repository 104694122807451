import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import propType from 'prop-types';
import { RootState } from 'features';
import { DomainRating } from 'api';
import theme from 'theme';
import ScoreBar from '../../../../../Ratings/ScoreBar';
import DynamicSvg from '../../../../../DynamicSvgIcon';

function DomainRow({ domainRating }: { domainRating: DomainRating }) {
	const { domainEntities } = useSelector((state:RootState) => state.filters.domain);
	const domain = domainEntities[domainRating.domainId];
	const ratingNumber = parseFloat(domainRating.rating);

	const { expertOpinionsOn } = useSelector((state:RootState) => state.filters.toggle);
	const getDomainRatingFormatted = () => ratingNumber.toFixed(1);

	if (!domain) return null;

	return (
		<Grid
			container
			spacing={1}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'start',
				alignItems: 'center',
				alignContent: 'center',
			}}
		>
			<Grid
				item
				xs={1}
				md={1.5}
				sx={{
					display: 'flex',
					alignContent: 'center',
					alignItems: 'flex-start',
					justifyContent: 'center',
					objectFit: 'contain',
				}}
			>
				<img
					src={domain.iconMain}
					alt={domain.name}
					style={{
						width: 'auto',
						maxHeight: '30px',
						objectFit: 'contain',
					}}
				/>
			</Grid>
			<Grid
				item
				xs={11}
				md={10.5}
			>
				<Grid
					container
					spacing={1}
					alignItems="center"
					sx={{
						marginBottom: ['5px', '5px', '10px'], // [mobile, tablet, desktop
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',

					}}
				>
					<Grid item xs={12} sx={{ marginTop: ['10px', '10px', 'auto'] }}>

						<Typography
							sx={{
								fontFamily: 'Inter, sans-serif',
								fontWeight: 600,
								fontSize: ['10px', '12px', '14px'], // [mobile, tablet, desktop
								textAlign: 'left',
								color: theme.palette.primary.main,
							}}
						>
							{domain.name}
						</Typography>

					</Grid>

					<Grid item xs={8} lg={expertOpinionsOn ? 8 : 7} marginTop="-5px">
						<ScoreBar
							score={ratingNumber || 0}
							sx={{
								paddingLeft: '10px',
							}}
							highlight
						/>
					</Grid>
					<Grid item xs={4} lg={expertOpinionsOn ? 4 : 1} marginTop="-5px">
						<Typography
							sx={{
								color: '#455468',
								fontFamily: 'Inter, sans-serif',
								fontWeight: 600,
								fontSize: '14px',
								textAlign: 'right',
							}}
						>
							{getDomainRatingFormatted()}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>

	);
}

export default DomainRow;
