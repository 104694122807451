import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import theme from 'theme';
import { getSurveyShareLink } from 'thunk';
import SharedLinkCard from '../../Cards/SharedLinkCard';

export function ShareButton() {
	const dispatch = useDispatch();
	const { singleSurveyId, surveyId } = useParams();
	const [open, setOpen] = useState(false);

	const clickShare = () => {
		dispatch(getSurveyShareLink({ data: { surveyId: singleSurveyId || surveyId || '' } }) as any);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{
			padding: '0px',
		}}
		>
			<LoadingButton
				fullWidth
				variant="outlined"
				sx={{
					mt: 2,
					mb: 2,
					borderRadius: {
						xs: 40,
						sm: 40,
						md: 52,
					},
					height: {
						xs: 26,
						sm: 28,
						md: 30,
					},
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					'&:hover': {
						backgroundColor: theme.palette.primary.light,
					},
				}}
				startIcon={<ShareOutlinedIcon />}
				onClick={clickShare}
			>
				Share
			</LoadingButton>
			<SharedLinkCard open={open} handleClose={handleClose} />
		</Box>
	);
}

export default ShareButton;
