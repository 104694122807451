import { DomainRating } from 'api';
import DomainGeneralSkeleton from './DomainGeneralSkeleton';
import DomainGeneralContent from './DomainGeneralContent';

function DomainGeneral({
	domainRating,
	loading,
	error,
}: {
	domainRating: DomainRating,
	loading: boolean,
	error: boolean,
}) {
	if (loading) {
		return <DomainGeneralSkeleton />;
	}
	if (error) {
		return null;
	}
	return (
		<DomainGeneralContent domainRating={domainRating} />
	);
}

export default DomainGeneral;
