/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SURVEY_FILTER_THUNK_PREFIX } from 'thunk';
import { PersonaFilter, SurveyFilterResponse } from 'api';
import { RESET_STATE } from '../../constant';

export interface filterSelection {
	allowedMin: number;
	allowedMax: number;
	selectedMin: number;
	selectedMax: number;
	name: string;
}

export interface PersonaFilterState {
	loading: boolean;
	error: string | null;
	personaEntities: { [id: string]: PersonaFilter };
	personaIds: string[];
	filterSelection: { [id: string]: filterSelection };
}

const initialState: PersonaFilterState = {
	loading: false,
	error: null,
	personaEntities: {},
	personaIds: [],
	filterSelection: {},
};

const personaFilterSlice = createSlice({
	name: 'personaFilter',
	initialState,

	reducers: {
		// reducer to set min and max values for persona
		setSelectedPersonaRange(state, action) {
			// console.log('setSelectedPersonaRange', action.payload);
			const {
				id, selectedMin, selectedMax,
			} = action.payload;
			state.filterSelection[id].selectedMin = selectedMin;
			state.filterSelection[id].selectedMax = selectedMax;
		},

	},

	extraReducers: (builder) => {
		builder.addCase(RESET_STATE, (state) => {
		// reset the filterSelection
			state.filterSelection = state.personaIds.map((id) => ({
				[String(id)]: {
					allowedMin: state.filterSelection[String(id)].allowedMin,
					allowedMax: state.filterSelection[String(id)].allowedMax,
					selectedMin: state.filterSelection[String(id)].allowedMin,
					selectedMax: state.filterSelection[String(id)].allowedMax,
					name: state.filterSelection[String(id)].name,
				},
			})).reduce((acc, cur) => ({ ...acc, ...cur }), {});
		});
		builder.addMatcher(
			(action) => action.type.startsWith(SURVEY_FILTER_THUNK_PREFIX)
			&& action.type.endsWith('pending'),
			(state: PersonaFilterState) => {
				Object.assign(state, initialState);
				state.loading = true;
			},
		);
		builder.addMatcher(
			(action) => action.type.startsWith(SURVEY_FILTER_THUNK_PREFIX)
			&& action.type.endsWith('rejected'),
			(state: PersonaFilterState) => {
				Object.assign(state, initialState);
				state.loading = false;
				state.error = 'Error in fetching persona filters';
			},
		);

		builder.addMatcher(
			(action) => action.type.startsWith(SURVEY_FILTER_THUNK_PREFIX)
			&& action.type.endsWith('fulfilled'),
			(state: PersonaFilterState, action: { payload: SurveyFilterResponse }) => {
				state.loading = false;
				state.error = null;
				state.personaEntities = action.payload.personaFilters.map((persona) => ({
					[String(persona.categoryId)]: persona,
				})).reduce((acc, cur) => ({ ...acc, ...cur }), {});
				state.personaIds = action.payload.personaFilters.map((persona) => persona.categoryId);
				state.filterSelection = action.payload.personaFilters.map((persona) => ({
					[String(persona.categoryId)]: {
						allowedMin: persona.minRating,
						allowedMax: persona.maxRating,
						selectedMin: persona.minRating,
						selectedMax: persona.maxRating,
						name: persona.name,
					},
				})).reduce((acc, cur) => ({ ...acc, ...cur }), {});
			},
		);
	},
});

export const { setSelectedPersonaRange } = personaFilterSlice.actions;
export const personaSlice = personaFilterSlice.reducer;
