import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';
import productReducer from './product/productSlice';
import productFilterReducer from './filters/filterSlice';
import { auth } from './auth';
import errorMessageSlice from './errorMessages/errorMessageSlice';
import { coDesignerSurveyReducer } from './codesignerSurvey';
import { shareLinkReducer } from './shareLinkSlice';
import { shareReducer } from './share';
import { personaSurveyReducer } from './persoanQuestions/perosnaQuestion';
import { productSurveyReducer } from './productSurveyQuestions/productSurveyQuestions';
import { productSurveyResponseReducer } from './productSurveyQuestions/ProductSurveyResponse';
import { productSurveySubmission } from './productSurveyQuestions/productSurveySubmissionSlice';
// import personaCardSlice from './product/productPage/personaCardSlice';

// Configure redux-persist
const persistConfig = {
	key: 'root', // Key for the persisted reducer
	storage, // Default storage is localStorage
	whitelist: ['productSurveyInitial'], // Add reducers here to be persisted
};

// Combine reducers
const rootReducer = combineReducers({
	filters: productFilterReducer,
	auth,
	personaSurvey: personaSurveyReducer,
	productSurvey: productSurveyReducer,
	productSurveyInitial: productSurveyResponseReducer, // This reducer will be persisted
	productSurveySubmission,
	product: productReducer,
	error: errorMessageSlice,
	shareLink: shareLinkReducer,
	codesigner: coDesignerSurveyReducer,
	share: shareReducer,
	// personaCard: personaCardSlice,
});

// Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;

export type RootState = ReturnType<typeof persistedReducer>;

export * from './auth';
export * from './filters';
export * from './shareLinkSlice';
export * from './share';
export * from './productSurveyQuestions/productSurveyQuestions';
export * from './productSurveyQuestions/ProductSurveyResponse';
export * from './errorMessages/errorMessageSlice';
export * from './persoanQuestions/perosnaQuestion';
