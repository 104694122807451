/* eslint-disable no-shadow */
import { CUSTOMER_CONFIG, axiosInstance, CUSTOMER_SHARE_CONFIG } from 'utils';

const CUSTOMER_SURVEY_PATH = '/survey';

export interface FeatureRating{
    featureId:string;
    name:string;
    description:string;
    rating:string;
    importance :string;
    percentages: number[];
}
export interface DomainRating{
    domainId:string;
    rating:string;
    expertOpinion:string;
    featureRatings:FeatureRating[];
}

export interface ProductRating{
    productId:string;
    rating:string;
    expertOpinion:string;
    domainRatings:DomainRating[];
}

export interface SurveyProductRatingResponse {
    surveyId: string;
    productRatings: ProductRating[];
}

export const getProductRatings = async (
	{ surveyId, codesignerIds }: { surveyId: string, codesignerIds?: string[] },
):
Promise<SurveyProductRatingResponse> => {
	if (codesignerIds && codesignerIds?.length === 0) {
		const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/ratings`, CUSTOMER_CONFIG());
		return response as any as SurveyProductRatingResponse;
	}
	const codesignerIdsParam = codesignerIds ? codesignerIds.join(',') : undefined;
	// codesignerIds is an array of codesignerIds, so we need to pass it as a query parameter
	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/ratings`, {
		...CUSTOMER_CONFIG(),
		params: {
			codesignerIds: codesignerIdsParam,
		},
	});
	return response as any as SurveyProductRatingResponse;
};

export const getSharedProductRatings = async (
	{ surveyId, code, codesignerIds }
    : { surveyId: string, code: string, codesignerIds?: string[] },
): Promise<SurveyProductRatingResponse> => {
	if (codesignerIds && codesignerIds?.length === 0) {
		const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/ratings`, {
			...CUSTOMER_SHARE_CONFIG(code),
		});
		return response as any as SurveyProductRatingResponse;
	}
	const codesignerIdsParam = codesignerIds ? codesignerIds.join(',') : undefined;
	const response = await axiosInstance.get(`${CUSTOMER_SURVEY_PATH}/${surveyId}/ratings`, {
		...CUSTOMER_SHARE_CONFIG(code),
		params: {
			codesignerIds: codesignerIdsParam,
		},
	});
	return response as any as SurveyProductRatingResponse;
};
