import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { RootState } from 'features';
import { DomainCard } from 'components';

function SelectedDomainCards() {
	const { selectedDomains } = useSelector((state: RootState) => state.filters.domain);

	return (
		<Grid container spacing={2}>
			{selectedDomains?.map((id) => ((
				<Grid item xs={12} sm={6} lg={4} key={`${id}-domain-selectedDomainCard`}>
					<DomainCard
						domainId={String(id)}
						key={`${id}-domain`}
					/>
				</Grid>
			)
			))}
		</Grid>
	);
}

export default SelectedDomainCards;
