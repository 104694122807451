import React from 'react';
import {
	Card, CardContent, CardHeader, Dialog, IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import { FeatureRating } from 'api';
import CloseIcon from '../../icons/CloseIcon';
import FeatureCardContent from './FeatureCardContent';

function FeatureCard({
	open, domainId, featureRating, handleCloseClick,
}: {
	open: boolean, domainId: string, featureRating:FeatureRating, handleCloseClick: () => void,
}) {
	const { domainEntities } = useSelector((state: RootState) => state.filters.domain);

	return (
		<Dialog
			open={open}
			sx={{
				'& .MuiDialog-paper': {
					alignItems: 'center',
					justifyContent: 'center',
					width: ['350px', '600px', '800px'], // Breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'
					maxHeight: '90vh',
					margin: 'auto',
				},
			}}
		>
			<Card
				sx={{
					width: '100%', // Adjust as needed, or use maxWidth for responsiveness
					maxWidth: '600px',
					maxHeight: '90vh',
					// marginBottom: '16px',
					// overflow: 'visible', // Ensures shadows and such are not clipped
				}}
				elevation={0}
			>
				<CardHeader
					sx={{
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: '15px',
						paddingBottom: '0px',
					}}
					titleTypographyProps={{
						sx: {
							color: '#000000',
							fontFamily: 'Inter, sans-serif',
							fontWeight: 600,
							fontSize: '18px',
							textAlign: 'left',
						},
					}}
					title={featureRating.name}
					subheader={domainEntities[domainId].name}
					action={(
						<IconButton
							onClick={handleCloseClick}
							sx={{
								'& .MuiSvgIcon-root': {
									width: '40px',
									height: '40px',
									padding: '0px',
								},
							}}
						>
							<CloseIcon />
						</IconButton>
					)}
				/>
				<CardContent sx={{
					paddingTop: '0px',
					paddingBottom: ['8px', '16px', '16px'],
					paddingLeft: ['8px', '16px', '16px'],
					paddingRight: ['8px', '16px', '16px'],
				}}
				>
					<FeatureCardContent featureRating={featureRating} />
				</CardContent>
			</Card>
		</Dialog>
	);
}

export default FeatureCard;
