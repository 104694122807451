/* eslint-disable no-param-reassign */
import { combineReducers } from '@reduxjs/toolkit';
import ageFilterSlice from './ageFilterSlice';
import { personaSlice } from './personaFilterSlice';
import { domainSlice } from './domainFilterSlice';
import genderFilterSlice from './genderFilterSlice';
import toggleFilterSlice from './toggleFilterSlice';
// import shareSlice from './shareSlice';
import { surveySlice } from './surveySlice';

const filters = combineReducers({
	age: ageFilterSlice,
	persona: personaSlice,
	domain: domainSlice,
	gender: genderFilterSlice,
	toggle: toggleFilterSlice,
	// share: shareSlice,
	survey: surveySlice,
});

export default filters;
