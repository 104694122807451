import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import DynamicSvg from '../../DynamicSvgIcon';
import BrandCardSkeleton from './BrandCardSkeleton';

export function BrandCard() {
	const {
		productEntities,
		productIds,
		loading,
		error,
	} = useSelector((state: RootState) => state.product.productInfo);

	if (loading) {
		return (
			<Box
				sx={{
					display: 'flex',
					height: 'auto',
					marginBottom: '16px',
					backgroundColor: 'transparent',
					justifyContent: 'start',
					alignItems: 'center',
				}}
			>
				<BrandCardSkeleton />
			</Box>
		);
	}
	if (error) {
		return (
			<Box
				sx={{
					display: 'flex',
					height: 'auto',
					marginBottom: '16px',
					backgroundColor: 'transparent',
					justifyContent: 'start',
					alignItems: 'center',
				}}
			>
				<Typography>Error Loading Brand</Typography>
			</Box>
		);
	}

	if (productIds.length === 0 || !productEntities || !productEntities[String(productIds[0])]) {
		return (
			<Box
				sx={{
					display: 'flex',
					height: 'auto',
					marginBottom: '16px',
					backgroundColor: 'transparent',
					justifyContent: 'start',
					alignItems: 'center',
				}}
			>
				<Typography>No brand info</Typography>
			</Box>
		);
	}

	const { brandName, brandIconImageUrl } = productEntities[String(productIds[0])];

	const getBrandInfoRendered = () => (
		<>
			{brandIconImageUrl && (
				<DynamicSvg
					svgData={brandIconImageUrl}
					style={{
						width: '80px',
						height: '80px',
						justifyContent: 'center',
					}}
				/>
			)}
			<Typography
				sx={{
					color: '#000000',
					fontFamily: 'Inter, sans-serif',
					fontWeight: 600,
					fontSize: '18px',
					textAlign: 'start',
				}}
			>
				{brandName}
			</Typography>
		</>
	);

	return (
		<Box
			sx={{
				display: 'flex',
				height: 'auto',
				marginBottom: '16px',
				backgroundColor: 'transparent',
				justifyContent: 'start',
				alignItems: 'center',
			}}
		>
			{getBrandInfoRendered()}
		</Box>
	);
}

export default BrandCard;
