/* eslint-disable no-param-reassign */
// apiConfig.ts
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export class APIConfig {
	timeout?: number;

	headers?: AxiosRequestHeaders = {} as AxiosRequestHeaders;

	isShare: boolean;

	shareCode: string | null;

	useBaseEntryPath: string | null;

	useAuth: boolean;

	constructor() {
		this.isShare = false;
		this.shareCode = null;
		this.useBaseEntryPath = null;
		this.useAuth = false;
	}

	setUseBaseEntryPath(path: string): void {
		this.useBaseEntryPath = path;
	}

	setShareCode(code: string): void {
		this.shareCode = code;
	}

	setShare(isShare: boolean): void {
		this.isShare = isShare;
	}

	getUseBaseEntryPath(): string | null {
		return this.useBaseEntryPath;
	}

	getShareCode(): string | null {
		return this.shareCode;
	}

	getIsShare(): boolean {
		return this.isShare;
	}

	getUseAuth(): boolean {
		return this.useAuth;
	}

	setUseAuth(useAuth: boolean): void {
		this.useAuth = useAuth;
	}

	applyTo(config: AxiosRequestConfig): AxiosRequestConfig {
		if (this.useBaseEntryPath) {
			config.url = `${this.useBaseEntryPath}${config.url}`;
		}
		if (this.isShare && this.shareCode) {
			const separator = config.url?.includes('?') ? '&' : '?';
			config.url += `${separator}code=${this.shareCode}`;
		}
		return config;
	}
}

export const CUSTOMER_ENTRY_PATH = '/customer';
export const CUSTOMER_SHARE_PATH = '/customer/share';
export const USER_ACCESS_PATH = '/user/access';

export const CUSTOMER_CONFIG = (): APIConfig => {
	const config = new APIConfig();
	config.setUseBaseEntryPath(CUSTOMER_ENTRY_PATH);
	config.setUseAuth(true);
	return config;
};

export const CUSTOMER_SHARE_CONFIG = (code: string): APIConfig => {
	const config = new APIConfig();
	config.setUseBaseEntryPath(CUSTOMER_SHARE_PATH);
	config.setShare(true);
	config.setShareCode(code);
	return config;
};
