import {
	Box, Card, CardMedia, Grid, Skeleton, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { colors } from 'theme';
import { RootState } from 'features';
import { ProductPlaceHolder } from 'images';
import MavieURating from '../../../Ratings/MavieURating';

export function ProductIntroContent({ productId }: {productId: string}) {
	const { productEntities } = useSelector((state: RootState) => state.product.productInfo);
	const {
		loading: ratingLoading,
		error: ratingError,
		ratingEntities,
	} = useSelector((state: RootState) => state.product.rating);
	const productDetail = productEntities[productId];
	const productDescription = productDetail?.description || 'No description available';
	const productImage = productDetail?.imageUrl || ProductPlaceHolder;
	const categoryName = productDetail?.categoryName || '';
	const productModalName = `${productDetail?.modelName} ${productDetail?.modelNumber}` || 'Unknown';
	// console.log('product modal name', productModalName);
	const getRatingRendered = () => {
		if (ratingError) {
			return (
				<Typography
					sx={{
						fontSize: '12px',
						fontWeight: 400,
						fontFamily: 'Inter, sans-serif',
						textAlign: 'left',
						color: colors.grey,
						marginBottom: '16px',
					}}
				>
					Error loading rating
				</Typography>
			);
		}
		if (ratingLoading) {
			return (<Skeleton variant="rectangular" width={100} height={20} />);
		}
		if (ratingEntities && ratingEntities[productId]) {
			return (
				<MavieURating
					rating={ratingEntities[productId].rating || 0}
					readOnly
					sx={{
						width: '95%',
						height: 'auto',
						justifyContent: 'center',
					}}
				/>
			);
		}
		return null;
	};
	return (
		<Card
			sx={{
				display: 'flex',
				flexDirection: 'column',
				border: 'none',
				paddingRight: '16px',
				// marginTop: '-16px',
			}}
			elevation={0}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'start',
					justifyContent: { xs: 'space-between', sm: 'flex-start' },
					// paddingLeft: '16px',
					paddingBottom: '16px',
					paddingRight: '16px',
					gap: ['0px', '0px', '16px'],
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'start',
						justifyContent: 'center',
					}}
					width={['20%', '33%', '20%']}
					height={['auto', 'auto', 'auto']}
				>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: 400,
							fontFamily: 'Inter, sans-serif',
							textAlign: 'left',
							color: colors.grey,
							marginBottom: '16px',
						}}
					>
						{categoryName}

					</Typography>

					{getRatingRendered()}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'start',
						justifyContent: 'center',
					}}
					width={['33%', '33%', '60%']}

				>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: 600,
							fontFamily: 'Inter, sans-serif',
							textAlign: 'left',
							marginBottom: '16px',
						}}
					>
						Description
					</Typography>
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: 400,
							fontFamily: 'Inter, sans-serif',
						}}
					>
						{productDescription}
					</Typography>
				</Box>
				<Box
					sx={{
						// display: { xs: 'none', md: 'block' },
					}}
					width={['33%', '33%', '20%']}
				>
					<CardMedia
						component="img"
						image={productImage}
						title={productModalName}
						sx={{
							width: '95%',
							borderRadius: '8px',
							// marginLeft: 'auto',
						}}
					/>
				</Box>
			</Box>
		</Card>
	);
}

export default ProductIntroContent;
