import { Box, Typography } from '@mui/material';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProductInfo,
	getSharedProductInfo,
	getCodesigners,
	getSharedCodesigners,
	getProductRating,
	getSharedProductRating,
}
	from 'thunk';
import { RootState } from 'features';
import { BrandCard, ProductIntroCard, CodesignerCard } from 'components';

function IndividualProductPage() {
	// if path is /client/:id/product/:id and id changes, then we need to fetch the new product
	const { singleSurveyId, clientId } = useParams();
	const navigate = useNavigate();
	const surveyId = singleSurveyId || '';
	const { productIds } = useSelector((state: RootState) => state.product.productInfo);
	const { isShare, shareCode } = useSelector((state: RootState) => state.auth);
	const dispatch = useDispatch();
	const productId = productIds[0];

	useEffect(() => {
		if (isShare && shareCode) {
			dispatch(getSharedProductInfo({ data: { surveyId, code: shareCode } }) as any);
			dispatch(getSharedCodesigners({ data: { surveyId, code: shareCode } }) as any);
			dispatch(getSharedProductRating({ data: { surveyId, code: shareCode } }) as any);
		} else {
			dispatch(getProductInfo({ data: { surveyId } }) as any);
			dispatch(getCodesigners({ data: { surveyId } }) as any);
			dispatch(getProductRating({ data: { surveyId } }) as any);
		}
		if (clientId) {
			navigate(`/client/${clientId}/single/${surveyId}/metrics`);
		} else if (isShare && shareCode) {
			navigate(`/share/single/${surveyId}/metrics?code=${shareCode}`);
		}
	}, [surveyId, dispatch, navigate, clientId, singleSurveyId, isShare, shareCode]);

	return (
		<Box
			sx={{
				width: '100%',
				height: 'auto',
				justifyContent: 'start',
				paddingRight: '16px',
				paddingLeft: '16px',
				gap: '16px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<BrandCard />
			<Typography
				sx={
					{
						color: '#455468',
						fontFamily: 'Inter, sans-serif',
						fontWeight: 600,
						fontSize: '18px',
						textAlign: 'left',
						marginTop: '16px',
						marginBottom: '16px',
					}
				}
			>
				Product
			</Typography>
			<ProductIntroCard productId={productId} />
			{/* <GeneralPersonaCard /> */}
			{/* <PersonaPopup open /> */}
			<CodesignerCard />
			<Outlet />
		</Box>

	);
}

export default IndividualProductPage;
