/* eslint-disable react/react-in-jsx-scope */

import { useRoutes } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { PersistGate } from 'redux-persist/integration/react';
import { CssBaseline } from '@mui/material';
import routes from './route';
import store, { persistor } from './store';
import theme from './theme';
import DisplayErrorMessage from './components/DisplayErrorMessage';

function App() {
	const routing = useRoutes(routes);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					{routing}
					<DisplayErrorMessage />
				</ThemeProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
