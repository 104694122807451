export function MaxFeatureLinesCalculator(ratingEntities:
	{ [key: string]: { domainRatings: { featureRatings: any[] }[] } }) {
	// for each domain in domains, it has featureIds array
	// get the length of each featureIds array
	// return the max length
	let maxFeatureLines = 0;
	Object.keys(ratingEntities).forEach((productId) => {
		const product = ratingEntities[productId];
		if (product && product.domainRatings) {
			product.domainRatings.forEach((domain) => {
				if (domain.featureRatings?.length > maxFeatureLines) {
					maxFeatureLines = domain.featureRatings.length;
				}
			});
		}
	});
	// console.log('maxFeatureLines', maxFeatureLines);
	return maxFeatureLines;
}

export default MaxFeatureLinesCalculator;
