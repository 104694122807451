import { Box, Grid, Divider } from '@mui/material';

import { useSelector } from 'react-redux';
import {
	CodesignerSurveySelector, PersonaFilterWithSelector, LogoutButton, DomainSelector, ShareButton,
} from 'components';
import { RootState } from 'features';

export function ProductLogoutMenu() {
	const { isShare } = useSelector((state: RootState) => state.auth);

	return (
		<Box
			sx={
				{
					position: 'relative', // added
					maxHeight: '100vh', // ensure it does not exceed the viewport height
					overflow: 'auto', // hide the scrollbars
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'start',
					alignItems: 'start',
					width: '95%',
					height: '100%',
					marginTop: '10px',
					paddingLeft: '23px',
					paddingRight: '5px',
					marginLeft: '0px',
					marginRight: '0px',
					marginBottom: '10px',
					borderRadius: '0px 0px 0px 0px',
					boxShadow: '0px 0px 0px 0px',
				}
			}
		>
			<CodesignerSurveySelector />
			<Divider
				sx={{
					width: '100%',
					marginTop: '10px',
				}}
			/>
			{!isShare && (
				<Grid
					container
					spacing={1}
					sx={{
						position: 'sticky', bottom: 0, zIndex: 10,
					}}
				>
					<Grid item xs={6}>
						<LogoutButton />
					</Grid>
				</Grid>
			)}
		</Box>
	);
}

export default ProductLogoutMenu;
