import {
	Box, Typography, TextField, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';

interface Option {
    optionId: number;
    content: string;
    nextQuestionIds: number[];
}

interface Question {
    questionId: number;
    questionType: string;
    content: string;
    orderNumber: number;
    options: Option[];
    parentQuestionId: number;
    parentOptionId: number;
}

interface PersonaQuestionProps {
    question: Question;
    index: number;
    handleAnswerChange: (questionId: string, answer?: string, optionId?: string) => void;
}

export function PersonaQuestion({
	question,
	index,
	handleAnswerChange,
}: PersonaQuestionProps) {
	// Get the current answer from the Redux state
	const answerEntities = useSelector((state: RootState) => state.personaSurvey.answerEntities);

	// Get the selected optionId (for Radio buttons) or the open answer (for TextField)
	const currentOptionId = answerEntities?.[question.questionId]?.selectedOptionId || '';
	const currentAnswer = answerEntities?.[question.questionId]?.answer || '';

	return (
		<Box key={question.questionId} sx={{ mb: 4, width: '75%' }}>
			<Typography variant="h6">
				<span
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: `${index + 1}. ${question.content}` }}
				/>
			</Typography>
			{question.questionType === 'open' ? (
				<TextField
					required
					variant="outlined"
					type="number"
					InputProps={{ inputProps: { min: 18, max: 120 } }}
					value={currentAnswer}
					onChange={
						(e) => handleAnswerChange(question.questionId.toString(), e.target.value, undefined)
					}
				/>
			) : (
				<RadioGroup
					value={currentOptionId} // Bind the current selected optionId from state
					onChange={
						(e) => handleAnswerChange(question.questionId.toString(), undefined, e.target.value)
					}
				>
					{question.options.map((option) => (
						<FormControlLabel
							key={option.optionId}
							value={option.optionId.toString()}
							control={<Radio />}
							label={option.content}
							sx={{
								'.MuiFormControlLabel-label': {
									fontSize: '14px',
								},
							}}
						/>
					))}
				</RadioGroup>
			)}
		</Box>
	);
}

export default PersonaQuestion;
