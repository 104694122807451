import {
	Box, Grid, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import { DomainRating } from 'api';
import DynamicSvgIcon from '../../../../../DynamicSvgIcon';
import MavieGauge from '../../../../../Gauges';

function DomainGeneralContent({
	domainRating, importanceRatingOn,
}: {
	domainRating: DomainRating, importanceRatingOn: boolean
}) {
	const { domainEntities } = useSelector((state: RootState) => state.filters.domain);
	const domainInfo = domainEntities[domainRating.domainId];
	const domainRatingNumber = parseFloat(domainRating.rating) || 0;
	const getDomainRatingFormatted = () => {
		if (domainRatingNumber === 0) {
			return 'N/A';
		}
		return domainRatingNumber.toFixed(1);
	};
	return (
		<Grid container spacing={2} justifyContent="space-between">
			<Grid
				item
				xs={5}
				sm={4}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						height: '100%',
					}}
				>
					<DynamicSvgIcon
						svgData={domainInfo.iconRound}
						sx={{
							width: ['65px', '60px', '60px'], // [mobile, tablet, desktop]
							height: ['65px', '60px', '60px'], // [mobile, tablet, desktop]
							marginTop: '-30px', // [mobile, tablet, desktop]
						}}
					/>
					{/* <div style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						// flexWrap: 'wrap',
					}}
					> */}
					<Typography
						sx={{
							color: '#455468',
							fontFamily: 'Inter, sans-serif',
							fontWeight: 600,
							fontSize: ['36px', '38px', '32px'],
							textAlign: 'center',
							// marginBottom: ['5px', '5px', '5px'],
						}}
					>
						{getDomainRatingFormatted()}
					</Typography>

					{/* </div> */}

				</Box>
			</Grid>
			<Grid
				item
				xs={7}
				sm={8}
				sx={{
					width: '100%',
					display: 'flex',
					alignItems: 'flex-end',
					alignContent: 'flex-end',
				}}
			>

				<MavieGauge
					type="default"
					value={domainRatingNumber}
				/>
			</Grid>
		</Grid>
	);
}

export default DomainGeneralContent;
