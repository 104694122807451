import { useSelector } from 'react-redux';
import { RootState } from 'features';
import { NO_PERSONA_PROFILE } from 'constant';
import RoundButton from './RoundButton';

function SelectedCodesignerCard(
	{ coDesignerId, onIconClick } : {coDesignerId: string, onIconClick: () => void},
) {
	const { codesignerEntities } = useSelector((state:RootState) => state.codesigner);
	// const selectedPersonas = useSelector((state) => state.filters.persona);
	const codesigner = codesignerEntities[coDesignerId];

	return (
		<RoundButton
			onClick={onIconClick}
			svgData={codesigner?.profileImgUrl || NO_PERSONA_PROFILE}
			sx={{
				width: '100%',
			}}
			disabled={codesigner?.personaCategoryRatings?.length === 0}
		/>
	);
}

export default SelectedCodesignerCard;
