/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
// middleware/serializeBigNumbersMiddleware.ts
import { Middleware } from '@reduxjs/toolkit';
// import BigNumber from 'bignumber.js';

/**
 * Checks if a value is a BigNumber-like object.
 * @param value - The value to check.
 * @returns true if value is a BigNumber-like object, false otherwise.
 */
function isBigNumberLike(value: any): boolean {
	return value && typeof value === 'object' && 'c' in value && 'e' in value && 's' in value;
}

/**
 * Recursively serializes BigNumber objects into strings.
 * @param data - The data to be serialized.
 * @returns The serialized data.
 */
const serializeBigNumbers = (data: any): any => {
	if (data === null || data === undefined) {
		return data;
	}

	if (Array.isArray(data)) {
		return data.map((item) => serializeBigNumbers(item));
	}

	if (typeof data === 'object') {
		const serializedObj: any = {};
		for (const key in data) {
			// console.log('key', key);
			// console.log('data[key]', data[key]);
			// console.log('typeof data[key]', typeof data[key]);
			// eslint-disable-next-line no-continue

			const value = data[key];
			if (isBigNumberLike(value)) {
				// Convert BigNumber to string or BigInt
				// console.log('value', value);
				serializedObj[key] = value.toString(); // or BigInt(value.toString())
				// console.log('serializedObj[key]', serializedObj[key]);
			} else if (typeof value === 'object') {
				serializedObj[key] = serializeBigNumbers(value);
			} else {
				// console.log('key', key);
				// console.log('value', value);
				// console.log('typeof value', typeof value);
				serializedObj[key] = value;
			}
		}
		// console.log('serializedObj', serializedObj);
		return serializedObj;
	}

	return data;
};

/**
 * Redux middleware to serialize BigNumber objects before they reach the reducer.
 */
const serializeBigNumbersMiddleware: Middleware = (store) => (next) => (action:any) => {
	if (typeof action.payload === 'object' && action.payload !== null) {
		action.payload = serializeBigNumbers(action.payload);
	}
	return next(action);
};

export default serializeBigNumbersMiddleware;
