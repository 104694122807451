import { useSelector, useDispatch } from 'react-redux';
import { Checkbox, Box } from '@mui/material';
import { RootState } from 'features';
import DynamicSvg from '../../../../DynamicSvgIcon';
import SinglePersonaSlider from './SinglePersonaSlider';

function SinglePersonaSelector({ personaId }: { personaId: string }) {
	const dispatch = useDispatch();
	const personaInfo = useSelector(
		(state: RootState) => state.filters.persona.personaEntities[personaId],
	);
	// const personaInfo = personas[personaId];
	const personaFilter = useSelector(
		(state: RootState) => state.filters.persona.filterSelection[personaId],
	);

	// const handleSelectCheckbox = (id: number) => {
	// 	dispatch(setSelectedPersona({ id, selected: !personaFilter.selected }));
	// };
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'start',
				height: '100%',
				padding: '8px',
				spacing: '8px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '1px',
					margin: '0px',
					marginBottom: '8px',
					marginTop: '8px',
				}}

			>

				{/* <Checkbox
					checked={personaFilter?.selected}
					onChange={() => handleSelectCheckbox(personaId)}
					sx={
						{
							color: theme.palette.grey,
							'&.Mui-checked': {
								color: theme.palette.primary.light,
							},
							marginRight: '0px',
						}
					}

				/> */}
				<DynamicSvg
					style={{
						display: 'flex',
						width: '24px',
						height: '24px',
						borderRadius: '50%',
						verticalAlign: 'middle',
						marginLeft: '0px',
						marginRight: '8px',
					}}
					svgData={personaInfo?.iconImageUrl}
				/>
				<span
					style={{
						fontFamily: 'Inter',
						fontWeight: '600',
						fontSize: '12px',
						verticalAlign: 'middle',
					}}
				>
					{personaInfo?.name || 'default persona'}
				</span>
			</Box>
			<SinglePersonaSlider personaId={personaId} />

		</Box>
	);
}

export default SinglePersonaSelector;
