import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from 'features';
import { setCurrentCodesignerId } from 'features/codesignerSurvey';
import SingleCoDesignerIcon from './SingleCoDesignerIcon';
import PersonaPopup from '../../../../Popup/PersonaPopup';

function SingleCoDesignerHead({ coDesignerId } : {coDesignerId: string}) {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const handlePopupClose = () => {
		setOpen(false);
		setCurrentCodesignerId(-1);
	};

	const onIconClick = () => {
		dispatch(setCurrentCodesignerId(coDesignerId));
		setOpen(true);
	};

	return (
		<>
			<SingleCoDesignerIcon onIconClick={onIconClick} coDesignerId={coDesignerId} />
			<PersonaPopup open={open} onClose={handlePopupClose} />
		</>
	);
}

export default SingleCoDesignerHead;
