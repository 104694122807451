import { configureStore } from '@reduxjs/toolkit';
import persistedReducer from 'features';
import { persistStore } from 'redux-persist';
import apiMiddleware from './apiMiddleware';
import serializeBigNumbersMiddleware from './serializeBigNumbersMiddleware';
import errorHandlingMiddleware from './errorHandlingMiddleware';

// Create the store
const store = configureStore({
	reducer: persistedReducer, // Use the persisted reducer here
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false, // Disable serializable check for redux-persist
	})
		.concat(errorHandlingMiddleware)
		.concat(apiMiddleware)
		.concat(serializeBigNumbersMiddleware),
});

// Create the persistor for the store
const persistor = persistStore(store);

export { store, persistor };

export default store;
