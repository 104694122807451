import {
	Box, RadioGroup, Typography, FormControlLabel, Radio,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReportType } from 'api';
import { setReportType } from 'features/share';
import theme from 'theme';

function PDFTypeSelector() {
	const dispatch = useDispatch();
	const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setReportType(event.target.value as ReportType));
	};
	const { singleSurveyId, surveyId } = useParams();

	const isProduct = (singleSurveyId && !surveyId);
	const defaultReportType = isProduct ? ReportType.SUMMARY : ReportType.COMPARISSON;
	useEffect(() => {
		dispatch(setReportType(defaultReportType));
	}, [dispatch, defaultReportType]);

	const getFormList = () => {
		if (isProduct) {
			return [
				{
					value: ReportType.SUMMARY,
					label: 'Summary PDF - 1 Page with the Usability Rating',
				},
				{
					value: ReportType.STANDARD,
					label: 'Standard PDF - Summary PDF + Domains details and Features with importance features',
				},
				{
					value: ReportType.DETAILED,
					label: 'Detailed PDF - Standard PDF + Detailed Features graphs',
				},
			];
		}

		return [

			{
				value: ReportType.COMPARISSON,
				label: 'Comparative PDF - Comparative testing report of different product',
			}];
	};

	return (
		<Box>
			<Typography
				sx={{
					fontSize: '14px',
					fontWeight: 600, // semibold
					color: theme.palette.text.primary,
				}}
			>
				Select PDF style
			</Typography>
			<Box sx={{
				pr: 2,
			}}
			>
				<RadioGroup
					aria-label="pdfStyle"
					defaultValue={defaultReportType}
					sx={{
						'& .MuiTypography-root': {
							fontSize: '12px',
							color: theme.palette.grey,
							fontFamily: 'Inter',
						},
						'& .MuiFormControlLabel-root': {
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignContent: 'center',
						},
						minWidth: '550px',
						width: '100%',
					} as any}
					onChange={handleChange}
				>
					{getFormList().map((form) => (
						<FormControlLabel
							key={`${form.value}-form`}
							value={form.value}
							control={<Radio />}
							label={form.label}
						/>
					))}
				</RadioGroup>
			</Box>

		</Box>
	);
}

export default PDFTypeSelector;
