import {
	Box, Grid, Typography,
} from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import the default styles
import { useSelector } from 'react-redux';
import theme, { colors } from 'theme';
import { RootState } from 'features';
import React from 'react';
import DynamicSvg from '../../../../DynamicSvgIcon';
import ScoreBar from '../../../../Ratings/ScoreBar';
import './styles.css';

function PersonaCategoryRating({ id, rating }: { id: string, rating: string }) {
	const { personaEntities } = useSelector((state: RootState) => state.filters.persona);
	const persona = personaEntities[id];
	const getRatingFormatted = parseFloat(rating).toFixed(1);
	if (!persona) {
		return null;
	}
	return (
		<Tippy
			content={`Rating: ${getRatingFormatted}`}
			theme="custom"
			className="mavy-tooltip"
			arrow
			placement="right"
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'start',
					alignItems: 'start',
				}}
				width="100%"
				rowGap={[0.5, 0.75, 0.5]}
			>

				<Grid
					container
					width="100%"
					spacing={1}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'start',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						item
						xs={2}
						md={2}
						lg={3}
						sx={{
						}}
					>
						<DynamicSvg
							svgData={persona.iconImageUrl}
							sx={{
								width: ['28x', '26px', '28px'], // [mobile, tablet, desktop
								height: ['28px', '26px', '28px'], // [mobile, tablet, desktop
								// paddingBottom: ['10px', '10px', '3px'],
								// paddingRight: ['10px', '10px', '10px'],
								marginBottom: ['0px', '0px', '5px'],
								alignItems: 'center',
								alignContent: 'center',
								display: 'flex',
							}}
						/>

					</Grid>

					<Grid
						item
						xs={10}
						md={10}
						lg={9}
						sx={{
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Typography
							sx={{
								fontSize: ['14px', '16px', '18px'], // [mobile, tablet, desktop]
								fontWeight: 600,
								color: theme.palette.text.primary,
							}}
						>
							{persona.name}
						</Typography>
					</Grid>
				</Grid>

				<Box
					width="100%"
				>
					<ScoreBar score={Number(getRatingFormatted)} color={theme.palette.primary.light} />
				</Box>

			</Box>
		</Tippy>
	);
}

export default PersonaCategoryRating;
