import React, { useState, useEffect } from 'react';
import {
	Box, Grid, Typography, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setProductImportanceValidation } from 'features';

interface ImportanceRatingProps {
    featureName: string;
    featureId: string;
    products: { productId: string; modelName: string; }[];
    importanceEntities: {
        [productId: string]: { [featureId: string]: { rating: number | null } } } | null;
	importanceValidations: { [productId: string]: { [featureId: string]: boolean } } | null;
    handleImportanceAnswerChange: (productId: string, featureId: string, value: string) => void;
}

export function ImportanceRatingQuestion({
	featureName,
	featureId,
	products,
	importanceEntities,
	importanceValidations,
	handleImportanceAnswerChange,
}: ImportanceRatingProps) {
	const dispatch = useDispatch();
	const { currentSurveyId: surveyId } = useSelector((state:RootState) => state.filters.survey);
	const { products: productsState } = useSelector((state: RootState) => state.productSurvey);
	// eslint-disable-next-line no-shadow
	const onRatingChange = (productId: string, featureId: string, value: string) => {
		dispatch(setProductImportanceValidation({
			surveyId: surveyId as string,
			productId,
			featureId,
			isValid: true,
		}));
		handleImportanceAnswerChange(productId, featureId, value);
	};

	return (
		<Box sx={{ mb: 4 }}>
			<Typography variant="body2" sx={{ mb: 1, fontSize: '16px', fontWeight: 600 }}>
				Feature Importance: Now that you have rated
				{' '}
				{featureName}
				{' '}
				as a feature of the product, please indicate its Importance on a scale of 1 to 5:
			</Typography>

			<Grid container spacing={1} alignItems="center" justifyContent="space-between" paddingTop="10px">
				<Grid item xs={1.5}>
					<Typography variant="body2" textAlign="center" fontWeight="550">Product</Typography>
				</Grid>
				<Grid item xs={10.5}>
					<Grid container>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Not Important</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(1)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Slightly Important</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(2)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Neutral</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(3)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Moderately Important</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(4)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Very Important</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(5)</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							sx={{
								display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
							}}
						>
							<Typography variant="body2" textAlign="center" fontWeight="550">Not Applicable</Typography>
							<Typography variant="subtitle2" textAlign="center" fontWeight="550">(Null)</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{products.map((product) => (
				<Grid container spacing={1} alignItems="center" justifyContent="center" width="100%" key={product.productId}>
					<Grid
						item
						xs={1.5}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						{ importanceValidations && !importanceValidations[product.productId]?.[featureId] && (
							<Typography variant="body2" color="error" sx={{ mb: 1 }}>
								*
							</Typography>
						)}
						<Typography variant="body2" textAlign="center">
							{product.modelName}
						</Typography>
						{products.length !== 1 && (
							<img
								src={
									productsState.find((p) => p.productId === product.productId)?.imageUrl
								}
								alt={product.modelName}
								style={{ width: '50px', height: '50px', marginLeft: '10px' }}
							/>
						)}
					</Grid>

					<Grid item xs={10.5}>
						<RadioGroup
							row
							value={
								importanceEntities?.[product.productId]?.[featureId]?.rating?.toString() || ''
							}
							onChange={(e) => onRatingChange(product.productId, featureId, e.target.value)}
						>
							<Grid container>
								{[1, 2, 3, 4, 5, 0].map((val) => (
									<Grid
										item
										xs={2}
										key={val}
									>
										<FormControlLabel
											sx={{
												width: '100%',
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center',
												margin: '0',
												marginLeft: '6.5px',
											}}
											value={val.toString()}
											control={<Radio />}
											label=""
										/>
									</Grid>
								))}
							</Grid>
						</RadioGroup>
					</Grid>
				</Grid>
			))}
		</Box>
	);
}

export default ImportanceRatingQuestion;
