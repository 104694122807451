import { useSelector } from 'react-redux';
import { RootState } from 'features';
import DomainGeneralSkeleton from './DomainGeneralSkeleton';
import ProductGeneralContent from './ProductGeneralContent';

function UpperBody({
	productId,
}:{
	productId:string,
}) {
	const { loading, error } = useSelector((state:RootState) => state.product.rating);

	if (loading) {
		return <DomainGeneralSkeleton />;
	}
	if (error) {
		return null;
	}
	return (
		<ProductGeneralContent productId={productId} />
	);
}

export default UpperBody;
